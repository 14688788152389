import { Button, Col, Row } from 'antd'
import React, { useState } from 'react'
import GameChart from '../components/GameChart'
import { handleExportCSVScreen } from '~/common/constants'

const GameDashboard = () => {
  const handleExport = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/admin/dashboard/campaign-data-csv`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        },
      )

      if (response.ok) {
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'Closed Beta Campaign.xlsx' // Set the filename here
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } else {
        console.error('Failed to export data')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const [ParamsDate, setParamsDate] = useState({})
  const handleChangeParam = (data) => {
    setParamsDate(data)
  }

  return (
    <React.Fragment>
      <Row gutter={10}>
        <Col span={24}>
          <div>
            <GameChart handleChangeParam={handleChangeParam} />
          </div>
        </Col>
        <Button style={{ background: '#1890FF' }} onClick={handleExport}>
          Export Campaign Data
        </Button>
        <Button
          style={{ backgroundColor: '#1890FF', marginLeft: 8 }}
          onClick={() =>
            handleExportCSVScreen(
              'DASHBOARD_GAME',
              ParamsDate,
              'DASHBOARD_GAME',
            )
          }
        >
          Export to CSV
        </Button>
      </Row>
    </React.Fragment>
  )
}

export default GameDashboard
