import { useTranslation } from '~/common/hooks/useTranslation'
import { Col, Modal, Row } from 'antd'
import React from 'react'

import configs from '~/configs'

function ModalViewAddress(props) {
  const { t } = useTranslation()
  const { data, action = {} } = props
  const renderReceiverAddressesTo = (addresses) => {
    return (
      <>
        {addresses.map((address, index) => {
          let link = `${configs.NETWORK.scan}address/${address}`
          return (
            <a
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              href={link}
              title={address}
              style={{
                color: '#40A9FF',
                display: 'block',
                marginBottom: '5px',
              }}
            >
              {address}
            </a>
          )
        })}
      </>
    )
  }
  return (
    <Modal
      title={t('managementNotification.modal.listRecipient')}
      open={action?.isModalOpen}
      onCancel={action?.handleCancel}
      width={555}
      centered
      destroyOnClose
      footer={null}
      getContainer={false}
    >
      <div>
        <div className="addressGiftDetail">
          <Row>
            <Col span={24} className="addressGiftDetailTitle text-[20px]">
              {t('managementNotification.form.Recipient')}
            </Col>
          </Row>
          <Row className="addressGiftDetailContent">
            <Col
              span={24}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {renderReceiverAddressesTo(data?.address_wallets || [])}
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}

export default ModalViewAddress
