import { useTranslation } from '~/common/hooks/useTranslation'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Select,
  Space,
} from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import { EyeOutlined } from '@ant-design/icons'
import SponsorTournamentDetail from './modalDetailSponsor'
import { useTournament } from '../../redux/hooks/useTournament'
import { StateSponsorTournament } from '~/common/constants'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

const SponsorTournament = () => {
  const { t } = useTranslation()
  // const [formSearch] = Form.useForm()
  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()
  const [status, setStatus] = useState('All')
  const [sortTime, setSortTime] = useState('desc')
  const [dateRange, setDateRange] = useState([null, null])

  const {
    actions,
    data: { listTournament, isLoading },
  } = useTournament()

  const refreshData = () => {
    actions.getListTournament()
  }
  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword, status, dateRange, sortTime])

  const [modalDetail, setModalDetail] = useState({
    id: null,
    isOpenModal: false,
    sponsor: {},
  })

  const getColor = (color) => {
    switch (color) {
      case 4:
        return {
          border: '1px solid rgba(102, 187, 106, 1)',
          background: 'rgba(102, 187, 106, 1)',
        }
      case 5:
        return {
          border: '1px solid rgba(255, 0, 0, 1)',
          background: 'rgba(255, 0, 0, 1)',
        }
      case 2:
        return {
          border: '1px solid rgba(237, 135, 0, 1)',
          background: 'rgba(237, 135, 0, 1)',
        }
      case 3:
        return {
          border: '1px solid rgba(237, 135, 0, 1)',
          background: 'rgba(237, 135, 0, 1)',
        }
      default:
        return {
          border: '1px solid rgba(12, 200, 254, 1)',
          background: 'rgba(24, 45, 86, 1)',
        }
    }
  }

  const columns = [
    {
      title: t('sponsorsTournament.tournamentImage'),
      dataIndex: 'thumbnail_url',
      key: 'thumbnail_url',
      width: 200,
      render: (thumbnail_url) =>
        thumbnail_url ? (
          <Image
            className="imageTournament"
            style={{ width: '30vh', height: '100px', objectFit: 'cover' }}
            src={thumbnail_url}
          />
        ) : (
          'ー'
        ),
    },
    {
      title: (
        <div className="text-[16px] flex justify-center">
          {t('sponsorsTournament.tournamentName')}
        </div>
      ),
      dataIndex: 'tournament_name',
      key: 'tournament_name',
      render: (name) => (
        <div
          className="text-[16px] flex justify-center"
          style={{ fontFamily: 'Rexliafree' }}
        >
          {name}
        </div>
      ),
    },
    {
      title: (
        <div className="text-[16px] flex justify-center">
          {t('sponsorsTournament.status')}
        </div>
      ),
      dataIndex: 'state',
      key: 'state',
      render: (state) => (
        <div
          className="text-[16px] flex justify-center"
          style={{
            ...getColor(state),
            padding: '10px',
            borderRadius: '16px',
            fontFamily: 'Rexliafree',
          }}
        >
          {StateSponsorTournament(state)}
        </div>
      ),
    },
    {
      title: (
        <div className="text-[16px] flex justify-center">
          {t('sponsorsTournament.time')}
        </div>
      ),
      dataIndex: 'date',
      key: 'date',
      render: (time) => {
        const date = new Date(time * 1000)
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          timeZone: 'GMT',
        }).format(date)
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZone: 'GMT',
        })
        return (
          <div
            className="text-[16px] flex justify-center"
            style={{ fontFamily: 'Rexliafree' }}
          >{`${formattedDate} at ${formattedTime}`}</div>
        )
      },
    },
    {
      render: (_, data) => (
        <Space>
          <Button
            type="ghost"
            onClick={() => onShowModalDetail(data?._id, data)}
            icon={<EyeOutlined style={{ color: '#1890FF' }} />}
          />
        </Space>
      ),
    },
  ]

  const onShowModalDetail = (id, sponsor) => {
    setModalDetail({ id, isOpenModal: true, sponsor })
  }

  const onCloseModalDetail = () => {
    setModalDetail({ id: null, isOpenModal: false, sponsor: {} })
  }

  const handleSearch = (values) => {
    setKeyword(values?.keyword)
    setPage(1)
  }
  const searchStatus = (value) => {
    setStatus(value)
    if (value === 'All') {
      setPage(1)
    }
  }
  const handleSort = (value) => {
    setSortTime(value)
    setPage(1)
  }

  const filterData = listTournament?.sponsor_tournament_list
    ?.filter((item) => !item.is_draft)
    ?.filter((item) => item.state < 6)
    ?.filter((item) => {
      if (!keyword) {
        return true
      }
      return item?.tournament_name
        ?.toLowerCase()
        .includes(keyword?.toLowerCase())
    })
    ?.filter((item) => {
      if (status === 'All') {
        return true
      }
      return item?.state === status
    })
    ?.filter((item) => {
      if (dateRange && dateRange[0] && dateRange[1]) {
        const itemDate = new Date(item.date * 1000)
        return itemDate >= dateRange[0] && itemDate <= dateRange[1]
      }
      return true
    })
    ?.sort((a, b) => {
      const dateA = new Date(a.date * 1000)
      const dateB = new Date(b.date * 1000)

      if (sortTime === 'asc') {
        return dateA - dateB // Ascending order
      } else if (sortTime === 'desc') {
        return dateB - dateA // Descending order
      }
      return 0
    })

  const paginatedData = filterData?.slice(
    (page - 1) * pageSize,
    page * pageSize,
  )

  const options = [
    { value: 'All', label: 'All' },
    { value: 0, label: 'Upcoming' },
    { value: 1, label: 'Registration' },
    { value: 2, label: 'Entry' },
    { value: 3, label: 'JumpIn' },
    { value: 4, label: 'InProgress' },
    { value: 5, label: 'Ended' },
  ]
  const optionsSort = [
    { value: 'asc', label: 'Asc' },
    { value: 'desc', label: 'Desc' },
  ]

  const handleExportToExcel = () => {
    // Chuyển đổi dữ liệu và định dạng ngày tháng
    const formattedData = filterData.map((item) => ({
      ID: item._id,
      thumbnail_url: item.thumbnail_url,
      'Tournament Name': item.tournament_name,
      'Sponsor Name': item.sponsor_name,
      // Time: new Date(item.date * 1000),
      Time: `${new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        timeZone: 'GMT',
      }).format(new Date(item.date * 1000))} at ${new Date(
        item.date * 1000,
      ).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'GMT',
      })}`,
      Participants: item.participants,
      Status: StateSponsorTournament(item.state),
      'Wallet Address': item.wallet_address,
    }))

    // Tạo sheet Excel
    const ws = XLSX.utils.json_to_sheet(formattedData)

    ws['!cols'] = [
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 45 },
    ] // Thiết lập độ rộng cột Date

    // Tạo workbook và xuất file
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sponsor Tournament')
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(data, 'SponsorTournament.xlsx')
  }

  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={6}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <Form
                onFinish={handleSearch}
                className="w-full sm:w-auto"
                // form={formSearch}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {t('common.search')}
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Input className="w-full" placeholder={t('common.search')} />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={5}>
          <div className="flex sm:justify-center w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-center">
              <Form
                className="w-full sm:w-auto"
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {t('common.status')}
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Select
                    className="custom-select"
                    placeholder={t('common.status')}
                    options={options}
                    defaultValue="All"
                    onChange={searchStatus}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={5}>
          <Form className="w-full sm:w-auto">
            <Form.Item
              name="time"
              label={t('sponsorsTournament.time')}
              labelAlign="left"
            >
              <DatePicker.RangePicker
                className="w-[80%]"
                value={dateRange}
                onChange={(dates) => setDateRange(dates)}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={4}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <Form
                className="w-full sm:w-auto"
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {/* {t('common.status')} */}
                      Sort Time:
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Select
                    className="custom-select"
                    options={optionsSort}
                    defaultValue="desc"
                    onChange={handleSort}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={4}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end ml-3">
              <Button
                style={{ backgroundColor: '#1890FF' }}
                onClick={handleExportToExcel}
              >
                Export Tournament
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={paginatedData}
        loading={isLoading}
        showPagination
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: filterData?.length,
          keyword,
          setKeyword,
        }}
      />
      {modalDetail.isOpenModal && (
        <SponsorTournamentDetail
          isShowModal={modalDetail.isOpenModal}
          onClose={onCloseModalDetail}
          refreshData={refreshData}
          id={modalDetail.id}
          sponsor={modalDetail?.sponsor}
        />
      )}
    </div>
  )
}

export default SponsorTournament
