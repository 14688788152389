import { Button, Col, Form, Input, Popover, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import ManagementCollaborationNFTForm from './form'
import { useCollaboration } from '~/views/app/redux/hooks/useCollaboration'
import {
  LIST_CHAIN,
  converChain,
  handleExportCSVScreen,
} from '~/common/constants'
import { capitalizeFirstLetter } from '~/helpers/common'
import DeleteConfirm from './confirmDelete'

const ManagementCollaborationNFT = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [idSelected, setIdSelected] = useState([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [chain, setChain] = useState('-')
  const [isModalOpenDelete, setIsModalOpenDelete] = useState({
    isOpenModal: false,
    collaboration: {},
  })

  const {
    actions,
    data: { listCollaboration, isLoading, total },
  } = useCollaboration()
  //Control all modal
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    collaboration: {},
  })
  const showModalDelete = (content, idArray) => {
    setIsModalOpenDelete({
      content: content,
      idArray: idArray,
      isOpenModal: true,
    })
  }
  const handleCancelDelete = () => {
    setIsModalOpenDelete({
      content: null,
      idArray: [],
      isOpenModal: false,
    })
  }

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  const columns = [
    {
      title: t('common.no'),
      dataIndex: 'key',
      key: 'key',
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementCollaborationNFT.form.monster'),
      dataIndex: 'monster_seed',
      key: 'monster_seed',
      render: (monster_seed) => {
        return <div className="">{monster_seed}</div>
      },
    },
    {
      title: t('managementCollaborationNFT.form.collectionName'),
      dataIndex: 'collection_name',
      key: 'collection_name',
      with: 200,
      render: (collection_name) => {
        return (
          <Popover
            content={
              <div className="break-all w-[200px]">{collection_name}</div>
            }
          >
            <div className="break-all w-[200px] truncate">
              {collection_name}
            </div>
          </Popover>
        )
      },
    },
    {
      title: t('managementCollaborationNFT.form.market'),
      dataIndex: 'market',
      key: 'market',
      width: 150,
      render: (market) => {
        return (
          <Popover
            content={<div className="break-all w-[200px]">{market}</div>}
          >
            <div className="break-all w-[200px] truncate text-[#40A9FF]">
              {market}
            </div>
          </Popover>
        )
      },
    },
    {
      title: t('managementCollaborationNFT.form.chain'),
      dataIndex: 'chain',
      key: 'chain',
      render: (chain) => {
        return converChain(chain)
      },
    },
    {
      title: t('managementCollaborationNFT.form.tokenStandards'),
      dataIndex: 'token_standard',
      key: 'token_standard',
      render: (token_standard) => {
        return token_standard
      },
    },
    {
      title: t('managementCollaborationNFT.form.contract'),
      dataIndex: 'contract_address',
      key: 'contract_address',
      width: 150,
      render: (contract_address) => {
        return (
          <Popover
            content={
              <div className="break-all w-[150px]">{contract_address}</div>
            }
          >
            <div className="break-all w-[150px] truncate">
              {contract_address}
            </div>
          </Popover>
        )
      },
    },
    {
      title: t('managementCollaborationNFT.form.note'),
      dataIndex: 'note',
      with: 150,
      key: 'note',
      render: (note) => {
        return (
          <Popover content={<div className="break-all w-[150px]">{note}</div>}>
            <div className="break-all w-[150px] truncate">{note}</div>{' '}
          </Popover>
        )
      },
    },
    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              onClick={() => onShowModalForm(data?._id, data)}
              type="ghost"
              loading={false}
              icon={<EditFilled style={{ color: '#36CFC9' }} />}
            />

            <Button
              onClick={() => {
                onRemove([
                  {
                    monster_seed: data?.monster_seed,
                    id: data?._id,
                  },
                ])
              }}
              type="ghost"
              loading={false}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
            />
          </Space>
        )
      },
    },
  ]

  // On select Collaboration
  const onSelectChange = (itemsSelected, record) => {
    setSelectedRowKeys(itemsSelected)
    const idsPicked = record
    setIdSelected(idsPicked)
  }

  // Handle delete
  const onRemove = (data) => {
    let idArray = []
    let contentArray = ''

    if (data) {
      idArray = [data[0]?.id]
      contentArray = data[0]?.monster_seed
    } else {
      idArray = idSelected.map((item) => item._id)
      contentArray = idSelected.map((item) => item.monster_seed)
    }
    if (idArray.length > 0) {
      showModalDelete(
        t('cancel_modal.question', { question: contentArray.toString() }),
        idArray,
      )
    }
  }

  //Refresh data
  const params = {
    collectionName: keyword,
    chain: chain !== '-' ? chain : undefined,
    pageSize: pageSize,
    page,
  }
  const refreshData = () => {
    actions.getListCollaboration(params)
  }

  // Handle open modal (create-edit-delete)
  const onShowModalForm = (id, collaboration) => {
    setModalForm({
      id,
      isOpenModal: true,
      collaboration: collaboration,
    })
  }

  // Handle close modal (create-edit-delete)
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      collaboration: {},
    })
  }

  useEffect(() => {
    setSelectedRowKeys([])
    setIdSelected([])
    refreshData()
  }, [page, pageSize, keyword, chain])

  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }
  const onChangeChain = (values) => {
    setChain(values)
  }
  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Space className="d-flex justify-content-between justify-content-sm-start">
            <Button
              type="primary"
              onClick={() => {
                onRemove()
              }}
              disabled={!selectedRowKeys.length > 0 || isDeleting}
              loading={isDeleting}
              danger
            >
              {`${t('common.btn.delete')} ${
                selectedRowKeys.length ? `(${selectedRowKeys.length})` : ''
              }`}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                onShowModalForm(null, true)
              }}
            >
              {t('common.btn.addNew')}
            </Button>

            <Button
              style={{ backgroundColor: '#1890FF' }}
              onClick={() =>
                handleExportCSVScreen(
                  'MANAGEMENT_COLLABORATION_NFT',
                  params,
                  'MANAGEMENT_COLLABORATION_NFT',
                )
              }
            >
              Export to CSV
            </Button>
          </Space>
        </Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <Form
                onFinish={handleSearch}
                className="w-full sm:w-auto"
                form={formSearch}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {t('common.search')}
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Input
                    className="w-full !bg-[#0d2758]"
                    placeholder={t('common.enterName')}
                  />
                </Form.Item>
              </Form>

              <div className="flex items-baseline box__filter managementCollaborationNFT">
                <div className="mr-2 font__Rexliafree">
                  {t('managementCollaborationNFT.form.chain')}
                </div>
                <Select
                  onChange={onChangeChain}
                  className="min-w-[120px]"
                  placeholder={t('common.all')}
                  options={['All', ...LIST_CHAIN]?.map((data, i) => {
                    const chain = {
                      key: i?.toString(),
                      value: data === 'All' ? '-' : data,
                      label: capitalizeFirstLetter(data),
                    }
                    return chain
                  })}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listCollaboration}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
          preserveSelectedRowKeys: true,
        }}
        loading={isLoading}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: total,
          keyword,
          setKeyword,
        }}
      />

      {modalForm.isOpenModal && (
        <ManagementCollaborationNFTForm
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          refreshData={refreshData}
          id={modalForm.id}
          collaboration={modalForm?.collaboration}
        />
      )}

      <DeleteConfirm
        action={{
          isModalOpen: isModalOpenDelete.isOpenModal,
          handleCancel: handleCancelDelete,
        }}
        content={isModalOpenDelete.content}
        idArray={isModalOpenDelete.idArray}
        setIdSelected={setIdSelected}
        setIsDeleting={setIsDeleting}
        setSelectedRowKeys={setSelectedRowKeys}
        refreshData={refreshData}
        setPage={setPage}
        setPageSize={setPageSize}
        t={t}
      />
    </div>
  )
}

export default ManagementCollaborationNFT
