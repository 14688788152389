import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Form, Input, Row, Space } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import UserGuild from './UserDetail'
import GuildDetail from './GuildDetail'
import { DeleteFilled } from '@ant-design/icons'
import { useGuild } from '../../redux/hooks/useGuild'
import ConfirmDeleteGuild from './confirmDeleteGuild'
import {
  dataGuildLevel,
  getEndOfMonthUTC,
  getStartOfMonthUTC,
} from '~/common/constants'
import { useUser } from '../../redux/hooks/useUser'
import { usePools } from '../../redux/hooks/usePools'
import { formatNumber } from '~/helpers/common'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import TimeHelper from '~/helpers/time-helper'

const ManagementGuilds = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  const [modalForm, setModalForm] = useState({
    data: null,
    isOpenModal: false,
  })
  const [modalFormProfileGuild, setModalFormProfileGuild] = useState({
    data: null,
    isOpenModal: false,
  })
  const [isModalOpenDelete, setIsModalOpenDelete] = useState({
    isOpenModal: false,
  })

  const showModalDelete = (content, data) => {
    setIsModalOpenDelete({
      content: content,
      data: data,
      isOpenModal: true,
    })
  }
  const currentTimeUTC = Math.floor(Date.now() / 1000)
  const {
    actions: poolsActions,
    data: { listPool },
  } = usePools()
  const {
    actions,
    data: { listGuild, isLoading, scoreGuild },
  } = useGuild()

  const filterGuild = listGuild?.filter((item) => {
    return item?.is_draft === false
  })

  const paginatedData = filterGuild?.slice(
    (page - 1) * pageSize,
    page * pageSize,
  )
  const usersWithRole0 = filterGuild?.flatMap((guild) =>
    guild.guild_member.map((member) => member.user_wallet),
  )

  const {
    actions: getListUserByWallet,
    data: { listUserByWallet },
  } = useUser()

  useEffect(() => {
    const params = {
      search: keyword,
      pageSize: pageSize,
      page,
      allWallets: usersWithRole0,
    }
    getListUserByWallet.getUserByWallet(params)
  }, [listGuild, keyword, pageSize, page])
  const from_date_score = Math.min(...listPool.map((item) => item.timeStart))
  const to_date_score = Math.max(...listPool.map((item) => item.timeEnd))

  const refreshData = () => {
    const params = {
      guild_name: keyword,
    }
    const paramsGuildScore = {
      guild_id: filterGuild?.map((item) => item._id),
      from_date: from_date_score,
      to_date: to_date_score,
    }
    if (from_date_score && to_date_score) {
      actions.guildScore(paramsGuildScore)
    }

    actions.getListGuilds(params)
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword, from_date_score, to_date_score])
  const onRemove = (data) => {
    showModalDelete(t('cancel_modal.question', { question: '' }), data)
  }

  useEffect(() => {
    poolsActions.getListPools()
  }, [])

  const currentCycle = useMemo(
    () =>
      listPool?.find(
        (cycle) =>
          currentTimeUTC >= cycle.timeStart && currentTimeUTC <= cycle.timeEnd,
      ),
    [listPool, currentTimeUTC],
  )
  // const dataGetGuildScore = {
  //   guild_score: [
  //     {
  //       _id: '67b6a745ccba1910aeda2c8e',
  //       guild_id: '6761010d254c26d717d2c1d2',
  //       guild_name: 'ĐoM ĐóM',
  //       cycle: 2,
  //       cycle_name: 'Dom DOm',
  //       from_date: 1738368000,
  //       to_date: 1740787199,
  //       oas_earned: '0',
  //       ranked_score: 2,
  //       explore_score: 11,
  //       guild_placement: 0,
  //       total_placement: 0,
  //       is_finalize: false,
  //       crt_dt: '2025-02-20T03:53:41.014Z',
  //       crt_by: '0x926A80dEfCfb7130E02E1BE68fF52354E865d6c8',
  //       del_if: 0,
  //     },
  //     {
  //       _id: '67b6a745ccba1910aeda2c8e',
  //       guild_id: '6761010d254c26d717d2c1d2',
  //       guild_name: 'ĐoM ĐóM',
  //       cycle: 2,
  //       cycle_name: 'Dom DOm',
  //       from_date: 1740787200,
  //       to_date: 1743465599,
  //       oas_earned: '0',
  //       ranked_score: 9,
  //       explore_score: 1,
  //       guild_placement: 0,
  //       total_placement: 0,
  //       is_finalize: false,
  //       crt_dt: '2025-02-20T03:53:41.014Z',
  //       crt_by: '0x926A80dEfCfb7130E02E1BE68fF52354E865d6c8',
  //       del_if: 0,
  //     },
  //     {
  //       _id: '67b6a745ccba1910aeda2c8e',
  //       guild_id: '6761010d254c26d717d2c1d2',
  //       guild_name: 'ĐoM ĐóM',
  //       cycle: 1,
  //       cycle_name: 'Drace Cycle 2',
  //       from_date: 1733011200,
  //       to_date: 1767113999,
  //       oas_earned: '0',
  //       ranked_score: 10,
  //       explore_score: 4,
  //       guild_placement: 0,
  //       total_placement: 0,
  //       is_finalize: false,
  //       crt_dt: '2025-02-20T03:53:41.014Z',
  //       crt_by: '0x926A80dEfCfb7130E02E1BE68fF52354E865d6c8',
  //       del_if: 0,
  //     },
  //     {
  //       _id: '67c2937cb9662c241980f208',
  //       guild_id: '6768d76f59b73feaf9f9d96b',
  //       guild_name: 'Guild Test A',
  //       cycle: 2,
  //       cycle_name: 'Dom DOm',
  //       from_date: 1740787200,
  //       to_date: 1743465599,
  //       oas_earned: '0',
  //       ranked_score: 7,
  //       explore_score: 1,
  //       guild_placement: 0,
  //       total_placement: 0,
  //       is_finalize: false,
  //       crt_dt: '2025-03-01T04:56:28.977Z',
  //       crt_by: '0xE085260EB256c33159b232d4e6feDA2c19E1a934',
  //       del_if: 0,
  //     },
  //   ],
  // }
  const columns = [
    {
      title: (
        <div className="flex items-center">
          <img
            className="w-[25px] h-[25px] mr-2 mt-[2px]"
            src={`${process.env.PUBLIC_URL}/svgs/guild/guild.svg`}
            alt=""
          />
          {t('managementGuilds.form.nameGuild')}
        </div>
      ),
      dataIndex: 'guild_name',
      key: 'guild_name',
      render: (guild_name) => {
        return (
          <div className="" style={{ fontFamily: 'Rexliafree' }}>
            {guild_name}
          </div>
        )
      },
    },
    {
      title: t('managementGuilds.form.level'),
      dataIndex: 'guild_level',
      key: 'guild_level',
      render: (guild_level) => {
        return <div style={{ fontFamily: 'Rexliafree' }}>{guild_level}</div>
      },
    },

    {
      title: t('managementGuilds.form.rank'),
      dataIndex: 'rank',
      key: 'rank',
      render: (_, _record) => {
        const dataGuildScore = _record?.guild_score?.filter(
          (item) =>
            Number(item.from_date) === getStartOfMonthUTC() &&
            Number(item.to_date) === getEndOfMonthUTC(),
        )

        return (
          <div className="flex" style={{ fontFamily: 'Rexliafree' }}>
            <div className="flex items-center justify-center">
              <img
                className="w-[20px] h-[20px] mr-3 "
                src={`${process.env.PUBLIC_URL}/svgs/guild/1.svg`}
                alt=""
              />
            </div>
            {dataGuildScore?.length > 0 ? (
              <>{dataGuildScore[0]?.guild_placement}</>
            ) : (
              'ー'
            )}
          </div>
        )
      },
    },

    {
      title: (
        <div className="flex ml-[3rem]">{t('managementGuilds.form.score')}</div>
      ),
      dataIndex: 'score',
      width: 300,
      key: 'score',
      render: (_, data) => {
        const totalData = scoreGuild?.guild_score
          ?.map((item) => ({
            ...item,
            totalScore: item.explore_score + item.ranked_score,
          }))
          .filter((item) => item.guild_id === data._id)

        const currentMonthEntriesTotal = totalData?.find((item) => {
          const fromDate = parseInt(item.from_date, 10)
          const toDate = parseInt(item.to_date, 10)
          return (
            fromDate <= getEndOfMonthUTC() && toDate >= getStartOfMonthUTC()
          )
        })
        const totalScoreCycle = totalData
          ?.filter((item) => Number(item.cycle) === currentCycle?.cycle)
          ?.reduce((sum, item) => sum + item?.totalScore, 0)
        const totalScoreGuild = totalData?.reduce(
          (sum, item) => sum + item?.totalScore,
          0,
        )

        return (
          <div className="flex-center" style={{ fontFamily: 'Rexliafree' }}>
            {scoreGuild?.guild_score?.length > 0 ? (
              <Row gutter={24}>
                <Col span={8} className="flex">
                  <div className="flex items-center justify-center">
                    <img
                      className="w-[20px] h-[20px] mr-3 "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                      alt=""
                    />
                  </div>
                  <div>
                    <div>Total</div>
                    <div>Month</div>
                    <div>Cycle</div>
                  </div>
                </Col>
                <Col span={16}>
                  <React.Fragment>
                    <div className="flex flex-col mb-1 items-start">
                      <div className="flex items-center h-[25px] flex-col  ">
                        <div>{formatNumber(totalScoreGuild)}</div>
                        <div>
                          {formatNumber(currentMonthEntriesTotal?.totalScore)}
                        </div>
                        <div>{formatNumber(totalScoreCycle)}</div>
                      </div>
                    </div>
                  </React.Fragment>
                </Col>
              </Row>
            ) : (
              <Row gutter={24}>
                <Col span={8} className="flex">
                  <div className="flex items-center justify-center">
                    <img
                      className="w-[20px] h-[20px] mr-3 "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                      alt=""
                    />
                  </div>
                  <div>
                    <div>Total</div>
                    <div>Month</div>
                    <div>Cycle</div>
                  </div>
                </Col>
                <Col span={16}>
                  <React.Fragment>
                    <div className="flex flex-col mb-1 items-start">
                      <div className="flex items-center h-[25px] flex-col  ">
                        <div>0</div>
                        <div>0</div>
                        <div>0</div>
                      </div>
                    </div>
                  </React.Fragment>
                </Col>
              </Row>
            )}
          </div>
        )
      },
    },
    {
      title: (
        <div className="flex items-center">
          <img
            className="w-[15px] h-[15px] mr-2 mt-[2px]"
            src={`${process.env.PUBLIC_URL}/svgs/guild/master.svg`}
            alt=""
          />
          {t('managementGuilds.form.master')}
        </div>
      ),
      dataIndex: 'guild_member',
      key: 'guild_member',
      render: (_record) => {
        const findMemberRole = _record?.find(
          (member) => member.member_role === 0,
        )

        if (findMemberRole) {
          const user = listUserByWallet?.find(
            (user) => user.address_wallet === findMemberRole.user_wallet,
          )

          if (user) {
            return (
              <div
                style={{
                  fontFamily: 'Rexliafree',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  className="w-[30px] h-[30px] mr-2"
                  src={
                    user.avatar ||
                    `${process.env.PUBLIC_URL}/default-avatar.png`
                  }
                  alt=""
                />
                <span className="max-w-[80px] overflow-hidden whitespace-nowrap text-ellipsis">
                  {user.user_name || 'Anonymous'}
                </span>
              </div>
            )
          }
        }
        return 'ー'
      },
    },
    {
      title: t('managementGuilds.form.member'),
      dataIndex: 'guild_member',
      key: 'guild_member',
      render: (_record, member) => {
        const maxMembers = dataGuildLevel(member?.guild_level)?.maxMembers
        const uniqueGuildMembers = _record?.filter(
          (member, index, self) =>
            index ===
            self.findIndex((m) => m.user_wallet === member.user_wallet),
        )

        return (
          <div style={{ fontFamily: 'Rexliafree' }}>
            {uniqueGuildMembers?.length}/{maxMembers}
          </div>
        )
      },
    },
    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={
                <img
                  className="w-[20px] h-[20px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/infoGuild.svg`}
                  alt=""
                />
              }
              onClick={() => {
                onShowModalFormGuild(data, data)
              }}
            />
            <Button
              type="ghost"
              loading={false}
              onClick={() => onShowModalForm(data, data)}
              icon={
                <img
                  className="w-[20px] h-[20px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/userGuild.svg`}
                  alt=""
                />
              }
            />

            <Button
              type="ghost"
              loading={false}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
              onClick={() => {
                onRemove(data)
              }}
            />
          </Space>
        )
      },
    },
  ]

  const onShowModalForm = (data) => {
    setModalForm({
      data,
      isOpenModal: true,
    })
  }

  const onShowModalFormGuild = (data) => {
    setModalFormProfileGuild({
      data,
      isOpenModal: true,
    })
  }

  const onCloseModalForm = () => {
    setModalForm({
      data: null,
      isOpenModal: false,
    })
  }
  const onCloseModalFormGuild = () => {
    setModalFormProfileGuild({
      data: null,
      isOpenModal: false,
    })
  }

  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }
  const handleCancelDelete = () => {
    setIsModalOpenDelete({
      content: null,
      data: null,
      isOpenModal: false,
    })
  }
  const handleExportToExcel = () => {
    const formattedData = filterGuild.map((item) => {
      const dataGuildScore = item.guild_score?.filter(
        (score) =>
          Number(score.from_date) === getStartOfMonthUTC() &&
          Number(score.to_date) === getEndOfMonthUTC(),
      )

      const findMemberRole = item.guild_member?.find(
        (member) => member.member_role === 0,
      )

      let userName = 'Unknown'
      if (findMemberRole) {
        const user = listUserByWallet?.find(
          (user) => user.address_wallet === findMemberRole.user_wallet,
        )
        if (user) userName = user.user_name
      }

      const maxMembers = dataGuildLevel(item?.guild_level)?.maxMembers || 0

      const uniqueGuildMembers = item.guild_member?.filter(
        (member, index, self) =>
          index === self.findIndex((m) => m.user_wallet === member.user_wallet),
      )

      const totalData = item?.guild_score?.map((score) => ({
        ...score,
        totalScore: score.exploration_score + score.ranked_score,
      }))

      const currentMonthEntriesTotal = totalData?.find((score) => {
        const fromDate = parseInt(score.from_date, 10)
        const toDate = parseInt(score.to_date, 10)
        return fromDate <= getEndOfMonthUTC() && toDate >= getStartOfMonthUTC()
      })

      const totalScoreCycle = totalData
        ?.filter((score) => Number(score.cycle) === currentCycle?.cycle)
        ?.reduce((sum, score) => sum + score?.totalScore, 0)

      const totalScoreGuild = totalData?.reduce(
        (sum, score) => sum + score?.totalScore,
        0,
      )

      return {
        'Guild Image': item.guild_image,
        'Guild Name': item.guild_name,
        'Guild Level': item.guild_level,
        'Guild Rank':
          dataGuildScore?.length > 0
            ? dataGuildScore[0]?.guild_placement
            : 'ー',
        'Total Score': totalScoreGuild || 0,
        'Month Score': currentMonthEntriesTotal?.totalScore || 0,
        'Cycle Score': totalScoreCycle || 0,
        'Guild Master': userName,
        'Guild Member': `${uniqueGuildMembers?.length}/${maxMembers}`,
        'Guild exp': item.guild_exp,
        'Guild Introduction': item.guild_introduction,
        'Guild Notice': item.guild_notice,
        'Guild Creation Date': TimeHelper(item.crt_dt),
      }
    })

    // Tạo sheet Excel
    const ws = XLSX.utils.json_to_sheet(formattedData)

    ws['!cols'] = [
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 25 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ] // Thiết lập độ rộng cột

    // Tạo workbook và xuất file
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Guild List')
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(data, 'Guilds.xlsx')
  }

  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Button
            style={{ backgroundColor: '#1890FF' }}
            onClick={handleExportToExcel}
          >
            Export Guild
          </Button>
        </Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <Form
                onFinish={handleSearch}
                className="w-full sm:w-auto"
                form={formSearch}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {t('common.search')}
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Input className="w-full" placeholder={t('common.search')} />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={paginatedData}
        loading={isLoading}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: filterGuild?.length,
          keyword,
          setKeyword,
        }}
      />

      {modalForm.isOpenModal && (
        <UserGuild
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          data={modalForm.data}
          currentCycle={currentCycle}
          listPool={listPool}
          listUserByWallet={listUserByWallet}
        />
      )}
      {modalFormProfileGuild.isOpenModal && (
        <GuildDetail
          isShowModal={modalFormProfileGuild.isOpenModal}
          onClose={onCloseModalFormGuild}
          data={modalFormProfileGuild.data}
          dataGetGuildScore={scoreGuild}
          listPool={listPool}
          listUserByWallet={listUserByWallet}
          totalGuild={filterGuild?.length}
        />
      )}

      <ConfirmDeleteGuild
        action={{
          isModalOpen: isModalOpenDelete.isOpenModal,
          handleCancel: handleCancelDelete,
        }}
        refreshData={refreshData}
        dataGuild={isModalOpenDelete.data}
        t={t}
      />
    </div>
  )
}

export default ManagementGuilds
