import PropTypes from 'prop-types'
// import { useTranslation } from '~/common/hooks/useTranslation'
import { Form, Modal, Button, Row, Col, Tooltip, Avatar } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useUser } from '~/views/app/redux/hooks/useUser'
import {
  converRole,
  converUserRankUser,
  getStartOfMonthUTC,
} from '~/common/constants'
import { formatCreateAtProfile, formatPhoneNumber } from '~/helpers/time-helper'
import { useTranslation } from '~/common/hooks/useTranslation'
import { useGuild } from '~/views/app/redux/hooks/useGuild'
import { useSector } from '~/views/app/redux/hooks/useSector'
import { formatNumber } from '~/helpers/common'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
function Profile(props) {
  const { t } = useTranslation()
  const { isShowModal, onClose, id, user, address, currentCycle } = props

  const {
    actions,
    data: { userDetail, listSnapshot },
  } = useUser()

  const {
    actions: guildActions,
    data: { listGuild },
  } = useGuild()
  const filterGuild = listGuild?.filter((item) => {
    return item?.is_draft === false
  })

  const {
    actions: getSectorActions,
    data: { listSector },
  } = useSector()

  const [form] = Form.useForm()

  const handleClose = () => {
    form.resetFields()
    onClose()
  }
  //Refresh data

  const refreshData = () => {
    actions.getDetailUser(id)
    guildActions.getListGuilds()
  }
  useEffect(() => {
    if (currentCycle) {
      getSectorActions.getListSectors({
        from_date: currentCycle.timeStart,
        to_date: currentCycle.timeEnd,
      })
    }
    const paramScoreSnapShot = {
      score_type: 'SECTOR',
      from_date: currentCycle?.timeStart,
      to_date: currentCycle?.timeEnd,
    }
    actions.getScoreSnapShotAddress(paramScoreSnapShot)
  }, [currentCycle])

  useEffect(() => {
    refreshData()
  }, [isShowModal, id])

  const findGuild = useMemo(
    () =>
      filterGuild?.find((guild) =>
        guild?.guild_member?.some(
          (member) => member.user_wallet === user.address_wallet,
        ),
      ),
    [filterGuild, user.address_wallet],
  )
  const filteRRoleUser = findGuild?.guild_member?.find(
    (item) => item.user_wallet === user.address_wallet,
  )
  const filterScoreRankUser = listSnapshot?.find(
    (item) => item.user === address,
  )

  const findSector = useMemo(() => {
    return listSector?.data
      ?.map((sector) => ({
        sector_id: sector._id,
        sector_name: sector.sector_name,
        sector: sector.sector,
        sector_cycle_id: sector.sector_cycle_id,
        sector_cycle_name: sector.sector_cycle_name,
        sector_member: sector.sector_user,
        exploration_score: sector.explore_score,
        ranked_score: sector.ranked_score,
        total: (
          Number(sector.explore_score) + Number(sector.ranked_score)
        ).toFixed(0),
      }))
      .sort((a, b) => b.total - a.total)
      .map((item, index) => ({
        ...item,
        rank: index + 1,
      }))
      .filter((item) => item.sector_member.includes(user.address_wallet))
  }, [user.address_wallet, listSector])

  const filterCyclePool = findSector?.find(
    (item) => Number(item?.sector_cycle_id) === currentCycle?.cycle,
  )

  useEffect(() => {
    if (isShowModal) {
      refreshData()
    }
  }, [isShowModal])

  const total = (exploration_score, ranked_score) => {
    return (Number(ranked_score) + Number(exploration_score)).toLocaleString(
      'en-US' || 0,
    )
  }

  const filterUserOfGuild = findGuild?.guild_score?.find(
    (item) => Number(item.from_date) === getStartOfMonthUTC(),
  )
  const backgroundColor = (value) => {
    switch (value) {
      case 0:
        return 'rgba(12, 200, 254, 0.4)'
      case 1:
        return 'rgba(79, 252, 88, 0.4)'
      case 2:
        return 'rgba(238, 1, 9, 0.4)'
      default:
        return ''
    }
  }
  const borderColor = (value) => {
    switch (value) {
      case 0:
        return '1px solid rgba(12, 200, 254, 0.4)'
      case 1:
        return ' 1px solid rgba(79, 252, 88, 0.4)'
      case 2:
        return '1px solid rgba(238, 1, 9, 0.4)'
      default:
        return ''
    }
  }

  const handleExportToExcel = () => {
    const rankedScore = Number(filterScoreRankUser?.ranked_score ?? 0).toFixed(
      0,
    )
    const exploreScore = Number(
      filterScoreRankUser?.explore_score ?? 0,
    ).toFixed(0)

    const formattedData = [
      {
        Avatar: userDetail?.user?.avatar ?? '../svgs/camera.svg',
        'User Name': userDetail?.user?.user_name,
        'Player Rank': converUserRankUser(
          userDetail?.playerGameProgress?.player_rank,
        ),
        'Account Creation Date': formatCreateAtProfile(
          userDetail?.user?.crt_dt,
        ),
        Bio: userDetail?.user?.player_bio,
        Phone: formatPhoneNumber(userDetail?.user?.player_phone),
        'Score Rank (Cycle)': filterScoreRankUser?.rank ?? 0,
        'Cycle Score': total(Number(rankedScore), Number(exploreScore)),
        'Rank match': Number(rankedScore).toLocaleString('en-US'),
        Exploration: Number(exploreScore).toLocaleString('en-US'),
        'Total Score': total(Number(rankedScore), Number(exploreScore)),
        'Rank match score': Number(rankedScore).toLocaleString('en-US'),
        'Exploration score': Number(exploreScore).toLocaleString('en-US'),
        'Guild Name': findGuild?.guild_name,
        Role: converRole(filteRRoleUser?.member_role),
        Rank:
          filterUserOfGuild?.guild_placement ||
          filterUserOfGuild?.guild_placement !== undefined
            ? `${filterUserOfGuild?.guild_placement}/${filterUserOfGuild?.total_placement}`
            : '/',
        'Score guild': total(
          filterUserOfGuild?.exploration_score ?? 0,
          filterUserOfGuild?.ranked_score ?? 0,
        ),
        'Sector Name': filterCyclePool?.sector_name,
        'Rank sector': `${filterCyclePool?.rank} ${
          filterCyclePool?.rank === 1
            ? 'st'
            : filterCyclePool?.rank === 2
            ? 'nd'
            : filterCyclePool?.rank === 3
            ? 'rd'
            : 'th'
        }`,
        'Score sector': formatNumber(Number(filterCyclePool?.total)),
      },
    ]

    // Tạo sheet Excel
    const ws = XLSX.utils.json_to_sheet(formattedData)

    ws['!cols'] = [
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ]

    // Tạo workbook và xuất file
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Profile user')
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(data, 'Profileuser.xlsx')
  }

  return (
    <Modal
      title={<>{t('managementUsers.form.profile')} </>}
      onCancel={handleClose}
      open={isShowModal}
      width={850}
      centered
      destroyOnClose
      footer={
        <div className="flex justify-end ">
          <Button
            style={{ backgroundColor: '#1890FF' }}
            onClick={handleExportToExcel}
          >
            Export Profile
          </Button>
        </div>
      }
    >
      <div className="font-normal">
        <Row gutter={24} style={{ fontFamily: 'Rexliafree' }}>
          <Col span={12} className="flex">
            <div>
              <img
                className="w-[70px] h-[70px] "
                src={userDetail?.user?.avatar ?? '../svgs/camera.svg'}
                alt="User Avatar"
              />
            </div>

            <div className="flex flex-col justify-center ml-2">
              <Tooltip title={userDetail?.user?.user_name}>
                <div className="mb-4 text-2xl ">
                  {userDetail?.user?.user_name &&
                  userDetail?.user?.user_name.length > 9
                    ? userDetail?.user?.user_name.substring(0, 9) + '...'
                    : userDetail?.user?.user_name}
                </div>
              </Tooltip>
              <div>
                {converUserRankUser(
                  userDetail?.playerGameProgress?.player_rank,
                )}
              </div>
            </div>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <div className="mb-[10px]">Account Creation Date</div>
            <div className="text-[20px]">
              {formatCreateAtProfile(userDetail?.user?.crt_dt)}
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            {userDetail?.user?.player_bio && (
              <>
                <div
                  className="text-[20px]"
                  style={{ fontFamily: 'Rexliafree' }}
                >
                  Bio
                </div>
                <div className="text-justify">
                  {userDetail?.user?.player_bio}
                </div>
              </>
            )}

            <div className="flex mt-[10px]">
              {userDetail?.user?.player_twitter ? (
                <Button
                  style={{
                    padding: '10px 24px',
                    gap: '4',
                  }}
                >
                  <img
                    className="w-[16px] h-[16px] mr-1"
                    src={`${process.env.PUBLIC_URL}/svgs/twitter.svg`}
                    alt=""
                  />
                  @{userDetail?.user?.player_twitter}
                </Button>
              ) : (
                ''
              )}

              <div style={{ margin: '0 5px' }}></div>
              {userDetail?.user?.player_phone ? (
                <Button style={{ background: '#1890FF' }}>
                  {formatPhoneNumber(userDetail?.user?.player_phone)}
                </Button>
              ) : (
                ''
              )}
            </div>
            <div>
              <Row style={{ fontFamily: 'Rexliafree' }}>
                <Col span={18}>
                  <div className="text-[16px]" style={{ margin: '16px 0' }}>
                    Score Rank (Cycle)
                  </div>
                  <div className="text-[16px]">Cycle Score</div>
                  <div>Rank match</div>
                  <div>Exploration</div>
                  <div
                    className="text-[16px] "
                    style={{ margin: '16px 0 0 0' }}
                  >
                    Total Score
                  </div>
                  <div>Rank match</div>
                  <div>Exploration</div>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                  <div className="text-[16px]" style={{ margin: '16px 0' }}>
                    {filterScoreRankUser?.rank ?? 0}
                  </div>
                  <div className="text-[16px]">
                    {total(
                      Number(filterScoreRankUser?.ranked_score.toFixed(0) ?? 0),
                      Number(
                        filterScoreRankUser?.explore_score.toFixed(0) ?? 0,
                      ),
                    )}
                  </div>
                  <div>
                    {Number(
                      filterScoreRankUser?.ranked_score.toFixed(0) ?? 0,
                    ).toLocaleString('en-US')}
                  </div>
                  <div>
                    {Number(
                      filterScoreRankUser?.explore_score.toFixed(0) ?? 0,
                    ).toLocaleString('en-US')}
                  </div>
                  <div className="text-[16px]" style={{ margin: '16px 0 0 0' }}>
                    {total(
                      Number(filterScoreRankUser?.ranked_score.toFixed(0) ?? 0),
                      Number(
                        filterScoreRankUser?.explore_score.toFixed(0) ?? 0,
                      ),
                    )}
                  </div>
                  <div>
                    {Number(
                      filterScoreRankUser?.ranked_score.toFixed(0) ?? 0,
                    ).toLocaleString('en-US')}
                  </div>
                  <div>
                    {Number(
                      filterScoreRankUser?.explore_score.toFixed(0) ?? 0,
                    ).toLocaleString('en-US')}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            {/* Guild Name */}
            <div
              style={{
                background: '#000F38',
                border: '1px solid #1890FF',
                borderRadius: '5px',
              }}
            >
              <Row style={{ fontFamily: 'Rexliafree' }}>
                <Col
                  span={3}
                  style={{
                    background: 'rgba(24, 144, 255, 0.50)',
                    border: '1px solid #1890FF ',
                    borderRadius: '5px 0 0 5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    className="w-[32px] h-[32px]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/dashicons_shield.svg`}
                    alt=""
                  />
                </Col>
                <Col span={21} style={{ padding: '16px' }}>
                  {findGuild && (
                    <>
                      <Row>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            className={` ${
                              findGuild?.guild_image?.startsWith(
                                process.env.REACT_APP_BASE_URL_IMAGE,
                              )
                                ? 'h-[40px] mb-2'
                                : 'w-[40px] h-[40px] mr-2 '
                            }`}
                            src={
                              findGuild?.guild_image?.startsWith(
                                process.env.REACT_APP_BASE_URL_IMAGE,
                              )
                                ? findGuild.guild_image
                                : `${process.env.PUBLIC_URL}/svgs/guild/ImageGuild/${findGuild?.guild_image}.svg`
                            }
                            alt=""
                          />
                          <div className="text-[20px] ml-4">
                            {findGuild?.guild_name}
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ margin: '13px 0' }}>
                        <Col span={10} className="text-[20px]">
                          <div>Role</div>
                        </Col>
                        <Col
                          span={14}
                          className="text-[16px]"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                          }}
                        >
                          <div>{converRole(filteRRoleUser?.member_role)}</div>
                        </Col>
                      </Row>
                      <Row style={{ margin: '13px 0' }}>
                        <Col span={10} className="text-[20px]">
                          <div>Rank</div>
                        </Col>
                        <Col
                          span={14}
                          className="text-[16px]"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                          }}
                        >
                          <div>
                            {filterUserOfGuild?.guild_placement}/
                            {filterUserOfGuild?.total_placement}
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ margin: '13px 0 0 0' }}>
                        <Col span={10} className="text-[20px]">
                          <div>Score</div>
                        </Col>
                        <Col
                          span={14}
                          className="text-[16px]"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                          }}
                        >
                          <div>
                            {total(
                              filterUserOfGuild?.exploration_score ?? 0,
                              filterUserOfGuild?.ranked_score ?? 0,
                            )}
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  {!findGuild && <div className="text-[20px]">NO GUILD</div>}
                </Col>
              </Row>
            </div>
            {/* Echo-Leaves */}
            <div
              style={{
                background: '#000F38',
                border: '1px solid #52C41A',
                borderRadius: '5px',
                marginTop: '4%',
              }}
            >
              <Row style={{ fontFamily: 'Rexliafree' }}>
                <Col
                  span={3}
                  style={{
                    background: backgroundColor(filterCyclePool?.sector),
                    // border: '1px solid #1890FF ',
                    border: borderColor(filterCyclePool?.sector),
                    borderRadius: '5px 0 0 5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    className="w-[32px] h-[32px]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/fluent-mdl2_quarter-circle.svg`}
                    alt=""
                  />
                </Col>
                {filterCyclePool && (
                  <Col
                    span={21}
                    style={{
                      padding: '16px',
                      background: backgroundColor(filterCyclePool?.sector),
                    }}
                  >
                    <img
                      className="absolute left-0 top-0 w-[90%] h-[90%] "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logoSector/${filterCyclePool?.sector}.svg`}
                      alt=""
                    />
                    <Row>
                      <Col style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          className="w-[55px] h-[55px] mr-2 "
                          src={`${process.env.PUBLIC_URL}/svgs/guild/sector/${filterCyclePool?.sector}.svg`}
                          alt=""
                        />
                        <div className="text-[20px] ml-4">
                          {filterCyclePool?.sector_name}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ margin: '13px 0' }}>
                      <Col span={10} className="text-[20px]">
                        <div>Rank</div>
                      </Col>
                      <Col
                        span={14}
                        className="text-[16px]"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div className="flex items-center ">
                          <div className="text-[20px]">
                            {filterCyclePool?.rank}
                          </div>
                          <div className="text-[16px]">
                            {filterCyclePool?.rank === 1
                              ? 'st'
                              : filterCyclePool?.rank === 2
                              ? 'nd'
                              : 'rd'}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ margin: '13px 0 0 0' }}>
                      <Col span={10} className="text-[20px]">
                        <div>Score</div>
                      </Col>
                      <Col
                        span={14}
                        className="text-[16px]"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div>
                          {formatNumber(Number(filterCyclePool?.total))}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}
                {!filterCyclePool && (
                  <div className="text-[20px] p-[10px]">NO SECTOR</div>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

Profile.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
  user: PropTypes.string,
  address: PropTypes.string,
}

export default Profile
