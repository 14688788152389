export const GET_LIST_NOTIFICATION_START = 'GET_LIST_NOTIFICATION_START'
export const GET_LIST_NOTIFICATION_SUCCESS = 'GET_LIST_NOTIFICATION_SUCCESS'
export const GET_LIST_NOTIFICATION_FAILED = 'GET_LIST_NOTIFICATION_FAILED'

export const ADD_NOTIFICATION_START = 'ADD_NOTIFICATION_START'
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS'
export const ADD_NOTIFICATION_FAILED = 'ADD_NOTIFICATION_FAILED'

export const EDIT_NOTIFICATION_START = 'EDIT_NOTIFICATION_START'
export const EDIT_NOTIFICATION_SUCCESS = 'EDIT_NOTIFICATION_SUCCESS'
export const EDIT_NOTIFICATION_FAILED = 'EDIT_NOTIFICATION_FAILED'

export const DELETE_NOTIFICATION_START = 'DELETE_NOTIFICATION_START'
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS'
export const DELETE_NOTIFICATION_FAILED = 'DELETE_NOTIFICATION_FAILED'

//-------------------------notification------------------------//

/**
 * [1]
 * get list notification start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getNotificationLists(payload, onSuccess, onError) {
  return {
    type: GET_LIST_NOTIFICATION_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list notification success action
 * @param {*} payload
 * @returns {object}
 */
export function getNotificationListsSuccess(payload) {
  return {
    type: GET_LIST_NOTIFICATION_SUCCESS,
    payload: payload,
  }
}

/**
 * get list notification failed action
 * @returns {object}
 */
export function getNotificationListsFailed() {
  return {
    type: GET_LIST_NOTIFICATION_FAILED,
  }
}

/**
 * [2]
 * add notification start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function addNotification(payload, onSuccess, onError) {
  return {
    type: ADD_NOTIFICATION_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * add notification success action
 * @param {*} payload
 * @returns {object}
 */
export function addNotificationSuccess(payload) {
  return {
    type: ADD_NOTIFICATION_SUCCESS,
    payload: payload,
  }
}

/**
 * add notification failed action
 * @returns {object}
 */
export function addNotificationFailed() {
  return {
    type: ADD_NOTIFICATION_FAILED,
  }
}

/**
 * [3]
 * edit notification start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function editNotification(payload, onSuccess, onError) {
  return {
    type: EDIT_NOTIFICATION_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * edit notification success action
 * @param {*} payload
 * @returns {object}
 */
export function editNotificationSuccess(payload) {
  return {
    type: EDIT_NOTIFICATION_SUCCESS,
    payload: payload,
  }
}

/**
 * edit notification failed action
 * @returns {object}
 */
export function editNotificationFailed() {
  return {
    type: EDIT_NOTIFICATION_FAILED,
  }
}

/**
 * [4]
 * delete notification start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function deleteNotification(payload, onSuccess, onError) {
  return {
    type: DELETE_NOTIFICATION_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * delete notification success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteNotificationSuccess(payload) {
  return {
    type: DELETE_NOTIFICATION_SUCCESS,
    payload: payload,
  }
}

/**
 * delete notification failed action
 * @returns {object}
 */
export function deleteNotificationFailed() {
  return {
    type: DELETE_NOTIFICATION_FAILED,
  }
}

export default {
  getNotificationLists,
  getNotificationListsSuccess,
  getNotificationListsFailed,

  addNotification,
  addNotificationSuccess,
  addNotificationFailed,

  editNotification,
  editNotificationSuccess,
  editNotificationFailed,

  deleteNotification,
  deleteNotificationSuccess,
  deleteNotificationFailed,
}
