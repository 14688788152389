import { addKeyToList } from '~/helpers/transform-data'
import {
  GET_LIST_NOTIFICATION_START,
  GET_LIST_NOTIFICATION_SUCCESS,
  GET_LIST_NOTIFICATION_FAILED,
  ADD_NOTIFICATION_START,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_FAILED,
  EDIT_NOTIFICATION_START,
  EDIT_NOTIFICATION_SUCCESS,
  EDIT_NOTIFICATION_FAILED,
  DELETE_NOTIFICATION_START,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILED,
} from '../actions/notification'

const initialState = {
  isLoadingGetNotificationList: false,
  isLoading: false,
  isLoadingDelete: false,
  listNotification: [],
  dataDelete: {},
}

/**
 * notifications reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function notificationsManagement(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_NOTIFICATION_START:
      return {
        ...state,
        isLoadingGetNotificationList: true,
      }

    case ADD_NOTIFICATION_START:
    case EDIT_NOTIFICATION_START:
      return {
        ...state,
        isLoading: true,
      }
    case DELETE_NOTIFICATION_START:
      return {
        ...state,
        isLoadingDelete: true,
      }

    //

    case GET_LIST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        listNotification: addKeyToList(
          action?.payload?.data,
          action.payload?.currentPage,
          action.payload?.pageSize,
        ),
        total: action?.payload?.total,
        isLoadingGetNotificationList: false,
      }

    case ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case EDIT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
        dataDelete: action?.payload,
      }

    //
    case GET_LIST_NOTIFICATION_FAILED:
      return {
        ...state,
        isLoadingGetNotificationList: true,
      }

    case ADD_NOTIFICATION_FAILED:
    case EDIT_NOTIFICATION_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_NOTIFICATION_FAILED:
      return {
        ...state,
        isLoadingDelete: false,
      }
    default:
      return state
  }
}
