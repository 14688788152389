import { Button, Col, Modal, Row } from 'antd'
import { useState } from 'react'
export default function StatistcsDetail(props) {
  const { t = {}, title = null, action = {}, dataNotification } = props
  const [btnDetail, setBtnDetail] = useState('paid')
  const dataTest = {
    paid: [
      {
        attribute: 'Rank E',
        totalAmount: 123,
        ratio: '5%',
      },
      {
        attribute: 'Rank B',
        totalAmount: 123,
        ratio: '5%',
      },
      {
        attribute: 'Rank A',
        totalAmount: 55523.555,
        ratio: '15%',
      },
    ],
    required: [
      {
        attribute: 'Rank E required',
        totalAmount: 123,
        ratio: '5%',
      },
      {
        attribute: 'Rank B required',
        totalAmount: 123,
        ratio: '5%',
      },
      {
        attribute: 'Rank A required',
        totalAmount: 55523.555,
        ratio: '15%',
      },
    ],
  }

  const renderTableRows = (data) =>
    data.map((item, index) => (
      <Row key={index} className="mt-3">
        <Col xl={8} md={8} sm={24} xs={24}>
          <div className="flex justify-center">{item.attribute}</div>
        </Col>
        <Col xl={8} md={8} sm={24} xs={24}>
          <div className="flex justify-center">{item.totalAmount}</div>
        </Col>
        <Col xl={8} md={8} sm={24} xs={24}>
          <div className="flex justify-center">{item.ratio}</div>
        </Col>
      </Row>
    ))
  return (
    <Modal
      centered
      width={800}
      getContainer={false}
      title={
        <h3 className="flex justify-center text-xl font-normal text-[#fff]">
          {title}
        </h3>
      }
      open={action?.isModalOpen}
      onCancel={action?.handleCancel}
      footer={null}
    >
      <div className="flex justify-center align-center !border !border-[#40A9FF] rounded-md">
        <Col xl={20} md={20} sm={24} xs={24}>
          <div className="grid grid-cols-1 sm:grid-cols-2 h-full min-h-[164px]">
            <div className="h-full flex flex-col justify-center">
              <div className="text-[16px] font-bold mb-1">
                {t('managementPools.rankingRewards.totalPools')}:
              </div>
              <div className="text-[24px] text-[#40A9FF] font-bold">11111</div>
            </div>
            <div className="h-full flex flex-col justify-center">
              <div className="text-[16px] font-bold mb-1">
                {t('managementPools.rankingRewards.totalRequestedAmount')}:
              </div>
              <div className="text-[24px] text-[#40A9FF] font-bold">123</div>
            </div>
            <div className="h-full flex flex-col justify-center">
              <div className="text-[16px] font-bold mb-1">
                {t('managementPools.rankingRewards.totalPaidAmount')}:
              </div>
              <div className="text-[24px] text-[#40A9FF] font-bold">
                500.000 OAS
              </div>
            </div>
            <div className="h-full flex flex-col justify-center">
              <div className="text-[16px] font-bold mb-1">
                {t('managementPools.rankingRewards.totalAmountNeeded')}:
              </div>
              <div className="text-[24px] text-[#FF4D4F] font-bold">
                1.000.000 OAS
              </div>
            </div>
          </div>
        </Col>
      </div>
      <div className="flex justify-between ml-[15%] mr-[15%] mt-4">
        <Button
          className={`!bg-[#0d2758] border__grey hover:!border-[#40A9FF] hover:!bg-[#40A9FF] hover:!text-[#fff] h-full !w-[150px] ${
            btnDetail === 'paid' &&
            '!bg-[#40A9FF] !border-[#40A9FF] !text-[#fff]'
          }`}
          onClick={() => setBtnDetail('paid')}
        >
          {t('managementPools.hashchipRedeemed.paiddetail')}
        </Button>
        <Button
          className={`!bg-[#0d2758] border__grey hover:!border-[#40A9FF] hover:!bg-[#40A9FF] hover:!text-[#fff] h-full !w-[200px] ${
            btnDetail === 'required' &&
            '!bg-[#40A9FF] !border-[#40A9FF] !text-[#fff]'
          }`}
          onClick={() => setBtnDetail('required')}
        >
          {t('managementPools.hashchipRedeemed.requiredDetail')}
        </Button>
      </div>
      <div className=" !border !border-[#40A9FF] rounded-md p-3 mt-3">
        <Row>
          <Col xl={8} md={8} sm={24} xs={24}>
            <div className="flex justify-center">Attribute</div>
          </Col>
          <Col xl={8} md={8} sm={24} xs={24}>
            <div className="flex justify-center">Total Amount</div>
          </Col>
          <Col xl={8} md={8} sm={24} xs={24}>
            <div className="flex justify-center">ratio</div>
          </Col>
        </Row>

        {renderTableRows(dataTest[btnDetail])}
      </div>
    </Modal>
  )
}
