export const REGEX = {
  PASSWORD: /^[a-zA-Z0-9]{6,14}$/,
  ADDRESS: /^[a-zA-Z0-9]+$/,
  NOT_WHITESPACE: /^(?!.*[\s])/,
  SPECIAL_CHARACTERS: /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/,
  NOT_WHITESPACE_START: /^\s*[^\s]/,
  INT_NUMBER: /^[0-9]+$/,
  PHONE_NUMBER: /^[0-9 -]+$/,
  PROFILE_NAME:
    /^(?![_\s])[a-zA-Z0-9\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}０-９\s_-]{1,15}$/u,
  PROFILE_EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
}
