import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Menu, Modal, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import RankingChart from './RankingChart'
import RankingPoolForm from '../form'
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import DepositForm from '../form/Deposit'
import WithdrawForm from '../form/Withdraw'
import { useGuild } from '~/views/app/redux/hooks/useGuild'
import { formatNumber } from '~/helpers/common'
import { useSector } from '~/views/app/redux/hooks/useSector'
import { useUser } from '~/views/app/redux/hooks/useUser'
import { useQueryState } from '~/common/hooks/useQueryState'
import { getCurrentWeekRange } from '~/common/constants'
import PageSortRankingPersonal from './PageDataPersonal'
import PageSortRankingGuild from './PageDataGuild'

function RankingPoolDetail(props) {
  const { t } = useTranslation()
  const { idCycle, isShowModal, onClose, refreshData, ranking, listPools } =
    props
  const [modalDeposit, setModalDeposit] = useState({
    id: null,
    isOpenModal: false,
  })
  const [listCheckRanking, setListCheckRanking] = useState('Sector')
  const { page } = useQueryState()
  const [keywordGuildMonth, setKeywordGuildMonth] = useState('')
  const [selectedOption, setSelectedOption] = useState('PVP')
  const [keywordUserCycle, setKeywordUserCycle] = useState('')
  const [keywordUserWeekly, setKeywordUserWeekly] = useState('')
  const range = getCurrentWeekRange()
  const [timeGuildWeek, setTimeGuildWeek] = useState({
    startTimestamp: range.startTimestamp,
    endTimestamp: range.endTimestamp,
  })
  const getCurrentMonthTimestamps = () => {
    const now = new Date()
    const startTimestamp = Math.floor(
      new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1)).getTime() /
        1000,
    )
    const endTimestamp = Math.floor(
      new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 0, 23, 59, 59),
      ).getTime() / 1000,
    )
    return { startTimestamp, endTimestamp }
  }

  const [timeGuildMonth, setTimeGuildMonth] = useState(
    getCurrentMonthTimestamps(),
  )

  const {
    actions,
    data: { listGuild, isLoading },
  } = useGuild()
  const {
    actions: actionsUseUser,
    data: { listUserByWallet, listCountSnapshot },
  } = useUser()

  const {
    actions: { getListSectors },
    data: { listSector },
  } = useSector()

  useEffect(() => {
    const params = {
      guild_name: listCheckRanking === 'GuildMonth' && keywordGuildMonth,
    }

    actions.getListGuilds(params)
  }, [keywordGuildMonth, listCheckRanking, timeGuildMonth])

  const [modalWithdraw, setModalWithdraw] = useState({
    id: null,
    isOpenModal: false,
  })

  const handleClose = () => {
    onClose()
  }
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    ranking: {},
  })
  const onShowModalForm = (id, ranking) => {
    setModalForm({
      id,
      isOpenModal: true,
      ranking: ranking,
    })
  }
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      ranking: {},
    })
  }

  const onShowModalDeposit = () => {
    setModalDeposit({
      isOpenModal: true,
    })
  }

  const onCloseModalDeposit = () => {
    setModalDeposit({
      isOpenModal: false,
    })
  }

  const onShowModalWithdraw = () => {
    setModalWithdraw({
      isOpenModal: true,
    })
  }

  const onCloseModalWithdraw = () => {
    setModalWithdraw({
      isOpenModal: false,
    })
  }

  let totalSum = Object.values(ranking?.subPools).reduce(
    (sum, item) => sum + parseFloat(item.total),
    0,
  )
  let claimedSum = Object.values(ranking?.subPools).reduce(
    (sum, item) => sum + parseFloat(item.claimed),
    0,
  )
  const PersonalItems = [
    {
      key: 'Personal',
      label:
        listCheckRanking === 'PersonalCycle'
          ? 'Personal (Cycle)'
          : listCheckRanking === 'PersonalWeekly'
          ? 'Personal (Weekly)'
          : 'Personal',
      children: [
        {
          key: 'PersonalWeekly',
          label: 'Weekly',
        },
        {
          key: 'PersonalCycle',
          label: 'Cycle',
        },
      ],
    },
  ]
  const [activeMenuKey, setActiveMenuKey] = useState('')
  const handleMenuClick = (key) => {
    setActiveMenuKey(key)
    setListCheckRanking(key)
  }
  const handleSearchMonth = (e) => {
    setKeywordGuildMonth(e.target.value)
  }
  const handleSearchUserCycle = (e) => {
    setKeywordUserCycle(e.target.value)
  }
  const handleSearchUserWeekly = (e) => {
    setKeywordUserWeekly(e.target.value)
  }

  const filterCyclePool = listPools?.map((item) => item.cycle)

  const TotalExplorationScoreMonth = (guildScore) => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()

    const filteredScores = guildScore?.filter((item) => {
      const fromDate = new Date(item.from_date * 1000)
      return (
        fromDate.getFullYear() === currentYear &&
        fromDate.getMonth() === currentMonth
      )
    })

    const filteredCycleScores = filteredScores?.filter((item) =>
      filterCyclePool?.includes(Number(item.cycle)),
    )
    const total = filteredCycleScores?.reduce(
      (acc, item) => acc + item.exploration_score,
      0,
    )
    return total?.toFixed(0) || 0
  }

  const TotalRankedScoreMonth = (guildScore) => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()

    const filteredScores = guildScore?.filter((item) => {
      const fromDate = new Date(item.from_date * 1000)
      return (
        fromDate.getFullYear() === currentYear &&
        fromDate.getMonth() === currentMonth
      )
    })
    const filteredCycleScores = filteredScores?.filter((item) =>
      filterCyclePool?.includes(Number(item.cycle)),
    )
    const total = filteredCycleScores?.reduce(
      (acc, item) => acc + item.ranked_score,
      0,
    )

    return total?.toFixed(0) || 0
  }

  const TotalRankingGuildMonth = (ExplorationScore, RankScore) => {
    const total = Number(ExplorationScore) + Number(RankScore)
    return total
  }
  const filterGuild = listGuild?.filter((item) => {
    return (
      item?.is_draft === false
      // &&
      // item?.guild_score?.some(
      //   (score) => score.from_date == timeGuildMonth.startTimestamp,
      // )
    )
  })

  const filterGuildWeek = listGuild?.filter((item) => {
    return (
      item?.is_draft === false
      // &&
      // item?.guild_score?.some(
      //   (score) =>
      //     Number(score.from_date) <= timeGuildWeek.startTimestamp &&
      //     Number(score.to_date) >= timeGuildWeek.endTimestamp,
      // )
    )
  })

  const selectedFilter =
    listCheckRanking === 'PersonalWeekly' ? filterGuildWeek : filterGuild

  const sortedDataRankingGuildMonth = selectedFilter
    ?.map((guild) => {
      const explorationScore = TotalExplorationScoreMonth(guild.guild_score)
      const rankedScore = TotalRankedScoreMonth(guild.guild_score)
      const totalScoreRankingGuildMonth = TotalRankingGuildMonth(
        explorationScore,
        rankedScore,
      )

      return {
        ...guild,
        totalScoreRankingGuildMonth: totalScoreRankingGuildMonth,
      }
    })
    .sort(
      (a, b) => b.totalScoreRankingGuildMonth - a.totalScoreRankingGuildMonth,
    )

  //xử lý phần sector

  useEffect(() => {
    const paramsSector = {
      from_date: ranking?.timeStart,
      to_date: ranking?.timeEnd,
    }
    getListSectors(paramsSector)

    actionsUseUser.getCountScore(paramsSector)
  }, [ranking])

  const TotalRankedScoreSector = (listSector) => {
    return listSector?.explore_score?.toFixed(0) || 0
    // const filteredScores = listSector?.filter(
    //   (item) => Number(item.sector_cycle_id) === idCycle,
    // )
    // const total = filteredScores?.reduce(
    //   (acc, item) => acc + item.ranked_score,
    //   0,
    // )
    // return total?.toFixed(0) || 0
  }
  const TotalExplorationScoreSector = (listSector) => {
    return listSector?.ranked_score?.toFixed(0) || 0
    // const filteredScores = listSector?.filter(
    //   (item) => Number(item.sector_cycle_id) === idCycle,
    // )
    // const total = filteredScores?.reduce(
    //   (acc, item) => acc + item.exploration_score,
    //   0,
    // )
    // return total?.toFixed(0) || 0
  }
  const TotalRankingSector = (ExplorationScore, RankScore) => {
    const total = Number(ExplorationScore) + Number(RankScore)
    return total
  }

  const sortedDataRankingSector = listSector?.data
    ?.map((data) => {
      const explorationScore = TotalExplorationScoreSector(data)
      const rankedScore = TotalRankedScoreSector(data)
      const totalScoreRankingSector = TotalRankingSector(
        explorationScore,
        rankedScore,
      )

      return {
        ...data,
        totalScoreRankingSector: totalScoreRankingSector,
      }
    })
    .sort((a, b) => b.totalScoreRankingSector - a.totalScoreRankingSector)

  const backgroundColor = (value) => {
    switch (value) {
      case 0:
        return 'rgba(12, 200, 254, 0.4)'
      case 1:
        return 'rgba(79, 252, 88, 0.4)'
      case 2:
        return 'rgba(238, 1, 9, 0.4)'
      default:
        return ''
    }
  }
  //xử lý phần personal
  const [ScoreRankingPersonal, setScoreRankingPersonal] = useState([])
  const calculateClaimsAndRewards = (data) => {
    if (!data || data.length === 0) return { claimedCount: 0, rewardCount: 0 }

    const claimedCount = data?.filter((item) => item.claim_date > 0).length
    const rewardCount = data?.filter(
      (item) => item.reward_amount !== '0',
    ).length

    return { claimedCount, rewardCount }
  }

  const { claimedCount, rewardCount } =
    calculateClaimsAndRewards(ScoreRankingPersonal)

  const ListUserOfCycle = listSector?.data
    ?.map((item) => {
      const filteredAddresses = [
        ...new Set(
          item?.sector_user?.filter(
            (address) =>
              address !== '' && address !== null && address !== undefined,
          ),
        ),
      ]
      return filteredAddresses
    })
    .flat()

  const handlePreviousWeek = () => {
    setTimeGuildWeek((prev) => {
      const startDate = new Date(prev.startTimestamp * 1000)
      const rankingStartDate = new Date(ranking?.timeStart * 1000) // Giả định ranking.timeStart đã được cung cấp

      if (startDate.getTime() <= rankingStartDate.getTime()) {
        return prev // Không thay đổi nếu trùng với ranking.timeStart
      }

      if (startDate.getUTCDate() === 1) {
        const previousMonth = new Date(startDate)
        previousMonth.setUTCMonth(previousMonth.getUTCMonth() - 1)

        const year = previousMonth.getUTCFullYear()
        const month = previousMonth.getUTCMonth()
        const lastWeekStartDate = new Date(Date.UTC(year, month, 22))
        const lastWeekEndDate = new Date(Date.UTC(year, month, 28, 23, 59, 59))

        return {
          startTimestamp: Math.floor(lastWeekStartDate.getTime() / 1000),
          endTimestamp: Math.floor(lastWeekEndDate.getTime() / 1000),
        }
      } else {
        return {
          startTimestamp: prev.startTimestamp - 7 * 24 * 60 * 60,
          endTimestamp: prev.endTimestamp - 7 * 24 * 60 * 60,
        }
      }
    })
  }

  const handleNextWeek = () => {
    setTimeGuildWeek((prev) => {
      const endDate = new Date(prev.endTimestamp * 1000)
      const currentDate = new Date()

      if (endDate.getTime() >= currentDate.getTime()) {
        return prev
      }

      if (endDate.getUTCDate() === 28) {
        const nextMonth = new Date(endDate)
        nextMonth.setUTCMonth(nextMonth.getUTCMonth() + 1)
        const year = nextMonth.getUTCFullYear()
        const month = nextMonth.getUTCMonth()
        return {
          startTimestamp: Math.floor(
            new Date(Date.UTC(year, month, 1)).getTime() / 1000,
          ),
          endTimestamp: Math.floor(
            new Date(Date.UTC(year, month, 7, 23, 59, 59)).getTime() / 1000,
          ),
        }
      } else {
        return {
          startTimestamp: prev.startTimestamp + 7 * 24 * 60 * 60,
          endTimestamp: prev.endTimestamp + 7 * 24 * 60 * 60,
        }
      }
    })
  }

  const handlePreviousMonth = () => {
    setTimeGuildMonth((prev) => {
      const currentStart = new Date(prev.startTimestamp * 1000)
      const rankingStartDate = new Date(ranking?.timeStart * 1000) // Giả định ranking.timeStart đã được cung cấp

      if (currentStart.getTime() <= rankingStartDate.getTime()) {
        return prev // Không thay đổi nếu trùng với ranking.timeStart
      }
      const previousMonth = new Date(currentStart)
      previousMonth.setUTCMonth(previousMonth.getUTCMonth() - 1)

      const startTimestamp = Math.floor(
        new Date(
          Date.UTC(
            previousMonth.getUTCFullYear(),
            previousMonth.getUTCMonth(),
            1,
          ),
        ).getTime() / 1000,
      )
      const endTimestamp = Math.floor(
        new Date(
          Date.UTC(
            previousMonth.getUTCFullYear(),
            previousMonth.getUTCMonth() + 1,
            0,
            23,
            59,
            59,
          ),
        ).getTime() / 1000,
      )

      return { startTimestamp, endTimestamp }
    })
  }

  const handleNextMonth = () => {
    setTimeGuildMonth((prev) => {
      const currentStart = new Date(prev.startTimestamp * 1000)
      const nextMonth = new Date(currentStart)
      const endDate = new Date(prev.endTimestamp * 1000)
      const currentDate = new Date()

      if (endDate.getTime() >= currentDate.getTime()) {
        return prev
      }
      nextMonth.setUTCMonth(nextMonth.getUTCMonth() + 1)

      const startTimestamp = Math.floor(
        new Date(
          Date.UTC(nextMonth.getUTCFullYear(), nextMonth.getUTCMonth(), 1),
        ).getTime() / 1000,
      )
      const endTimestamp = Math.floor(
        new Date(
          Date.UTC(
            nextMonth.getUTCFullYear(),
            nextMonth.getUTCMonth() + 1,
            0,
            23,
            59,
            59,
          ),
        ).getTime() / 1000,
      )

      return { startTimestamp, endTimestamp }
    })
  }

  const [isLoadingScore, setIsloadingScore] = useState(false)
  useEffect(() => {
    if (ListUserOfCycle && ListUserOfCycle?.length > 0) {
      const paramScoreSnapShotPersonal = {
        score_type:
          listCheckRanking === 'PersonalCycle' && selectedOption === 'PVP'
            ? 'PERSONAL_PVP'
            : listCheckRanking === 'PersonalCycle' &&
              selectedOption === 'EXPLORE'
            ? 'PERSONAL_EXPLORATION'
            : listCheckRanking === 'GuildMonth'
            ? 'GUILD'
            : listCheckRanking === 'Sector'
            ? 'SECTOR'
            : 'WEEKLY',
        from_date:
          listCheckRanking === 'PersonalCycle'
            ? ranking?.timeStart
            : listCheckRanking === 'GuildMonth'
            ? timeGuildMonth.startTimestamp
            : listCheckRanking === 'Sector'
            ? ranking.timeStart
            : timeGuildWeek.startTimestamp,
        to_date:
          listCheckRanking === 'PersonalCycle'
            ? ranking?.timeEnd
            : listCheckRanking === 'GuildMonth'
            ? timeGuildMonth.endTimestamp
            : listCheckRanking === 'Sector'
            ? ranking.timeEnd
            : timeGuildWeek.endTimestamp,
      }
      setIsloadingScore(true)
      actionsUseUser.getScoreSnapShotAddress(
        paramScoreSnapShotPersonal,
        (res) => {
          if (res) {
            setTimeout(() => {
              setScoreRankingPersonal(res?.data?.score_snapshot)
              setIsloadingScore(false)
            }, 1000)
          }
        },
      )
    }
  }, [listCheckRanking, ranking, timeGuildWeek, selectedOption])

  useEffect(() => {
    if (ListUserOfCycle && ListUserOfCycle?.length > 0) {
      const params = {
        search:
          listCheckRanking === 'PersonalCycle'
            ? keywordUserCycle
            : keywordUserWeekly,
        allWallets: ListUserOfCycle,
        pageSize: 1000000000,
        page,
      }
      actionsUseUser.getUserByWallet(params)
    }
  }, [listSector, listCheckRanking, keywordUserCycle, keywordUserWeekly])

  const findScore = (wallet, data, type) => {
    const record = data.find((item) => item.user === wallet)

    if (!record) return null
    if (type === 'explore_score') {
      return record.explore_score
    } else if (type === 'ranked_score') {
      return record.ranked_score
    } else if (type === 'score') {
      return record.score
    } else {
      return null
    }
  }

  const mergedDataListUser = listUserByWallet?.map((player) => {
    const userWallet = player.address_wallet
    const explorationScore = findScore(
      userWallet,
      ScoreRankingPersonal,
      'explore_score',
    )
    const rankedMatchScore = findScore(
      userWallet,
      ScoreRankingPersonal,
      'ranked_score',
    )
    const ScoreTotal = findScore(userWallet, ScoreRankingPersonal, 'score')

    // const total = Number(explorationScore || 0) + Number(rankedMatchScore || 0)
    //xử lý sector
    const filteredDataListSector = listSector?.data
      ?.map((sector) => ({
        ...sector,
        sector_cycle: sector?.sector_user?.filter((cycle) =>
          cycle?.includes(player?.address_wallet),
        ),
      }))
      .find((sector) => sector?.sector_cycle?.length > 0)

    //xử lý guild
    const dataListGuildUser = filterGuild
      ?.map((guild, index) => ({
        ...guild,
        guild_member: guild?.guild_member?.filter((data) =>
          data.user_wallet.includes(player?.address_wallet),
        ),
        TopGuildRank: index + 1,
      }))
      .find((guild) => guild?.guild_member?.length > 0)

    return {
      ...player,
      explorationScorePersonal: explorationScore ? explorationScore : 0,
      rankedMatchScorePersonal: rankedMatchScore ? rankedMatchScore : 0,
      totalPersonal: ScoreTotal,
      filteredDataListSector: filteredDataListSector,
      listGuildUser: dataListGuildUser ? dataListGuildUser : null,
    }
  })

  const handleSelect = (option) => {
    setSelectedOption(option)
  }
  const getRankingGuild = (guildScore, idCycle) => {
    if (!guildScore) return null
    const data = guildScore.find((item) => Number(item.cycle) === idCycle)
    return data
  }

  return (
    <>
      <Modal
        title={'Ranking Detail'}
        onCancel={handleClose}
        open={isShowModal}
        width={1100}
        centered
        destroyOnClose
        footer={null}
      >
        <div>
          <Row>
            <Col span={8}>
              <div>
                <RankingChart dataRanking={ranking?.subPools} />
              </div>
              <div className="mt-[15%]">
                <div className="flex gap-2 justify-center items-center">
                  <Button onClick={() => onShowModalDeposit()}>Deposit</Button>
                  <Button onClick={() => onShowModalWithdraw()}>
                    Withdraw
                  </Button>
                  <Button
                    onClick={() => onShowModalForm(ranking?.cycle, ranking)}
                  >
                    <SettingOutlined />
                  </Button>
                </div>
              </div>
              <div className="flex justify-center text-[24px] mt-3 mb-3">
                Reward Status
              </div>
              <div
                style={{
                  border: '2px solid #fff',
                  padding: '10px',
                  marginBottom: '12px',
                  borderRadius: '10px',
                }}
              >
                <div className="flex justify-center text-[20px]">
                  Claimed in{' '}
                  {listCheckRanking === 'PersonalWeekly'
                    ? 'Personal Weekly'
                    : listCheckRanking === 'PersonalWeekly'
                    ? 'Personal Weekly'
                    : listCheckRanking === 'GuildMonth'
                    ? 'Guild Month'
                    : listCheckRanking === 'Sector'
                    ? 'Sector'
                    : 'Personal Cycle'}
                </div>
                {listCheckRanking === 'PersonalWeekly' && (
                  <>
                    <div className="flex justify-center text-[16px] ">
                      {ranking?.subPools?.weekly?.claimed}/
                      {ranking?.subPools?.weekly?.total} OAS
                    </div>
                    <div className="flex justify-center text-[16px] ">
                      {claimedCount}/{rewardCount} Players
                    </div>
                  </>
                )}
                {listCheckRanking === 'GuildMonth' && (
                  <>
                    <div className="flex justify-center text-[16px] ">
                      {ranking?.subPools?.guild?.claimed}/
                      {ranking?.subPools?.guild?.total} OAS
                    </div>
                    <div className="flex justify-center text-[16px] ">
                      {claimedCount}/{rewardCount} Players
                    </div>
                  </>
                )}
                {listCheckRanking === 'Sector' && (
                  <>
                    <div className="flex justify-center text-[16px] ">
                      {ranking?.subPools?.sector?.claimed}/
                      {ranking?.subPools?.sector?.total} OAS
                    </div>
                    <div className="flex justify-center text-[16px] ">
                      {claimedCount}/{rewardCount} Players
                    </div>
                  </>
                )}
                {listCheckRanking === 'PersonalCycle' &&
                  selectedOption === 'PVP' && (
                    <>
                      <div className="flex justify-center text-[16px] ">
                        {ranking?.subPools?.personalPvP?.claimed}/
                        {ranking?.subPools?.personalPvP?.total} OAS
                      </div>
                      <div className="flex justify-center text-[16px] ">
                        {claimedCount}/{rewardCount} Players
                      </div>
                    </>
                  )}
                {listCheckRanking === 'PersonalCycle' &&
                  selectedOption === 'EXPLORE' && (
                    <>
                      <div className="flex justify-center text-[16px] ">
                        {ranking?.subPools?.personalExploraion?.claimed}/
                        {ranking?.subPools?.personalExploraion?.total} OAS
                      </div>
                      <div className="flex justify-center text-[16px] ">
                        {claimedCount}/{rewardCount} Players
                      </div>
                    </>
                  )}
              </div>
              <div
                style={{
                  border: '2px solid #fff',
                  padding: '10px',
                  borderRadius: '10px',
                }}
              >
                <div className="flex justify-center text-[20px]">
                  Claimed in Total
                </div>
                <div className="flex justify-center text-[16px]">
                  {claimedSum?.toFixed(1)}/{totalSum?.toFixed(1)} OAS
                </div>
                <div className="flex justify-center text-[16px]">
                  {listCountSnapshot?.total_reward_claimed}/
                  {listCountSnapshot?.total_reward_available} Players
                </div>
              </div>
            </Col>
            <Col span={1}></Col>
            <Col span={15}>
              <div
                className={`flex items-center mb-2 ${
                  listCheckRanking === 'PersonalCycle'
                    ? ' justify-between  ml-[15%]'
                    : listCheckRanking === 'Sector'
                    ? ' justify-center'
                    : 'flex-col'
                }`}
                style={{ fontFamily: 'Rexliafree' }}
              >
                <div></div>
                <div className="flex items-center">
                  <img
                    className="w-[25px] h-[25px] mr-2 mt-[2px]"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                    alt=""
                  />
                  <div className="text-[25px]">Ranking</div>
                </div>
                {listCheckRanking === 'PersonalCycle' && (
                  <div className="flex items-center space-x-4 mt-2 mr-[50px]">
                    {/* PVP Button */}
                    <div
                      onClick={() => handleSelect('PVP')}
                      style={{
                        cursor: 'pointer',
                        border: ' 2px solid #1677ff',
                        padding: '5px',
                        borderRadius: '5px',
                        background: selectedOption === 'PVP' ? '#1677ff' : '',
                      }}
                    >
                      <img
                        className="w-[25px] h-[25px]"
                        src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                        alt=""
                      />
                    </div>
                    {/* EXPLORE Button */}
                    <div
                      onClick={() => handleSelect('EXPLORE')}
                      style={{
                        cursor: 'pointer',
                        border: ' 2px solid #1677ff',
                        padding: '5px',
                        borderRadius: '5px',
                        background:
                          selectedOption === 'EXPLORE' ? '#1677ff' : '',
                      }}
                    >
                      <img
                        className="w-[25px] h-[25px]"
                        src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                        alt=""
                      />
                    </div>
                  </div>
                )}

                {listCheckRanking === 'PersonalWeekly' && (
                  <div className="flex items-center space-x-4 ">
                    <CaretLeftOutlined
                      style={{
                        fontSize: '25px',
                        cursor:
                          timeGuildWeek.startTimestamp !== ranking?.timeStart
                            ? 'pointer'
                            : 'not-allowed',
                        color:
                          timeGuildWeek.startTimestamp !== ranking?.timeStart
                            ? 'inherit'
                            : 'black',
                      }}
                      onClick={handlePreviousWeek}
                    />

                    <div
                      style={{
                        fontSize: '18px',
                        whiteSpace: 'nowrap',
                        flexShrink: 0,
                      }}
                    >
                      <div
                        style={{
                          fontSize: '18px',
                          whiteSpace: 'nowrap',
                          flexShrink: 0,
                        }}
                      >
                        {new Date(
                          timeGuildWeek.startTimestamp * 1000,
                        ).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                          timeZone: 'UTC',
                        })}{' '}
                        -{' '}
                        {new Date(
                          timeGuildWeek.endTimestamp * 1000,
                        ).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                          timeZone: 'UTC',
                        })}
                      </div>
                    </div>
                    <CaretRightOutlined
                      style={{
                        fontSize: '25px',
                        cursor:
                          new Date(timeGuildWeek.endTimestamp * 1000) <=
                          new Date()
                            ? 'pointer'
                            : 'not-allowed',
                        color:
                          new Date(timeGuildWeek.endTimestamp * 1000) <=
                          new Date()
                            ? 'inherit'
                            : 'black',
                      }}
                      onClick={handleNextWeek}
                    />
                  </div>
                )}
                {listCheckRanking === 'GuildMonth' && (
                  <div className="flex items-center space-x-4 ">
                    <CaretLeftOutlined
                      style={{
                        fontSize: '25px',
                        cursor:
                          timeGuildMonth.startTimestamp !== ranking?.timeStart
                            ? 'pointer'
                            : 'not-allowed',
                        color:
                          timeGuildMonth.startTimestamp !== ranking?.timeStart
                            ? 'inherit'
                            : 'black',
                      }}
                      onClick={handlePreviousMonth}
                    />

                    <div
                      style={{
                        fontSize: '18px',
                        whiteSpace: 'nowrap',
                        flexShrink: 0,
                      }}
                    >
                      <div
                        style={{
                          fontSize: '18px',
                          whiteSpace: 'nowrap',
                          flexShrink: 0,
                        }}
                      >
                        {`Month ${
                          new Date(
                            timeGuildMonth.startTimestamp * 1000,
                          ).getUTCMonth() + 1
                        } (`}
                        {`${new Date(
                          timeGuildMonth.startTimestamp * 1000,
                        ).toLocaleDateString('en-US', {
                          month: 'short',
                          timeZone: 'UTC',
                        })}.${new Date(
                          timeGuildMonth.startTimestamp * 1000,
                        ).getUTCFullYear()}`}
                        {')'}
                      </div>
                    </div>
                    <CaretRightOutlined
                      style={{
                        fontSize: '25px',
                        cursor:
                          new Date(timeGuildMonth.endTimestamp * 1000) <=
                          new Date()
                            ? 'pointer'
                            : 'not-allowed',
                        color:
                          new Date(timeGuildMonth.endTimestamp * 1000) <=
                          new Date()
                            ? 'inherit'
                            : 'black',
                      }}
                      onClick={handleNextMonth}
                    />
                  </div>
                )}
              </div>

              {listCheckRanking === 'PersonalWeekly' && (
                <PageSortRankingPersonal
                  backgroundColor={backgroundColor}
                  mergedDataListUser={mergedDataListUser
                    .sort((a, b) => b.totalPersonal - a.totalPersonal)
                    ?.filter((item) => item.totalPersonal)}
                  getRankingGuild={getRankingGuild}
                  idCycle={idCycle}
                  handleSearchUser={handleSearchUserWeekly}
                  keywordUser={keywordUserWeekly}
                  isLoadingScore={isLoadingScore}
                  sortRankingGuild={sortedDataRankingGuildMonth}
                />
              )}
              {listCheckRanking === 'PersonalCycle' && (
                <PageSortRankingPersonal
                  backgroundColor={backgroundColor}
                  mergedDataListUser={
                    selectedOption === 'PVP'
                      ? mergedDataListUser
                          .sort(
                            (a, b) =>
                              b.rankedMatchScorePersonal -
                              a.rankedMatchScorePersonal,
                          )
                          ?.filter(
                            (item) =>
                              item.rankedMatchScorePersonal ||
                              item.explorationScorePersonal,
                          )
                      : mergedDataListUser
                          .sort(
                            (a, b) =>
                              b.explorationScorePersonal -
                              a.explorationScorePersonal,
                          )
                          ?.filter(
                            (item) =>
                              item.explorationScorePersonal ||
                              item.rankedMatchScorePersonal,
                          )
                  }
                  getRankingGuild={getRankingGuild}
                  idCycle={idCycle}
                  handleSearchUser={handleSearchUserCycle}
                  keywordUser={keywordUserCycle}
                  isLoadingScore={isLoadingScore}
                  sortRankingGuild={sortedDataRankingGuildMonth}
                />
              )}
              {listCheckRanking === 'GuildMonth' && (
                <PageSortRankingGuild
                  timeGuildMonth={timeGuildMonth}
                  sortRankingGuild={sortedDataRankingGuildMonth}
                  handleSearchGuild={handleSearchMonth}
                  keywordGuild={keywordGuildMonth}
                  isLoading={isLoading}
                  TotalRankingGuild={TotalRankingGuildMonth}
                  TotalRankedScore={TotalRankedScoreMonth}
                  TotalExplorationScore={TotalExplorationScoreMonth}
                />
              )}
              {listCheckRanking === 'Sector' && (
                <div style={{ fontFamily: 'Rexliafree' }}>
                  <Spin spinning={isLoading}>
                    {sortedDataRankingSector?.map((item, index) => (
                      <div
                        className="guildInfo"
                        style={{
                          maxHeight: '567px',
                          overflowY: 'auto',
                          padding: '0 20px',
                        }}
                      >
                        <div
                          className="mb-2"
                          style={{
                            border: '2px solid rgba(12, 200, 254, 1)',
                            padding: '10px',
                            background: backgroundColor(item?.sector),
                            borderRadius: '20px',
                            position: 'relative',
                          }}
                        >
                          <img
                            className="absolute left-0 top-0 w-full h-full "
                            src={`${process.env.PUBLIC_URL}/svgs/guild/logoSector/${item?.sector}.svg`}
                            alt=""
                          />
                          <Row>
                            <Col span={17}>
                              <Row>
                                <Col span={5} className="flex  items-center">
                                  <img
                                    className="w-[55px] h-[55px] mr-2 "
                                    src={`${process.env.PUBLIC_URL}/svgs/guild/sector/${item?.sector}.svg`}
                                    alt=""
                                  />
                                </Col>
                                <Col span={14} className="flex items-center  ">
                                  <div className="text-[27px]">
                                    {item.sector_name}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              span={7}
                              className=" flex items-center justify-center"
                              style={{
                                border: '1px solid rgba(0, 0, 0, 0.8)',
                                background: 'rgba(0, 0, 0, 0.8)',
                                borderRadius: '10px',
                                height: '55px',
                              }}
                            >
                              <img
                                className="w-[25x] h-[25px] mr-2"
                                src={`${process.env.PUBLIC_URL}/svgs/guild/${
                                  index + 1
                                }.svg`}
                                alt=""
                              />
                              <div className="flex items-center ">
                                <div className="text-[45px] mb-[12px] ">
                                  {index + 1}
                                </div>
                                <div className="text-[25px]">
                                  {index + 1 === 1
                                    ? 'st'
                                    : index + 1 === 2
                                    ? 'nd'
                                    : 'rd'}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={17} className="text-[25px]">
                              Sector Score
                            </Col>
                            <Col span={7} className="text-[25px] text-end">
                              {formatNumber(
                                TotalRankingSector(
                                  TotalRankedScoreSector(item),
                                  TotalExplorationScoreSector(item),
                                ),
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={17} className="text-[20px] flex">
                              <img
                                className="w-[20px] h-[20px] mt-[2px]"
                                src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                                alt=""
                              />
                              <div className="ml-2">Ranked Match</div>
                            </Col>
                            <Col span={7} className="text-[20px] text-end">
                              {formatNumber(TotalRankedScoreSector(item))}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={17} className="text-[20px] flex">
                              <img
                                className="w-[20px] h-[20px] mt-[2px]"
                                src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                                alt=""
                              />
                              <div className="ml-2">Exploration</div>
                            </Col>
                            <Col span={7} className="text-[20px] text-end">
                              {formatNumber(TotalExplorationScoreSector(item))}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                  </Spin>
                </div>
              )}
              <div>
                <Row className="mt-8">
                  <Col span={5}></Col>
                  <Col
                    span={14}
                    className="flex gap-6 justify-center items-start "
                  >
                    <Menu
                      onClick={(e) => handleMenuClick(e.key)}
                      selectedKeys={[activeMenuKey]}
                      style={{
                        fontFamily: 'Rexliafree',
                        fontWeight: '400',
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '40px',
                        backgroundColor: 'rgba(0, 0, 0, 0.45)',
                        border: '1px solid rgb(230, 235, 241)',
                        borderRadius: '0.625rem',
                      }}
                      mode="vertical"
                      items={PersonalItems}
                    />
                    <Button
                      style={{
                        color: listCheckRanking === 'GuildMonth' && '#1677ff',
                      }}
                      onClick={() => {
                        setActiveMenuKey('GuildMonth')
                        setListCheckRanking('GuildMonth')
                      }}
                    >
                      Guild (Monthly)
                    </Button>
                    <Button
                      style={{
                        color: listCheckRanking === 'Sector' && '#1677ff',
                      }}
                      onClick={() => {
                        setActiveMenuKey('Sector')
                        setListCheckRanking('Sector')
                      }}
                    >
                      Sector (Cycle)
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        {modalForm.isOpenModal && (
          <RankingPoolForm
            isShowModal={modalForm.isOpenModal}
            onClose={onCloseModalForm}
            refreshData={refreshData}
            id={modalForm.id}
            ranking={modalForm?.ranking}
          />
        )}
      </Modal>

      {modalDeposit.isOpenModal && (
        <DepositForm
          isShowModal={modalDeposit.isOpenModal}
          onClose={onCloseModalDeposit}
          // refreshData={refreshData}
          // id={modalForm.id}
          // ranking={modalForm?.ranking}
        />
      )}

      {modalWithdraw.isOpenModal && (
        <WithdrawForm
          isShowModal={modalWithdraw.isOpenModal}
          onClose={onCloseModalWithdraw}
          // refreshData={refreshData}
          // id={modalForm.id}
          // ranking={modalForm?.ranking}
        />
      )}
    </>
  )
}

RankingPoolDetail.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default RankingPoolDetail
