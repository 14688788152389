import { Button, Checkbox, Col, DatePicker, Popover, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import { useHistoryTrade } from '../../redux/hooks/useHistoryTrade'
import { fromWei, getAssetType } from '~/blockchain/provider'
import { assetTypeData, eventData } from './data'
import configs from '~/configs'
import {
  formatAddress,
  formatNumber,
  formatTransaction,
} from '~/helpers/common'
import { DownOutlined } from '@ant-design/icons'
import {
  ACTIVE_ALL_ASSET_TYPE,
  ACTIVE_ALL_EVENT,
  DEFAULT_ACTIVE_ASSET_TYPE,
  handleExportCSVScreen,
} from '~/common/constants'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
const { RangePicker } = DatePicker
const CheckboxGroup = Checkbox.Group

function HistoryTrade() {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const userTimezone = dayjs.tz.guess() // Lấy múi giờ local của user
  const userUtcOffset = dayjs().tz(userTimezone).utcOffset() / 60 // Lấy offset theo giờ
  const { t } = useTranslation()
  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()
  const [date, setDate] = useState(['', ''])
  const [checkedList, setCheckedList] = useState(DEFAULT_ACTIVE_ASSET_TYPE)
  const checkAll = ACTIVE_ALL_ASSET_TYPE.length === checkedList.length
  const indeterminate =
    checkedList.length > 0 && checkedList.length < ACTIVE_ALL_ASSET_TYPE.length

  const [checkedListEvent, setCheckedListEvent] = useState(
    DEFAULT_ACTIVE_ASSET_TYPE,
  )
  const checkAllEvent = ACTIVE_ALL_EVENT.length === checkedListEvent.length
  const indeterminateEvent =
    checkAllEvent.length > 0 &&
    checkAllEvent.length < ACTIVE_ALL_ASSET_TYPE.length

  const {
    actions,
    data: { allHistory, total, isLoading },
  } = useHistoryTrade()

  const columns = [
    {
      title: t('historyTrade.form.event'),
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: 100,
      render: (type) => {
        return type === 'OrderCreated'
          ? 'For Sale'
          : type === 'OrderSuccessful'
          ? 'Buy'
          : undefined
      },
    },

    {
      title: t('historyTrade.form.assetType'),
      dataIndex: 'asset_address',
      key: 'asset_address',
      align: 'center',
      render: (asset_address) => {
        return getAssetType(asset_address)
      },
    },
    {
      title: t('historyTrade.form.tokenId'),
      dataIndex: 'tokenId',
      align: 'center',
      key: 'tokenId',
      render: (_, data) => {
        let link = `${configs.NETWORK.scan}token/${data?.asset_address}/instance/${data?.tokenId}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            #{data?.tokenId}
          </a>
        )
      },
    },
    {
      // title: t('historyTrade.form.time'),
      title: (
        <>
          {t('historyTrade.form.time')} (UTC{' '}
          {userUtcOffset >= 0 ? `+${userUtcOffset}` : userUtcOffset})
        </>
      ),
      dataIndex: 'timestamp',
      key: 'timestamp',
      align: 'center',
      // render: (timestamp) => {
      //   return moment(timestamp * 1000)
      //     .tz('Asia/Tokyo')
      //     .format(DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2)
      // },
      render: (data) => {
        // return moment(data * 1000)
        //   .utc()
        //   .format(DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2)
        return dayjs(data * 1000).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: t('historyTrade.form.from'),
      dataIndex: 'from',
      key: 'from',
      align: 'center',
      render: (from) => {
        let link = `${configs.NETWORK.scan}address/${from}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={from}
            style={{ color: '#40A9FF' }}
          >
            {formatAddress(from)}
          </a>
        )
      },
    },
    {
      title: t('historyTrade.form.to'),
      dataIndex: 'to',
      key: 'to',
      align: 'center',
      render: (to) => {
        let link = `${configs.NETWORK.scan}address/${to}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={to}
            style={{ color: '#40A9FF' }}
          >
            {formatAddress(to)}
          </a>
        )
      },
    },
    {
      title: t('historyTrade.form.quantity'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      render: (amount) => {
        return amount
      },
    },
    {
      title: t('historyTrade.form.price'),
      dataIndex: 'priceInWei',
      align: 'center',
      key: 'priceInWei',
      render: (priceInWei) => {
        return (
          <div>
            <Row gutter={4}>
              <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                {formatNumber(fromWei(priceInWei))}
              </Col>
              <Col
                span={12}
                style={{ display: 'flex', justifyContent: 'start' }}
              >
                OAS
              </Col>
            </Row>
          </div>
        )
      },
    },
    {
      title: t('historyTrade.form.txHash'),
      dataIndex: 'transactionHash',
      align: 'center',
      key: 'transactionHash',
      render: (transactionHash) => {
        const link = `${configs.NETWORK.scan}tx/${transactionHash}`
        return (
          <a
            style={{ color: '#40A9FF' }}
            href={link}
            title={transactionHash}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatTransaction(transactionHash)}
          </a>
        )
      },
    },
  ]

  //Refresh data
  const params = {
    search: keyword,
    pageSize: pageSize,
    event: checkedListEvent,
    addressContract: checkedList,
    page,
  }

  if (date[0] !== '') {
    let temp = date[0].split('-')
    let temp1 = date[1].split('-')
    params.fromTime = params.fromTime =
      Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0) / 1000

    params.toTime =
      Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59) / 1000
  }
  const refreshData = () => {
    console.log(123, params)

    actions.getHistoryTrade(params)
  }

  useEffect(() => {
    refreshData()
  }, [date, checkedListEvent, checkedList, page, pageSize, keyword])

  const onChangeDate = (values) => {
    setDate(values)
  }

  const onChange = (list) => {
    setCheckedList(list)
  }
  const onChangeEv = (list) => {
    setCheckedListEvent(list)
  }
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? ACTIVE_ALL_ASSET_TYPE : [])
  }

  const onCheckAllChangeEv = (e) => {
    setCheckedListEvent(e.target.checked ? ACTIVE_ALL_EVENT : [])
  }
  return (
    <div>
      <div className="flex justify-end gap-3 mb-5 box__filter">
        <Button
          style={{ backgroundColor: '#1890FF' }}
          onClick={() =>
            handleExportCSVScreen('HISTORY_TRADE', params, 'HISTORY_TRADE')
          }
        >
          Export to CSV
        </Button>

        <div className="flex items-center">
          <div className="mr-2 font__Rexliafree">
            {t('historyTrade.form.event')}
          </div>

          <Popover
            colorBgElevated="#000"
            content={
              <div className="w-[125px]">
                <Checkbox
                  indeterminate={indeterminateEvent}
                  onChange={onCheckAllChangeEv}
                  checked={checkAllEvent}
                >
                  All
                </Checkbox>
                <CheckboxGroup
                  options={eventData}
                  value={checkedListEvent}
                  onChange={onChangeEv}
                />
              </div>
            }
            className="w-full"
            trigger="click"
            placement="bottom"
            arrow={false}
          >
            <Button className="!bg-[#0d2758] border__grey hover:!border-[#40A9FF] h-full !w-[150px]">
              <div className="w-full flex text-start justify-between items-center">
                <div className="max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis">
                  {checkAll
                    ? 'All'
                    : checkedListEvent?.map((data) => t(data))?.join()}
                </div>
                <DownOutlined
                  className="absolute right-[15px]"
                  style={{ fontSize: 10 }}
                />
              </div>
            </Button>
          </Popover>
        </div>

        <div className="flex items-center">
          <div className="mr-2 font__Rexliafree">
            {t('historyTrade.form.assetType')}
          </div>

          <Popover
            colorBgElevated="#000"
            content={
              <div className="w-[125px]">
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  All
                </Checkbox>
                <CheckboxGroup
                  options={assetTypeData}
                  value={checkedList}
                  onChange={onChange}
                />
              </div>
            }
            className="w-full"
            trigger="click"
            placement="bottom"
            arrow={false}
          >
            <Button className="!bg-[#0d2758] border__grey hover:!border-[#40A9FF] h-full !w-[150px]">
              <div className="w-full flex text-start justify-between items-center">
                <div className="max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis">
                  {checkAll
                    ? 'All'
                    : checkedList?.map((data) => getAssetType(data))?.join()}
                </div>
                <DownOutlined
                  className="absolute right-[15px]"
                  style={{ fontSize: 10 }}
                />
              </div>
            </Button>
          </Popover>
        </div>

        <div className="flex items-center">
          <div className="mr-2 font__Rexliafree">
            {t('historyTrade.form.date')}
          </div>
          <RangePicker
            onChange={(values, dateString) => onChangeDate(dateString)}
            className="h-full max-w-[256px]"
          />
        </div>
      </div>

      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={allHistory}
        showPagination
        showHeader
        loading={isLoading}
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: total,
          keyword,
          setKeyword,
        }}
      />
    </div>
  )
}

export default HistoryTrade
