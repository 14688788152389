import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  deleteNotificationSuccess,
  deleteNotificationFailed,
  DELETE_NOTIFICATION_START,
} from '~/views/app/redux/actions/notification'

/**
 * delete user api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const deleteNotificationApi = (props) => {
  const uri = `/notifications/${props?.id}`
  return api.delete(uri)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */

function* doDeleteNotification(action) {
  try {
    const response = yield call(deleteNotificationApi, action?.payload)
    yield put(deleteNotificationSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(deleteNotificationFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch delete notification request
 */
export default function* watchDeleteNotification() {
  yield takeLatest(DELETE_NOTIFICATION_START, doDeleteNotification)
}
