import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Empty, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { UserSwitchOutlined } from '@ant-design/icons'
import SectorDetail from '../../../Sector/modalDetail'
import { useSector } from '~/views/app/redux/hooks/useSector'
function UserRow({ item, index, totalItems, onShowModalDetail, idCycle }) {
  const filteredAddresses = [
    ...new Set(
      item?.sector_user?.filter(
        (address) => address !== '' && address !== null,
      ),
    ),
  ]

  return (
    <Row
      key={index}
      style={{
        background: '#0D2758',
        padding: '16px',
        borderBottom:
          index !== totalItems - 1
            ? '1px solid rgb(36, 169, 250, 0.3)'
            : 'none',
        borderRadius: index === totalItems - 1 ? '0 0 8px 8px' : '0',
      }}
    >
      <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
        {item?.sector_name}
      </Col>
      <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
        {filteredAddresses?.length || 0}
      </Col>
      <Col span={4}>
        <Button
          disabled={(filteredAddresses?.length || 0) === 0}
          type="ghost"
          loading={false}
          onClick={() =>
            onShowModalDetail(item?._id, item, filteredAddresses, idCycle)
          }
          icon={<UserSwitchOutlined style={{ color: '#1890FF' }} />}
        />
      </Col>
    </Row>
  )
}
function SectorInfor(props) {
  const { t } = useTranslation()
  const { isShowModal, onClose, timeStartPool, sectorInfor } = props

  const {
    actions,
    data: { listSector },
  } = useSector()

  useEffect(() => {
    fetchData()
  }, [timeStartPool])

  const fetchData = () => {
    actions.getListSectors({
      from_date: sectorInfor?.timeStart,
      to_date: sectorInfor?.timeEnd,
    })
  }

  const refreshData = fetchData

  const [modalDetail, setModalDeail] = useState({
    id: null,
    isOpenModal: false,
    sector: {},
    sectorMembersCount: {},
    idCycle: null,
  })

  const onShowModalDetail = (id, sector, sectorMembersCount, idCycle) => {
    setModalDeail({
      id,
      isOpenModal: true,
      sector: sector,
      sectorMembersCount: sectorMembersCount,
      idCycle: idCycle,
    })
  }
  const onCloseModalDetail = () => {
    setModalDeail({
      id: null,
      isOpenModal: false,
      sector: {},
      sectorMembersCount: {},
      idCycle: null,
    })
  }

  return (
    <div>
      <Modal
        title={t('sector.sectorInfo')}
        onCancel={onClose}
        open={isShowModal}
        width={800}
        centered
        destroyOnClose
        footer={null}
      >
        <div className="ContainerGiftDetail">
          <Row
            style={{
              fontFamily: 'Rexliafree',
              padding: '16px',
            }}
          >
            <Col span={12} style={{ fontSize: '16px' }}>
              {t('sector.sectorName')}
            </Col>
            <Col span={8} style={{ fontSize: '16px' }}>
              {t('sector.totalUser')}
            </Col>
            <Col span={4} style={{ fontSize: '16px' }}>
              {t('common.action')}
            </Col>
          </Row>
          {listSector?.data?.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          {listSector?.data?.map((item, index) => (
            <UserRow
              key={index}
              item={item}
              index={index}
              idCycle={sectorInfor?.cycle}
              totalItems={listSector?.data?.length}
              // handChange={handChange}
              // dataSector={fakeDataSector.data}
              // dataUserSector={dataSector}
              onShowModalDetail={onShowModalDetail}
            />
          ))}
        </div>
      </Modal>
      {modalDetail.isOpenModal && (
        <SectorDetail
          isShowModal={modalDetail.isOpenModal}
          onClose={onCloseModalDetail}
          refreshData={refreshData}
          id={modalDetail.id}
          dataSector={modalDetail?.sector}
          sectorMembersCountInfo={modalDetail?.sectorMembersCount}
          listSector={listSector?.data}
          idCycle={modalDetail?.idCycle}
        />
      )}
    </div>
  )
}

export default SectorInfor
