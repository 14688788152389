import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Select,
  Spin,
  Tooltip,
} from 'antd'
import React, { useRef, useState, useEffect } from 'react'
import { formatAddress } from '~/helpers/common'
import configs from '~/configs'
import moment from 'moment'
import {
  converUserRankUser,
  DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2,
  NOTIFICATION_DURATION,
} from '~/common/constants'
import { useQueryState } from '~/common/hooks/useQueryState'
import { useUser } from '~/views/app/redux/hooks/useUser'
import { useSector } from '~/views/app/redux/hooks/useSector'

function UserRow({
  item,
  indexAddress,
  totalItems,
  handChange,
  isLoading,
  dataSector,
  listSector,
  sectorMembersCount,
}) {
  const nameRef = useRef(null)
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    if (nameRef.current) {
      setIsTruncated(nameRef.current.scrollWidth > nameRef.current.clientWidth)
    }
  }, [item?.user_name])

  return (
    <Spin spinning={isLoading}>
      <Row
        key={indexAddress}
        style={{
          background: '#0D2758',
          padding: '16px',
          borderBottom:
            indexAddress !== totalItems - 1
              ? '1px solid rgb(36, 169, 250, 0.3)'
              : 'none',
          borderRadius: indexAddress === totalItems - 1 ? '0 0 8px 8px' : '0',
        }}
      >
        <Tooltip title={isTruncated ? item?.user_name : ''}>
          <Col
            span={5}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              alignContent: 'center',
            }}
            className={isTruncated ? '' : 'flex items-center justify-center'}
            ref={nameRef}
          >
            {item?.user_name}
          </Col>
        </Tooltip>

        <Col span={5} className="flex items-center justify-center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${configs.NETWORK.scan}address/${item?.address_wallet}`}
            style={{ color: '#40A9FF' }}
          >
            {formatAddress(item?.address_wallet)}
          </a>
        </Col>
        <Col span={4} className="flex items-center justify-center">
          {item?.player_rank ? converUserRankUser(item?.player_rank) : 'ー'}
        </Col>

        <Col span={4} className="flex items-center justify-center">
          {moment(item?.crt_dt).utc().format(DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2)}
        </Col>

        <Col
          span={6}
          className="flex items-center justify-center text-[#40A9FF] text-[20px] cursor-pointer"
        >
          <Select
            defaultValue={dataSector?._id}
            className="w-[90%]"
            onChange={(value, option) =>
              handChange(
                value,
                option,
                dataSector,
                sectorMembersCount,
                indexAddress,
              )
            }
            options={listSector?.map((data, i) => ({
              key: i?.toString(),
              value: data?._id,
              label: data?.sector_name,
              from_date: data?.from_date,
              to_date: data?.to_date,
              sector_cycle_id: data?.sector_cycle_id,
            }))}
          />
        </Col>
      </Row>
    </Spin>
  )
}

function SectorDetail(props) {
  const { t } = useTranslation()
  const {
    isShowModal,
    onClose,
    dataSector,
    sectorMembersCountInfo,
    listSector,
    idCycle,
    refreshData,
  } = props
  const { page, pageSize, setPage, setPageSize, keyword, setKeyword } =
    useQueryState()
  const [checkChangeSector, setCheckChangeSector] = useState(true)
  const handleClose = () => {
    onClose()
  }
  const [listSortUserByWallet, setListSortUserByWallet] = useState([])

  const { actions: actionsSector } = useSector()

  const [changeSector, setChangeSector] = useState({})

  const [formSearch] = Form.useForm()
  const [updatedSectorMembersSector0, setUpdatedSectorMembersSector0] =
    useState([])

  const [updatedSectorMembersSector1, setUpdatedSectorMembersSector1] =
    useState([])

  const filterSector = listSector?.filter((item) => item._id !== dataSector._id)
  useEffect(() => {
    if (
      updatedSectorMembersSector0?.length > 0 ||
      updatedSectorMembersSector1?.length > 0
    ) {
      setCheckChangeSector(false)
    } else {
      setCheckChangeSector(true)
    }

    setChangeSector({
      data: [
        {
          _id: filterSector[0]?._id,
          sector_cycle_id: filterSector[0]?.sector_cycle_id,
          from_date: filterSector[0]?.from_date,
          to_date: filterSector[0]?.to_date,
          sector_users: updatedSectorMembersSector0,
        },
        {
          _id: filterSector[1]?._id,
          sector_cycle_id: filterSector[1]?.sector_cycle_id,
          from_date: filterSector[1]?.from_date,
          to_date: filterSector[1]?.to_date,
          sector_users: updatedSectorMembersSector1,
        },
      ],
    })
  }, [updatedSectorMembersSector0, updatedSectorMembersSector1])

  const handChange = (
    newValue,
    option,
    previousData,
    sectorMembersCount,
    indexAddress,
  ) => {
    // Cập nhật sector 0
    setUpdatedSectorMembersSector0((prev) => {
      let newList = [...prev] // Giữ dữ liệu cũ
      if (newValue === filterSector[0]?._id) {
        newList = [
          ...updatedSectorMembersSector0,
          sectorMembersCount[indexAddress],
        ]
      } else {
        newList = newList.filter(
          (member) => member !== sectorMembersCount[indexAddress],
        )
      }
      return [...new Set(newList)]
    })

    // Cập nhật sector 1
    setUpdatedSectorMembersSector1((prev) => {
      let newList = [...prev] // Giữ dữ liệu cũ
      if (newValue === filterSector[1]?._id) {
        newList = [
          ...updatedSectorMembersSector1,
          sectorMembersCount[indexAddress],
        ]
      } else {
        newList = newList.filter(
          (member) => member !== sectorMembersCount[indexAddress],
        )
      }
      return [...new Set(newList)]
    })
  }

  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }
  const {
    actions,
    data: { listUserByWallet, isLoading },
  } = useUser()

  useEffect(() => {
    if (sectorMembersCountInfo && sectorMembersCountInfo.length > 0) {
      const params = {
        search: keyword,
        allWallets: sectorMembersCountInfo,
        pageSize: pageSize,
        page: page,
      }
      actions.getUserByWallet(params)
    }
  }, [sectorMembersCountInfo, pageSize, page, keyword])
  const handleChangeSector = () => {
    // actionsSector.deleteSectorMember({
    //   _id: deletePreviousSector._id,
    //   user_id: deletePreviousSector.user_id[0],
    // })
    actionsSector?.changeSectorMember(changeSector, (res) => {
      if (res?.success === true) {
        onSuccess()
      }
    })
  }

  const onSuccess = () => {
    notification.success({
      message: t('common.message.changeSectorSuccessfully'),
      placement: 'topRight',
      duration: NOTIFICATION_DURATION,
    })

    setListSortUserByWallet((prev) =>
      prev.filter(
        (user) =>
          !updatedSectorMembersSector0.includes(user.address_wallet) &&
          !updatedSectorMembersSector1.includes(user.address_wallet),
      ),
    )

    setTimeout(() => {
      refreshData()
    }, 200)

    setUpdatedSectorMembersSector0([])
    setUpdatedSectorMembersSector1([])
    setChangeSector({})
  }

  useEffect(() => {
    if (sectorMembersCountInfo && sectorMembersCountInfo.length > 0) {
      const SortListUserByWallet = listUserByWallet.sort(
        (a, b) =>
          sectorMembersCountInfo.indexOf(a.address_wallet) -
          sectorMembersCountInfo.indexOf(b.address_wallet),
      )
      setListSortUserByWallet(SortListUserByWallet)
    } else {
      setListSortUserByWallet([])
    }
  }, [sectorMembersCountInfo, listUserByWallet])

  return (
    <Modal
      title={`Sector Detail (${dataSector?.sector_name})`}
      onCancel={handleClose}
      open={isShowModal}
      width={1300}
      centered
      destroyOnClose
      footer={null}
    >
      <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
        <Form
          onFinish={handleSearch}
          className="w-full sm:w-auto"
          form={formSearch}
          translate="yes"
          autoComplete="off"
          size="middle"
          validateTrigger={['onBlur', 'onChange']}
          requiredMark={false}
        >
          <Form.Item
            className="w-full"
            label={
              <span className="font__M__plus font-bold">
                {t('common.search')}
              </span>
            }
            name="keyword"
            labelAlign="left"
          >
            <Input className="w-full" placeholder={t('common.search')} />
          </Form.Item>
        </Form>
      </div>
      <div className="ContainerGiftDetail">
        <Row
          style={{
            fontFamily: 'Rexliafree',
            padding: '16px',
          }}
        >
          <Col
            span={5}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.name')}
          </Col>
          <Col
            span={5}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.walletAddress')}
          </Col>
          <Col
            span={4}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.rank')}
          </Col>

          <Col
            span={4}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.createdAt')}
          </Col>
          <Col
            span={6}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('sector.changeSector')}
          </Col>
        </Row>

        {listSortUserByWallet?.map((item, index) => (
          <UserRow
            key={item._id}
            item={item}
            indexAddress={index}
            dataSector={dataSector}
            listSector={listSector}
            isLoading={isLoading}
            totalItems={listSortUserByWallet?.length}
            sectorMembersCount={sectorMembersCountInfo}
            handChange={handChange}
          />
        ))}
      </div>
      <div className="pagination__common scrollbar_container mb-4 mt-[-50px] ">
        <Pagination
          className="pageSector"
          current={page}
          pageSize={pageSize}
          total={sectorMembersCountInfo.length}
          onChange={(newPage, newPageSize) => {
            if (newPageSize !== pageSize) {
              setPage(1)
              setPageSize(newPageSize)
            } else {
              setPage(newPage)
            }
          }}
          showSizeChanger
        />
      </div>
      <div key="buttons" className="flex justify-end mr-[6%]">
        <Button
          // className="border-hidden"
          key="ok"
          htmlType="submit"
          type="primary"
          // style={okButtonStyle}
          disabled={checkChangeSector}
          onClick={() => handleChangeSector()}
        >
          {t('common.btn.change')}
        </Button>
      </div>
    </Modal>
  )
}

SectorDetail.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default SectorDetail
