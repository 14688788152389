import { useMemo } from 'react'

import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import notificationActions from '../actions/notification'

export const useNotifications = () => {
  const data = useSelector((state) => {
    return get(state, 'app.notificationsManagement')
  })

  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(notificationActions, dispatch),
    [dispatch],
  )

  return {
    actions,
    data,
  }
}
