import { call, put, takeLatest } from 'redux-saga/effects'
import {
  guildScoreSuccess,
  guildScoreFailed,
  GUILD_SCORE_START,
} from '../../actions/guild'
import { api } from '~/services/api/gameApi'
// import { api } from '~/services/api/blockchainApi'

/**
 * score guild api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const scoreGuildsApi = (props) => {
  var uri = `/api/game/guild/get-guild-score`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doScoreGuilds(action) {
  try {
    const response = yield call(scoreGuildsApi, action?.payload)
    yield put(guildScoreSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(guildScoreFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch score guild
 */
export default function* watchScoreGuilds() {
  yield takeLatest(GUILD_SCORE_START, doScoreGuilds)
}
