import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  editMaintenanceSuccess,
  editMaintenanceFailed,
  EDIT_MAINTENANCE_START,
} from '~/views/app/redux/actions/maintenance'

/**
 * edit maintenance api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const editMaintenanceApi = (props) => {
  const uri = `/maintenances/${props?.id}`
  return api.patch(uri, props?.data)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doEditMaintenance(action) {
  try {
    const response = yield call(editMaintenanceApi, action?.payload)

    if (response?.statusCode !== 400) {
      yield put(editMaintenanceSuccess(response))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      } else {
        throw response?.message
      }
    }
  } catch (error) {
    yield put(editMaintenanceFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch edit maintenance
 */
export default function* watchEditMaintenance() {
  yield takeLatest(EDIT_MAINTENANCE_START, doEditMaintenance)
}
