import { addKeyToList } from '~/helpers/transform-data'
import {
  GET_LIST_MAINTENANCE_START,
  GET_LIST_MAINTENANCE_SUCCESS,
  GET_LIST_MAINTENANCE_FAILED,
  ADD_MAINTENANCE_START,
  ADD_MAINTENANCE_SUCCESS,
  ADD_MAINTENANCE_FAILED,
  EDIT_MAINTENANCE_START,
  EDIT_MAINTENANCE_SUCCESS,
  EDIT_MAINTENANCE_FAILED,
  DELETE_MAINTENANCE_START,
  DELETE_MAINTENANCE_SUCCESS,
  DELETE_MAINTENANCE_FAILED,
} from '../actions/maintenance'

const initialState = {
  isLoadingGetMaintenanceList: false,
  isLoading: false,
  isLoadingDelete: false,
  listMaintenances: [],
  dataDelete: {},
}

/**
 * maintenances reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function maintenancesManagement(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_MAINTENANCE_START:
      return {
        ...state,
        isLoadingGetMaintenanceList: true,
      }

    case ADD_MAINTENANCE_START:
    case EDIT_MAINTENANCE_START:
      return {
        ...state,
        isLoading: true,
      }
    case DELETE_MAINTENANCE_START:
      return {
        ...state,
        isLoadingDelete: true,
      }

    //

    case GET_LIST_MAINTENANCE_SUCCESS:
      return {
        ...state,
        listMaintenances: addKeyToList(
          action?.payload?.data,
          action.payload?.currentPage,
          action.payload?.pageSize,
        ),
        total: action?.payload?.total,
        isLoadingGetMaintenanceList: false,
      }

    case ADD_MAINTENANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case EDIT_MAINTENANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_MAINTENANCE_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
        dataDelete: action?.payload,
      }

    //
    case GET_LIST_MAINTENANCE_FAILED:
      return {
        ...state,
        isLoadingGetMaintenanceList: true,
      }

    case ADD_MAINTENANCE_FAILED:
    case EDIT_MAINTENANCE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_MAINTENANCE_FAILED:
      return {
        ...state,
        isLoadingDelete: false,
      }
    default:
      return state
  }
}
