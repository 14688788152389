import { Button, Modal } from 'antd'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import dayjs from 'dayjs'
import { useState } from 'react'
export default function PreviewNotification(props) {
  const { t = {}, title = null, action = {}, dataNotification } = props
  const [btnLang, setBtnLang] = useState('ENG')
  return (
    <Modal
      centered
      width={1000}
      getContainer={false}
      title={
        <h3 className="flex justify-center text-xl font-normal text-[#fff]">
          {title ?? t('common.preview')}
        </h3>
      }
      open={action?.isModalOpen}
      onCancel={action?.handleCancel}
      footer={[
        <div key="buttons" className="flex justify-center">
          <Button
            key="cancel"
            onClick={action?.handleCancel}
            style={{ fontWeight: 'bold' }}
          >
            {t('cancel_sell.close')}
          </Button>
        </div>,
      ]}
    >
      <div className="flex justify-start gap-4 pl-[20px]">
        <Button
          onClick={() => setBtnLang('ENG')}
          style={{
            background: btnLang === 'ENG' ? '#1890FF' : 'rgba(0, 0, 0, 0.45)',
          }}
        >
          ENG
        </Button>
        <Button
          onClick={() => setBtnLang('JP')}
          style={{
            background: btnLang === 'JP' ? '#1890FF' : 'rgba(0, 0, 0, 0.45)',
          }}
        >
          JP
        </Button>
      </div>
      <div className="markdown-content ">
        <div className="text-[20px]">
          {dayjs(dataNotification?.notification_date).format('MMM. D YYYY')}
        </div>
        <div className="font-bold text-[24px]">
          {btnLang === 'ENG'
            ? dataNotification?.title
            : dataNotification?.title_jp}
        </div>
        <hr className="my-4 border-t border-gray-300" />
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {btnLang === 'ENG'
            ? dataNotification?.content
            : dataNotification?.content_jp}
        </ReactMarkdown>
      </div>
    </Modal>
  )
}
