import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  deleteMaintenanceSuccess,
  deleteMaintenanceFailed,
  DELETE_MAINTENANCE_START,
} from '~/views/app/redux/actions/maintenance'

/**
 * delete maintenance api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const deleteMaintenanceApi = (props) => {
  const uri = `/maintenances/${props?.id}`
  return api.delete(uri)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */

function* doDeleteMaintenance(action) {
  try {
    const response = yield call(deleteMaintenanceApi, action?.payload)
    yield put(deleteMaintenanceSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(deleteMaintenanceFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch delete notification request
 */
export default function* watchDeleteMaintenance() {
  yield takeLatest(DELETE_MAINTENANCE_START, doDeleteMaintenance)
}
