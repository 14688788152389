import React from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTE as ROUTE_APP } from '~/views/app/routes/config'
import CardLogin from '../components/CardLogin'
import { useAuth } from '~/views/auth/redux/hooks/useAuth'
import { useSDK } from '@metamask/sdk-react'
import { providerOAS } from '~/blockchain/oas'
import { utils } from 'ethers'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { USER_INFO_GAME } from '~/common/constants'

export default function LoginForm(props) {
  // eslint-disable-next-line
  const { t } = props
  const message = 'Login with Re.Monster: '
  const { sdk } = useSDK()
  const { actions: actionTheme, onLocaleChange } = useTheme()
  let history = useHistory()
  const { actions } = useAuth()
  const handleLoginMetamask = async () => {
    sdk?.disconnect()
    sdk?.terminate()
    await sdk
      ?.connect()
      .then(async (accounts) => {
        let dataUser = {
          address: accounts[0],
        }
        let signatureGame = await sdk.getProvider().request({
          method: 'personal_sign',
          params: [accounts[0], message],
        })
        let dataUserGame = {
          address_wallet: accounts[0],
          signature: signatureGame,
          message: message,
          wallet: 'MWGame',
          is_market: true,
          client: 'ADMIN',
        }
        localStorage.setItem('walletGame', 'MWGame')
        localStorage.setItem('wallet', 'MW')
        actions.getNonce(dataUser, async (res) => {
          if (res?.message === 'USER_NOT_FOUND') {
            return
          }
          let signature = await sdk.getProvider().request({
            method: 'personal_sign',
            params: [accounts[0], message + res?.nonce],
          })
          const paramLogin = {
            address: accounts[0],
            signature: signature,
          }
          actions.login(paramLogin, (res) => {
            history.push(ROUTE_APP.HOME?.PATH)
            actionTheme.updateInfoUser(res)
            if (res?.lang) {
              actionTheme.onLocaleChange(res?.lang)
              onLocaleChange(res?.lang)
            }
          })
        })
        actions.loginGame(dataUserGame, (res) => {
          localStorage.setItem(USER_INFO_GAME, JSON.stringify(res))

          // actionTheme.updateInfoUser(res)
          // if(res?.player_language){
          //   actionTheme.onLocaleChange(res?.player_language)
          // }
        })
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error)
      })
    // history.push(ROUTE_APP.HOME?.PATH)
  }
  // eslint-disable-next-line
  const handleLogin = () => {
    try {
      providerOAS
        .connect()
        .then(async () => {
          let address = providerOAS.accounts[0]

          let dataUser = {
            address_wallet: address,
          }
          localStorage.setItem('wallet', 'OAS')
          actions.getNonce(dataUser, async (res) => {
            const signature = await providerOAS.request({
              method: 'personal_sign',
              params: [utils.hexlify(utils.toUtf8Bytes(message + res?.nonce))],
            })
            const paramLogin = {
              address: address,
              signature: signature,
            }
            actions.login(paramLogin, (res) => {
              history.push(ROUTE_APP.HOME?.PATH)
              actionTheme.updateInfoUser(res)
              if (res?.lang) {
                actionTheme.onLocaleChange(res?.lang)
                onLocaleChange(res?.lang)
              }
            })
          })
        })
        .catch(() => {})
    } catch (error) {}
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <CardLogin
          handleLogin={handleLoginMetamask}
          type="METAMASK"
          title={t('wallet.metamask')}
        />
        <CardLogin
          handleLogin={handleLogin}
          type="OAS"
          title={t('wallet.oas')}
        />
      </div>
    </>
  )
}
