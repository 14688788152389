import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Form, Input, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import { DeleteFilled, EditFilled, EyeOutlined } from '@ant-design/icons'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { formatAddress } from '~/helpers/common'
import configs from '~/configs'
import NotificationForm from './form'
import { useNotifications } from '../../redux/hooks/useNotification'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import DeleteNotification from './form/deleteNotificationModal'
import PreviewNotification from './form/previewNotification'
import ModalViewAddress from './form/modalListReclpient'
import { handleExportCSVScreen } from '~/common/constants'
const ManagementNotification = () => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const userTimezone = dayjs.tz.guess() // Lấy múi giờ local của user
  const userUtcOffset = dayjs().tz(userTimezone).utcOffset() / 60 // Lấy offset theo giờ

  const { t } = useTranslation()
  const [formSearch] = Form.useForm()
  const [isModalOpenDelete, setIsModalOpenDelete] = useState({
    isOpenModal: false,
    user: {},
  })
  dayjs.extend(utc)
  const [isModalOpenPreview, setIsModalOpenPreview] = useState({
    isOpenModal: false,
    user: {},
  })
  const [isModalOpenPreviewAddress, setIsModalOpenPreviewAddress] = useState({
    isOpenModal: false,
    data: {},
  })
  const {
    data: { user },
  } = useTheme()

  const {
    actions: notificationActions,
    data: { isLoadingGetNotificationList, listNotification, total },
  } = useNotifications()

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  //Control all modal
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    noti: {},
  })
  const showModalDelete = (content, data) => {
    setIsModalOpenDelete({
      content: content,
      data: data,
      isOpenModal: true,
    })
  }
  const handleCancelDelete = () => {
    setIsModalOpenDelete({
      content: null,
      idArray: [],
      isOpenModal: false,
    })
  }
  const showModalPreview = (data) => {
    setIsModalOpenPreview({
      data: data,
      isOpenModal: true,
    })
  }
  const showModalPreviewAddress = (data) => {
    setIsModalOpenPreviewAddress({
      data: data,
      isOpenModal: true,
    })
  }

  const handleCancelPreview = () => {
    setIsModalOpenPreview({
      idArray: [],
      isOpenModal: false,
    })
  }
  const handleCancelPreviewAddress = () => {
    setIsModalOpenPreviewAddress({
      idArray: [],
      isOpenModal: false,
    })
  }
  const renderReceiverAddressesTo = (addresses) => {
    const maxDisplay = 3
    return (
      <>
        {addresses.slice(0, maxDisplay).map((address, index) => {
          let link = `${configs.NETWORK.scan}address/${address}`
          return (
            <a
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              href={link}
              title={address}
              style={{
                color: '#40A9FF',
                display: 'block',
                marginBottom: '5px',
              }}
            >
              {formatAddress(address)}
            </a>
          )
        })}
        {addresses.length > maxDisplay && (
          <span style={{ display: 'block', color: '#40A9FF' }}>...</span>
        )}
      </>
    )
  }

  const onRemove = (data) => {
    showModalDelete(t('cancel_modal.question', { question: '' }), data)
  }
  const columns = [
    {
      title: t('common.id'),
      dataIndex: 'key',
      key: 'key',
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementNotification.form.title'),
      dataIndex: 'title',
      key: 'title',
      render: (title) => {
        return <div className="">{title}</div>
      },
    },
    {
      title: t('managementNotification.form.Sender'),
      dataIndex: 'admin',
      key: 'admin',
      render: (admin) => {
        let link = `${configs.NETWORK.scan}address/${admin?.address_wallet}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={admin?.address_wallet}
            style={{ color: '#40A9FF' }}
          >
            {formatAddress(admin?.address_wallet)}
          </a>
        )
      },
    },
    {
      title: t('managementNotification.form.last_modified_by'),
      dataIndex: 'lastEditedBy',
      key: 'lastEditedBy',
      render: (lastEditedBy) => {
        let link = `${configs.NETWORK.scan}address/${lastEditedBy?.address_wallet}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={lastEditedBy?.address_wallet}
            style={{ color: '#40A9FF' }}
          >
            {lastEditedBy?.address_wallet
              ? formatAddress(lastEditedBy?.address_wallet)
              : 'ー'}
          </a>
        )
      },
    },

    {
      title: t('managementNotification.form.Recipient'),
      dataIndex: 'address_wallets',
      key: 'address_wallets',
      render: (receiver_address, data) => {
        return (
          <div className="flex gap-5">
            <div className="flex flex-col">
              {receiver_address?.length > 0
                ? renderReceiverAddressesTo(receiver_address)
                : 'All users'}
            </div>

            {receiver_address?.length > 3 && (
              // <Button
              //   type="ghost"
              //   loading={false}
              //   icon={<EyeOutlined style={{ color: '#1890FF', fontSize: 6 }} />}
              //   // onClick={() => {
              //   //   showModalPreview(data)
              //   // }}
              // />

              <EyeOutlined
                onClick={() => {
                  showModalPreviewAddress(data)
                }}
                style={{ color: '#1890FF', fontSize: 16, cursor: 'pointer' }}
              />
            )}
          </div>
        )
      },
    },

    {
      title: (
        <>
          {t('managementNotification.form.notification_date')} (UTC{' '}
          {userUtcOffset >= 0 ? `+${userUtcOffset}` : userUtcOffset})
        </>
      ),

      dataIndex: 'notification_date',
      key: 'notification_date',
      render: (notification_date) => {
        return dayjs(notification_date).local().format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: t('managementNotification.form.createdAt'),
      dataIndex: 'crt_dt',
      key: 'crt_dt',
      render: (crt_dt) => {
        return dayjs(crt_dt).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: t('managementNotification.form.modifiedAt'),
      dataIndex: 'mod_dt',
      key: 'mod_dt',
      render: (mod_dt) => {
        return dayjs(mod_dt).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: t('common.action'),
      render: (_, data) => {
        const isPastNotification = dayjs()
          .local()
          .isAfter(dayjs(data.notification_date).local())
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={<EditFilled style={{ color: '#36CFC9' }} />}
              onClick={() => onShowModalForm(data?._id, data)}
              disabled={isPastNotification}
            />
            <Button
              type="ghost"
              loading={false}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
              onClick={() => {
                onRemove(data)
              }}
              disabled={isPastNotification}
            />
            <Button
              type="ghost"
              loading={false}
              icon={<EyeOutlined style={{ color: '#1890FF' }} />}
              onClick={() => {
                showModalPreview(data)
              }}
            />
          </Space>
        )
      },
    },
  ]

  //Refresh data
  const params = {
    search: keyword,
    pageSize: pageSize,
    page,
  }
  const refreshData = () => {
    notificationActions.getNotificationLists(params)
  }

  // Handle delete

  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword])

  // Handle open modal (create-edit-delete)
  const onShowModalForm = (id, noti) => {
    setModalForm({
      id,
      isOpenModal: true,
      noti: noti,
    })
  }

  // Handle close modal (create-edit-delete)
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      noti: {},
    })
  }

  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }

  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Space className="d-flex justify-content-between justify-content-sm-start">
            <Button
              type="primary"
              onClick={() => {
                onShowModalForm(null, {})
              }}
            >
              {t('common.btn.addNew')}
            </Button>

            <Button
              style={{ backgroundColor: '#1890FF' }}
              onClick={() =>
                handleExportCSVScreen(
                  'NOTIFICATION',
                  params,
                  'Notification_Management',
                )
              }
            >
              Export to CSV
            </Button>
          </Space>
        </Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <Form
                onFinish={handleSearch}
                className="w-full sm:w-auto"
                form={formSearch}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {t('common.search')}
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Input className="w-full" placeholder={t('common.search')} />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listNotification}
        loading={isLoadingGetNotificationList}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: total,
          keyword,
          setKeyword,
        }}
      />

      {modalForm.isOpenModal && (
        <NotificationForm
          address_wallet_key={user?.address_wallet}
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          refreshData={refreshData}
          id={modalForm.id}
          noti={modalForm?.noti}
        />
      )}

      <DeleteNotification
        action={{
          isModalOpen: isModalOpenDelete.isOpenModal,
          handleCancel: handleCancelDelete,
        }}
        refreshData={refreshData}
        dataGuild={isModalOpenDelete.data}
        t={t}
      />
      <PreviewNotification
        action={{
          isModalOpen: isModalOpenPreview.isOpenModal,
          handleCancel: handleCancelPreview,
        }}
        dataNotification={isModalOpenPreview.data}
        t={t}
      />
      <ModalViewAddress
        action={{
          isModalOpen: isModalOpenPreviewAddress.isOpenModal,
          handleCancel: handleCancelPreviewAddress,
        }}
        data={isModalOpenPreviewAddress.data}
        t={t}
      />
    </div>
  )
}

export default ManagementNotification
