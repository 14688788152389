import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getNotificationListsSuccess,
  getNotificationListsFailed,
  GET_LIST_NOTIFICATION_START,
} from '../../actions/notification'
import { api } from '~/services/api'

/**
 * get list notification api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getNotificationListsApi = (props) => {
  var uri = `/notifications`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetNotificationLists(action) {
  try {
    const response = yield call(getNotificationListsApi, action?.payload)
    yield put(getNotificationListsSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(getNotificationListsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list notification
 */
export default function* watchGetNotificationLists() {
  yield takeLatest(GET_LIST_NOTIFICATION_START, doGetNotificationLists)
}
