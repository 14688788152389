import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Form, Input, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import { DeleteFilled, EditFilled, PlayCircleOutlined } from '@ant-design/icons'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { formatAddress } from '~/helpers/common'
import configs from '~/configs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { handleExportCSVScreen } from '~/common/constants'
import DeleteMaintenance from './form/deleteMaintenance'
import MainteanceForm from './form'
import { useMaintenances } from '../../redux/hooks/useMaintenance'

const ManagementMainteance = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()
  const [isModalOpenDelete, setIsModalOpenDelete] = useState({
    isOpenModal: false,
    user: {},
  })
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const userTimezone = dayjs.tz.guess() // Lấy múi giờ local của user
  const userUtcOffset = dayjs().tz(userTimezone).utcOffset() / 60 // Lấy offset theo giờ
  const {
    data: { user },
  } = useTheme()
  const currentTime = Date.now()
  const rowClassName = (record) => {
    const isInRange =
      new Date(record?.time_start).getTime() <= currentTime &&
      currentTime <= new Date(record?.time_end).getTime()

    if (isInRange) {
      return 'highlight-row'
    }
    return ''
  }
  const {
    actions: maintenanceActions,
    data: { isLoadingGetMaintenanceList, listMaintenances, total },
  } = useMaintenances()

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  //Control all modal
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    maintenance: {},
    checkPause: false,
  })
  const showModalDelete = (content, data) => {
    setIsModalOpenDelete({
      content: content,
      data: data,
      isOpenModal: true,
    })
  }
  const handleCancelDelete = () => {
    setIsModalOpenDelete({
      content: null,
      idArray: [],
      isOpenModal: false,
    })
  }

  const onRemove = (data) => {
    showModalDelete(t('cancel_modal.question', { question: '' }), data)
  }
  const columns = [
    {
      title: t('ManagementMainteance.form.note'),
      dataIndex: 'note',
      key: 'note',
      render: (note) => {
        return <div className="">{note}</div>
      },
    },
    {
      title: (
        <>
          {t('ManagementMainteance.form.timeStart')} (UTC{' '}
          {userUtcOffset >= 0 ? `+${userUtcOffset}` : userUtcOffset})
        </>
      ),
      dataIndex: 'time_start',
      key: 'time_start',
      render: (time_start) => {
        return dayjs(time_start).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      // title: t('ManagementMainteance.form.timeEnd'),
      title: (
        <>
          {t('ManagementMainteance.form.timeEnd')} (UTC{' '}
          {userUtcOffset >= 0 ? `+${userUtcOffset}` : userUtcOffset})
        </>
      ),
      dataIndex: 'time_end',
      key: 'time_end',
      render: (time_end) => {
        return dayjs(time_end).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    // {
    //   title: t('ManagementMainteance.form.Sender'),
    //   dataIndex: 'admin',
    //   key: 'admin',
    //   render: (admin) => {
    //     let link = `${configs.NETWORK.scan}address/${admin?.address_wallet}`
    //     return (
    //       <a
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         href={link}
    //         title={admin?.address_wallet}
    //         style={{ color: '#40A9FF' }}
    //       >
    //         {formatAddress(admin?.address_wallet)}
    //       </a>
    //     )
    //   },
    // },
    {
      title: t('ManagementMainteance.form.creator'),
      dataIndex: 'creator',
      key: 'creator',
      render: (creator) => {
        let link = `${configs.NETWORK.scan}address/${creator?.address_wallet}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={creator?.address_wallet}
            style={{ color: '#40A9FF' }}
          >
            {creator?.address_wallet
              ? formatAddress(creator?.address_wallet)
              : 'ー'}
          </a>
        )
      },
    },
    // {
    //   title: t('ManagementMainteance.form.Recipient'),
    //   dataIndex: 'address_wallets',
    //   key: 'address_wallets',
    //   render: (receiver_address, data) => {
    //     return (
    //       <div className="flex gap-5">
    //         <div className="flex flex-col">
    //           {receiver_address?.length > 0
    //             ? renderReceiverAddressesTo(receiver_address)
    //             : 'All users'}
    //         </div>
    //       </div>
    //     )
    //   },
    // },

    // {
    //   title: t('ManagementMainteance.form.notification_date'),
    //   dataIndex: 'notification_date',
    //   key: 'notification_date',
    //   render: (notification_date) => {
    //     return dayjs(notification_date).local().format('YYYY-MM-DD HH:mm:ss')
    //   },
    // },
    {
      title: t('ManagementMainteance.form.createdAt'),
      dataIndex: 'crt_dt',
      key: 'crt_dt',
      render: (crt_dt) => {
        return dayjs(crt_dt).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: t('ManagementMainteance.form.modifiedBy'),
      dataIndex: 'lastEditedBy',
      key: 'lastEditedBy',
      render: (lastEditedBy) => {
        let link = `${configs.NETWORK.scan}address/${lastEditedBy?.address_wallet}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={lastEditedBy?.address_wallet}
            style={{ color: '#40A9FF' }}
          >
            {lastEditedBy?.address_wallet
              ? formatAddress(lastEditedBy?.address_wallet)
              : 'ー'}
          </a>
        )
      },
    },
    {
      title: t('ManagementMainteance.form.modifiedAt'),
      dataIndex: 'mod_dt',
      key: 'mod_dt',
      render: (mod_dt) => {
        return dayjs(mod_dt).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: t('managementNotification.form.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        return <div className="">{type}</div>
      },
    },
    {
      title: t('common.action'),
      render: (_, data) => {
        const isPastNotification = dayjs()
          .local()
          .isAfter(dayjs(data.time_end).local())
        const isInRange =
          new Date(data?.time_start).getTime() <= currentTime &&
          currentTime <= new Date(data?.time_end).getTime()
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={<EditFilled style={{ color: '#36CFC9' }} />}
              onClick={() => onShowModalForm(data?._id, data, false)}
              disabled={isPastNotification}
            />
            <Button
              type="ghost"
              loading={false}
              icon={
                isInRange ? (
                  <PlayCircleOutlined style={{ color: '#FFA500 ' }} />
                ) : (
                  <DeleteFilled style={{ color: '#FF4D4F' }} />
                )
              }
              onClick={() => {
                isInRange
                  ? onShowModalForm(data?._id, data, true)
                  : onRemove(data)
              }}
              disabled={isPastNotification}
            />
          </Space>
        )
      },
    },
  ]

  //Refresh data
  const params = {
    search: keyword,
    pageSize: pageSize,
    page,
  }
  const refreshData = () => {
    maintenanceActions.getMaintenanceLists(params)
  }

  // Handle delete

  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword])

  // Handle open modal (create-edit-delete)
  const onShowModalForm = (id, maintenance, pause) => {
    setModalForm({
      id,
      isOpenModal: true,
      maintenance: maintenance,
      checkPause: pause,
    })
  }

  // Handle close modal (create-edit-delete)
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      maintenance: {},
      checkPause: false,
    })
  }

  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }

  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Space className="d-flex justify-content-between justify-content-sm-start">
            <Button
              type="primary"
              onClick={() => {
                onShowModalForm(null, {})
              }}
            >
              {t('common.btn.addNew')}
            </Button>

            <Button
              style={{ backgroundColor: '#1890FF' }}
              onClick={() =>
                handleExportCSVScreen(
                  'MAINTENACE',
                  params,
                  'Maintenance_Management',
                )
              }
            >
              Export to CSV
            </Button>
          </Space>
        </Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <Form
                onFinish={handleSearch}
                className="w-full sm:w-auto"
                form={formSearch}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {t('common.search')}
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Input className="w-full" placeholder={t('common.search')} />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listMaintenances}
        loading={isLoadingGetMaintenanceList}
        rowClassName={rowClassName}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: total,
          keyword,
          setKeyword,
        }}
      />

      {modalForm.isOpenModal && (
        <MainteanceForm
          address_wallet_key={user?.address_wallet}
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          refreshData={refreshData}
          id={modalForm.id}
          maintenance={modalForm?.maintenance}
          checkPause={modalForm.checkPause}
        />
      )}

      <DeleteMaintenance
        action={{
          isModalOpen: isModalOpenDelete.isOpenModal,
          handleCancel: handleCancelDelete,
        }}
        refreshData={refreshData}
        dataMaintenance={isModalOpenDelete.data}
        t={t}
      />
    </div>
  )
}

export default ManagementMainteance
