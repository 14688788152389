import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey:
    process.env.REACT_APP_FIREBASE_API_KEY ||
    'AIzaSyAzlGac8UlKsxKBf2172Vo0R22x0-xggu0',
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
    'remonster-20250324.firebaseapp.com',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'remonster-20250324',
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
    'remonster-20250324.firebasestorage.app',
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '226950676098',
  appId:
    process.env.REACT_APP_FIREBASE_APP_ID ||
    '1:226950676098:web:b55b7e7ef790648b5f7e9b',
  measurementId: process.env.REACT_APP_MEASUREMENT_ID || 'G-PZ13QH8QSX',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export default app
