import { useTranslation } from '~/common/hooks/useTranslation'
import {
  Button,
  Col,
  Form,
  notification,
  Row,
  Select,
  Space,
  Switch,
} from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import configs from '~/configs'
import {
  handleExportCSVScreen,
  NOTIFICATION_DURATION,
} from '~/common/constants'
import { DeleteFilled } from '@ant-design/icons'
import { useWhiteLists } from '~/views/app/redux/hooks/useWhiteList'
import DeleteUserWhiteListCommon from '~/common/hooks/useDeleteWhiteList'

const WhiteList = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()
  const [isModalOpenDelete, setIsModalOpenDelete] = useState({
    isOpenModal: false,
    user: {},
  })

  const {
    actions,
    data: {
      isTongle,
      isChange,
      listWhiteList,
      isLoadingGetListWhiteList,
      listAddWhiteList,
      total,
    },
  } = useWhiteLists()

  useEffect(() => {
    const params = {
      key: 'IS_ACTIVE_WHITE_LIST',
    }
    actions.getTongleWhiteLists(params)
  }, [])

  const [toggleState, setToggleState] = useState()

  useEffect(() => {
    // Đồng bộ hóa giá trị toggleState với isTongle khi isTongle thay đổi
    if (isTongle !== undefined) {
      setToggleState(isTongle?.value)
    }
    if (isChange !== undefined) {
      setToggleState(isChange?.value)
    }
  }, [isTongle, isChange])
  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  const showModalDelete = (content, data) => {
    setIsModalOpenDelete({
      content: content,
      data: data,
      isOpenModal: true,
    })
  }
  const handleCancelDelete = () => {
    setIsModalOpenDelete({
      content: null,
      data: null,
      isOpenModal: false,
    })
  }
  const onRemove = (data) => {
    showModalDelete(t('cancel_modal.question', { question: '' }), data)
  }
  const handleToggle = () => {
    const params = {
      value: !toggleState, // Trạng thái toggle sẽ là true/false (boolean)
      key: 'IS_ACTIVE_WHITE_LIST',
    }

    actions.changeTongleWhiteLists(params)
  }
  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword])
  const columns = [
    {
      title: t('common.no'),
      dataIndex: 'key',
      key: 'key',
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementUsers.form.name'),
      dataIndex: 'user_name',
      key: 'user_name',
      render: (user_name, data) => {
        return <div className="">{data?.user_id?.user_name}</div>
      },
    },
    {
      title: t('managementUsers.form.walletAddress'),
      dataIndex: 'address_wallet',
      key: 'address_wallet',
      render: (address_wallet, data) => {
        let link = `${configs.NETWORK.scan}address/${data?.user_id?.address_wallet}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={data?.user_id?.address_wallet}
            style={{ color: '#40A9FF' }}
          >
            {data?.user_id?.address_wallet}
          </a>
        )
      },
    },
    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
              // onClick={() => onShowModalForm(data?._id, data)}
              onClick={() => {
                onRemove(data)
              }}
            />
          </Space>
        )
      },
    },
  ]

  //Refresh data
  const refreshData = () => {
    const params = {
      pageSize: pageSize,
      page,
    }
    actions.getListWhiteLists(params)
    actions.getListAddWhiteLists()
  }
  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword])

  const generateItemList = (listAddWhiteList) =>
    listAddWhiteList?.map((item) => ({
      key: item?._id,
      label: (
        <Row>
          <Col span={24}>Name: {item?.user_name}</Col>
          <Col span={24}>Wallet address: {item?.address_wallet}</Col>
        </Row>
      ),
      value: item?.address_wallet,
      name: item?.user_name,
    }))

  const handleAddUserBlackList = async (value) => {
    const params = {
      wallet_address: value,
    }
    try {
      await actions.createWhiteLists(params)
      setTimeout(() => {
        refreshData()
        formSearch.resetFields()
        notification.success({
          message: t('common.message.createUserSuccessfully'),
          placement: 'topRight',
          duration: NOTIFICATION_DURATION,
        })
      }, 500)
    } catch (error) {
      //eslint-disable-next-line no-console
      console.log(error)
    }
  }
  const params = {}
  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Form
            onFinish={handleSearch}
            className="w-[80%]"
            form={formSearch}
            autoComplete="off"
            size="middle"
            validateTrigger={['onBlur', 'onChange']}
            requiredMark={false}
          >
            <Form.Item
              className="w-full"
              label={
                <span className="font__M__plus font-bold">
                  {t('common.add')}
                </span>
              }
              name="keyword"
              labelAlign="left"
            >
              <Select
                className="w-full addUserList"
                onChange={handleAddUserBlackList}
                options={generateItemList(listAddWhiteList)}
                placeholder={t('common.add')}
                showSearch
                filterOption={(input, option) => {
                  const nameMatch = option?.name
                    ?.toLowerCase()
                    .includes(input?.toLowerCase())
                  const valueMatch = option?.value
                    ?.toLowerCase()
                    .includes(input?.toLowerCase())
                  return nameMatch || valueMatch
                }}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={6}>
          <Button
            style={{ backgroundColor: '#1890FF' }}
            onClick={() =>
              handleExportCSVScreen('WHITE_LIST', params, 'White_List')
            }
          >
            Export to CSV
          </Button>
        </Col>
        <Col xs={24} sm={6}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-center">
              <Switch
                checked={toggleState}
                onChange={handleToggle}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                style={{ marginRight: '4rem' }}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listWhiteList}
        loading={isLoadingGetListWhiteList}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: total,
          keyword,
          setKeyword,
        }}
      />
      <DeleteUserWhiteListCommon
        action={{
          isModalOpen: isModalOpenDelete.isOpenModal,
          handleCancel: handleCancelDelete,
        }}
        refreshData={refreshData}
        dataUserWhiteList={isModalOpenDelete.data}
        t={t}
      />
    </div>
  )
}

export default WhiteList
