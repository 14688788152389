export const GET_LIST_MAINTENANCE_START = 'GET_LIST_MAINTENANCE_START'
export const GET_LIST_MAINTENANCE_SUCCESS = 'GET_LIST_MAINTENANCE_SUCCESS'
export const GET_LIST_MAINTENANCE_FAILED = 'GET_LIST_MAINTENANCE_FAILED'

export const ADD_MAINTENANCE_START = 'ADD_MAINTENANCE_START'
export const ADD_MAINTENANCE_SUCCESS = 'ADD_MAINTENANCE_SUCCESS'
export const ADD_MAINTENANCE_FAILED = 'ADD_MAINTENANCE_FAILED'

export const EDIT_MAINTENANCE_START = 'EDIT_MAINTENANCE_START'
export const EDIT_MAINTENANCE_SUCCESS = 'EDIT_MAINTENANCE_SUCCESS'
export const EDIT_MAINTENANCE_FAILED = 'EDIT_MAINTENANCE_FAILED'

export const DELETE_MAINTENANCE_START = 'DELETE_MAINTENANCE_START'
export const DELETE_MAINTENANCE_SUCCESS = 'DELETE_MAINTENANCE_SUCCESS'
export const DELETE_MAINTENANCE_FAILED = 'DELETE_MAINTENANCE_FAILED'

//-------------------------MAINTENANCE------------------------//

/**
 * [1]
 * get list maintenance start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getMaintenanceLists(payload, onSuccess, onError) {
  return {
    type: GET_LIST_MAINTENANCE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list maintenance success action
 * @param {*} payload
 * @returns {object}
 */
export function getMaintenanceListsSuccess(payload) {
  return {
    type: GET_LIST_MAINTENANCE_SUCCESS,
    payload: payload,
  }
}

/**
 * get list maintenance failed action
 * @returns {object}
 */
export function getMaintenanceListsFailed() {
  return {
    type: GET_LIST_MAINTENANCE_FAILED,
  }
}

/**
 * [2]
 * add maintenance start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function addMaintenance(payload, onSuccess, onError) {
  return {
    type: ADD_MAINTENANCE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * add maintenance success action
 * @param {*} payload
 * @returns {object}
 */
export function addMaintenanceSuccess(payload) {
  return {
    type: ADD_MAINTENANCE_SUCCESS,
    payload: payload,
  }
}

/**
 * add maintenance failed action
 * @returns {object}
 */
export function addMaintenanceFailed() {
  return {
    type: ADD_MAINTENANCE_FAILED,
  }
}

/**
 * [3]
 * edit maintenance start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function editMaintenance(payload, onSuccess, onError) {
  return {
    type: EDIT_MAINTENANCE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * edit maintenance success action
 * @param {*} payload
 * @returns {object}
 */
export function editMaintenanceSuccess(payload) {
  return {
    type: EDIT_MAINTENANCE_SUCCESS,
    payload: payload,
  }
}

/**
 * edit maintenance failed action
 * @returns {object}
 */
export function editMaintenanceFailed() {
  return {
    type: EDIT_MAINTENANCE_FAILED,
  }
}

/**
 * [4]
 * delete maintenance start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function deleteMaintenance(payload, onSuccess, onError) {
  return {
    type: DELETE_MAINTENANCE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * delete maintenance success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteMaintenanceSuccess(payload) {
  return {
    type: DELETE_MAINTENANCE_SUCCESS,
    payload: payload,
  }
}

/**
 * delete maintenance failed action
 * @returns {object}
 */
export function deleteMaintenanceFailed() {
  return {
    type: DELETE_MAINTENANCE_FAILED,
  }
}

export default {
  getMaintenanceLists,
  getMaintenanceListsSuccess,
  getMaintenanceListsFailed,

  addMaintenance,
  addMaintenanceSuccess,
  addMaintenanceFailed,

  editMaintenance,
  editMaintenanceSuccess,
  editMaintenanceFailed,

  deleteMaintenance,
  deleteMaintenanceSuccess,
  deleteMaintenanceFailed,
}
