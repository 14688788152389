import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import {
  AuditOutlined,
  DeleteFilled,
  EditFilled,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import RankingPoolForm from './form'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import RankingPoolDetail from './modalDetail'
import { usePools } from '~/views/app/redux/hooks/usePools'
import {
  DATE_FORMAT_YYYY_MM_DD,
  handleExportCSVScreen,
} from '~/common/constants'
import moment from 'moment'
import DeletePoolCommon from '~/common/hooks/useDeletePool'
import SectorInfor from './form/sectorInfo'
import ResetNFTPoolCommon from '~/common/hooks/useConfirmResetNFT'
import axios from 'axios'

const RankingPool = () => {
  const { t } = useTranslation()
  // const [formSearch] = Form.useForm()
  const [sortedPools, setSortedPools] = useState([])
  const [statusReset, setStatusReset] = useState('loading')

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  const [isModalOpenDelete, setIsModalOpenDelete] = useState({
    isOpenModal: false,
    ranking: {},
  })
  const [isModalOpenConfirmResetNFT, setIsModalOpenConfirmResetNFT] =
    useState(false)
  const {
    actions,
    data: { listPool, isLoadingListPool },
  } = usePools()

  useEffect(() => {
    actions?.getListPools()
  }, [page, pageSize])

  useEffect(() => {
    if (listPool) {
      const sortedList = [...listPool].sort((a, b) => {
        const timeStartDiff = b.timeStart - a.timeStart
        if (timeStartDiff !== 0) return timeStartDiff
        return b.timeEnd - a.timeEnd
      })
      setSortedPools(sortedList)
    }
  }, [listPool])

  const refreshData = () => {
    actions?.getListPools()
  }

  const {
    data: { user },
  } = useTheme()
  const getDataprocessId = JSON.parse(localStorage.getItem('dataprocessId'))

  useEffect(() => {
    let intervalId

    const fetchStatusResetRegen = async () => {
      try {
        if (getDataprocessId?.processId) {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL_BLOCKCHAIN}api/monster/status-reset-regen?processId=${getDataprocessId?.processId}`,
          )

          console.log('Status Reset/Regen Data:', response.data)
          setStatusReset(response.data?.data?.status)

          if (response.data?.data?.status === 'completed') {
            console.log('Process completed successfully!')

            clearInterval(intervalId)
            localStorage.removeItem('dataprocessId')
          }
        } else {
          console.warn('processId is not available.')
        }
      } catch (error) {
        console.error('Error fetching status reset/regen data:', error)
        clearInterval(intervalId)
      }
    }

    fetchStatusResetRegen()

    intervalId = setInterval(fetchStatusResetRegen, 3000)

    return () => clearInterval(intervalId)
  }, [getDataprocessId?.processId])

  //Control all modal
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    ranking: {},
  })
  const [modalFormSector, setModalFormSector] = useState({
    id: null,
    isOpenModal: false,
    sector: {},
  })
  const [modalDetail, setModalDeail] = useState({
    id: null,
    isOpenModal: false,
    ranking: {},
  })

  const showModalDelete = (content, data) => {
    setIsModalOpenDelete({
      content: content,
      data: data,
      isOpenModal: true,
    })
  }
  const handleCancelDelete = () => {
    setIsModalOpenDelete({
      content: null,
      data: null,
      isOpenModal: false,
    })
  }
  const handleCancelConfirmResetNFT = () => {
    setIsModalOpenConfirmResetNFT(false)
  }
  const columns = [
    {
      title: t('managementPools.rankingRewardsPool.form.poolName'),
      dataIndex: 'name',
      key: 'name',
      render: (name) => {
        return <div className="">{name}</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.poolTotal'),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (totalAmount) => {
        return <div className="">{totalAmount} OAS</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.tournament'),
      dataIndex: 'subPools',
      key: 'tournament',
      render: (subPools) => {
        return <div className="">{subPools.tournament.percentage}%</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.weekly'),
      dataIndex: 'subPools',
      key: 'weekly',
      render: (subPools) => {
        return <div className="">{subPools.weekly.percentage}%</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.pvp'),
      dataIndex: 'subPools',
      key: 'pvp',
      render: (subPools) => {
        return <div className="">{subPools.personalPvP.percentage}%</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.exploraion'),
      dataIndex: 'subPools',
      key: 'exploraion',
      render: (subPools) => {
        return <div className="">{subPools.personalExploraion.percentage}%</div>
      },
    },

    {
      title: t('managementPools.rankingRewardsPool.form.guild'),
      dataIndex: 'subPools',
      key: 'guild',
      render: (subPools) => {
        return <div className="">{subPools.guild.percentage}%</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.sector'),
      dataIndex: 'subPools',
      key: 'sector',
      render: (subPools) => {
        return <div className="">{subPools.sector.percentage}%</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.from'),
      dataIndex: 'timeStart',
      key: 'from',
      render: (timeStart) => {
        return moment(timeStart * 1000)
          .utc()
          .format(DATE_FORMAT_YYYY_MM_DD)
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.to'),
      dataIndex: 'timeEnd',
      key: 'to',
      render: (timeEnd) => {
        return moment(timeEnd * 1000)
          .utc()
          .format(DATE_FORMAT_YYYY_MM_DD)
      },
    },

    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={<EditFilled style={{ color: '#36CFC9' }} />}
              onClick={() => onShowModalForm(data?.cycle, data)}
            />

            <Button
              type="ghost"
              loading={false}
              disabled={data?.address_wallet === user?.address_wallet}
              onClick={() => {
                onRemove(data)
              }}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
            />
            <Button
              type="ghost"
              loading={false}
              onClick={() => onShowModalDetail(data?.cycle, data)}
              icon={<EyeOutlined style={{ color: '#1890FF' }} />}
            />
          </Space>
        )
      },
    },
    {
      title: t('menu.sector'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={<AuditOutlined />}
              onClick={() => onShowModalFormSector(data?.timeStart, data)}
            />
          </Space>
        )
      },
    },
  ]

  const currentTime = moment().utc().unix()
  const rowClassName = (record) => {
    const isInRange =
      record?.timeStart <= currentTime && currentTime <= record?.timeEnd

    if (isInRange) {
      return 'highlight-row'
    }
    return ''
  }

  // Handle delete
  const onRemove = (data) => {
    showModalDelete(t('cancel_modal.question', { question: '' }), data)
  }

  const onShowModalForm = (id, ranking) => {
    setModalForm({
      id,
      isOpenModal: true,
      ranking: ranking,
    })
  }
  const onShowModalFormSector = (id, sector) => {
    setModalFormSector({
      id,
      isOpenModal: true,
      sector: sector,
    })
  }
  const onShowModalDetail = (id, ranking) => {
    setModalDeail({
      id,
      isOpenModal: true,
      ranking: ranking,
    })
  }

  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      ranking: {},
    })
  }
  const onCloseModalFormSector = () => {
    setModalFormSector({
      id: null,
      isOpenModal: false,
      sector: {},
    })
  }
  const onCloseModalDetail = () => {
    setModalDeail({
      id: null,
      isOpenModal: false,
      ranking: {},
    })
  }

  // const handleSearch = (values) => {
  //   setKeyword(values?.keyword)
  // }

  const paginatedData = sortedPools.slice(
    (page - 1) * pageSize,
    page * pageSize,
  )
  const paramsExport = {}
  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Space className="d-flex justify-content-between justify-content-sm-start">
            <Button
              type="primary"
              onClick={() => {
                onShowModalForm(null, {})
              }}
            >
              {t('managementPools.rankingRewardsPool.create')}
            </Button>
            <Button
              type="primary"
              onClick={async () => {
                try {
                  const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL_BLOCKCHAIN}api/monster/export-nft-regen-counts`,
                  )

                  const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                  )
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', 'export-nft-regen-counts.csv')
                  document.body.appendChild(link)
                  link.click()
                  window.URL.revokeObjectURL(url)
                } catch (error) {
                  console.error('Lỗi khi tải file:', error)
                }
              }}
            >
              {t('managementPools.rankingRewardsPool.export_NFT')}
            </Button>
            <Button
              style={{ backgroundColor: '#1890FF' }}
              onClick={() =>
                handleExportCSVScreen(
                  'RANKING_POOL_CYCLE',
                  paramsExport,
                  'Cycle_Ranking_Rewards_Pool',
                )
              }
            >
              Export to CSV
            </Button>
          </Space>
        </Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <Button
                style={{ backgroundColor: '#1890ff' }}
                onClick={() => setIsModalOpenConfirmResetNFT(true)}
              >
                Reset NFT Cycle{' '}
                {statusReset !== 'completed' && getDataprocessId !== null && (
                  <LoadingOutlined />
                )}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={paginatedData}
        rowClassName={rowClassName}
        loading={isLoadingListPool}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: sortedPools?.length,
          keyword,
          setKeyword,
        }}
      />

      {modalForm.isOpenModal && (
        <RankingPoolForm
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          refreshData={refreshData}
          id={modalForm.id}
          ranking={modalForm?.ranking}
        />
      )}
      {modalFormSector.isOpenModal && (
        <SectorInfor
          isShowModal={modalFormSector.isOpenModal}
          onClose={onCloseModalFormSector}
          refreshData={refreshData}
          timeStartPool={modalFormSector.id}
          sectorInfor={modalFormSector?.sector}
        />
      )}

      {modalDetail.isOpenModal && (
        <RankingPoolDetail
          isShowModal={modalDetail.isOpenModal}
          onClose={onCloseModalDetail}
          refreshData={refreshData}
          idCycle={modalDetail?.id}
          ranking={modalDetail?.ranking}
          listPools={listPool}
        />
      )}

      <DeletePoolCommon
        action={{
          isModalOpen: isModalOpenDelete.isOpenModal,
          handleCancel: handleCancelDelete,
        }}
        refreshData={refreshData}
        user={user}
        content={isModalOpenDelete.content}
        dataPool={isModalOpenDelete.data}
        t={t}
      />
      <ResetNFTPoolCommon
        action={{
          isModalOpen: isModalOpenConfirmResetNFT,
          handleCancel: handleCancelConfirmResetNFT,
        }}
        title={
          statusReset !== 'completed' && getDataprocessId !== null
            ? t('common.titleConfirmResetNFTModalLoading')
            : t('common.titleConfirmResetNFTModal')
        }
        refreshData={refreshData}
        dataPool={listPool.filter(
          (item) =>
            item.timeStart <= currentTime && currentTime <= item?.timeEnd,
        )}
        checlStatusReset={
          statusReset !== 'completed' && getDataprocessId !== null
        }
        t={t}
      />
    </div>
  )
}

export default RankingPool
