import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getMaintenanceListsSuccess,
  getMaintenanceListsFailed,
  GET_LIST_MAINTENANCE_START,
} from '../../actions/maintenance'
import { api } from '~/services/api'

/**
 * get list maintenance api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getMaintenanceListsApi = (props) => {
  var uri = `/maintenances`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetMaintenanceLists(action) {
  try {
    const response = yield call(getMaintenanceListsApi, action?.payload)
    yield put(getMaintenanceListsSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(getMaintenanceListsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list maintenance
 */
export default function* watchGetMaintenanceLists() {
  yield takeLatest(GET_LIST_MAINTENANCE_START, doGetMaintenanceLists)
}
