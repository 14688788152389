import PropTypes from 'prop-types'
import { Form, Modal, Button, Row, Col, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import { useUser } from '~/views/app/redux/hooks/useUser'
import { converSeed, converUserRankUser } from '~/common/constants'
import ModalDatabase from './database'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import { formatNumber } from '~/helpers/common'
function Achievement(props) {
  const { t } = useTranslation()
  const { isShowModal, onClose, id, address, currentCycle } = props
  const [form] = Form.useForm()
  const [menuOpen, setMenuOpen] = useState(false)
  const [keyOpenTournament, setKeyOpenTournament] = useState([])
  const [totalScore, setTotalScore] = useState([])
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
  })
  const onShowModalForm = () => {
    setModalForm({
      id,
      isOpenModal: true,
    })
  }
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
    })
  }
  const {
    actions,
    data: { userDetail, balancePoc, rankingAchievement },
  } = useUser()

  const filterRankerMatch = rankingAchievement?.filter(
    (item) => item?.battle_type === 1,
  )

  const matchWin = filterRankerMatch?.filter((item) =>
    item?.winner_user?.includes(address),
  )

  const refreshData = () => {
    actions.getDetailUser(id)
    const paramScoreSnapShot = {
      score_type: 'SECTOR',
      from_date: currentCycle?.timeStart,
      to_date: currentCycle?.timeEnd,
    }
    const paramBalancePoc = {
      address: address,
    }
    actions.getScoreSnapShotAddress(paramScoreSnapShot)
    actions.getBalacePoc(paramBalancePoc)
  }
  useEffect(() => {
    refreshData()
    const params = {
      wallet: address,
    }
    actions.getRankingAchievement(params)
  }, [isShowModal, id, address, currentCycle])

  const handleOpenChangeRanking = (keys) => {
    if (keys?.length > 0) {
      setMenuOpen(true)
    } else {
      setMenuOpen(false)
    }
  }

  const handleOpenChangeTournament = (keys) => {
    setKeyOpenTournament(keys)
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    const paramsScoreRankCycle = {
      user_wallets: [address],
    }
    actions.getListScoreAddress(paramsScoreRankCycle, (res) => {
      if (res) {
        setTotalScore(res?.data?.user_score)
      }
    })
  }, [address, id, currentCycle, isShowModal])
  const filterExplorationScore = totalScore?.filter(
    (item) => item.score_type === 0,
  )
  const filterRankedScore = totalScore?.filter((item) => item.score_type === 1)

  const totalExplorationScore = filterExplorationScore
    ?.reduce((sum, item) => sum + item.score_value, 0)
    .toFixed(0)

  const totalRankedScore = filterRankedScore
    ?.reduce((sum, item) => sum + item.score_value, 0)
    .toFixed(0)

  const total = (exploration_score, ranked_score) => {
    return (Number(ranked_score) + Number(exploration_score)).toLocaleString(
      'en-US',
    )
  }
  const ranking = [
    {
      value: 'ranking',
      key: 'ranking',
      label: 'Ranking',
      children: [
        {
          value: 'total_score',
          key: 'total_score',
          label: 'Total Score',
          total: total(totalRankedScore, totalExplorationScore),
        },
        {
          value: 'ranker_match',
          key: 'ranker_match',
          label: 'Ranker Match Score',
          total: Number(totalRankedScore).toLocaleString('en-US'),
        },
        {
          value: 'exploration',
          key: 'exploration',
          label: 'Exploration',
          total: Number(totalExplorationScore).toLocaleString('en-US'),
        },
        {
          value: 'ranker_match',
          key: 'ranker_match',
          label: '# of Ranker Match',
          total: filterRankerMatch?.length,
        },
        {
          value: 'win',
          key: 'win',
          label: 'Win',
          // total: matchWin.length,
          total:
            filterRankerMatch?.length > 0
              ? `${matchWin?.length}(${(
                  (matchWin?.length / filterRankerMatch?.length) *
                  100
                ).toFixed(0)}%)`
              : '0(0%)',
        },
        {
          value: 'lose',
          key: 'lose',
          label: 'Lose',
          // total: rankingAchievement.length - matchWin.length,
          total:
            filterRankerMatch?.length > 0
              ? `${filterRankerMatch?.length - matchWin?.length}(${(
                  ((filterRankerMatch?.length - matchWin?.length) /
                    filterRankerMatch?.length) *
                  100
                ).toFixed(0)}%)`
              : '0(0%)',
        },
      ],
    },
  ]

  const filterTournament =
    userDetail?.playerTournamentsData?.filter(
      (item) => item.is_tournament_won === true,
    ) || []

  const tournamentGroups = {
    S: ['3_4_S', '6_4_S', '9_4_S', '12_4_S'],
    A: ['3_4_A', '6_4_A', '9_4_A', '12_4_A'],
    B: ['3_4_B', '6_4_B', '9_4_B', '12_4_B'],
    C: ['3_4_C', '6_4_C', '9_4_C', '12_4_C'],
    D: ['3_4_D', '6_4_D', '9_4_D', '12_4_D'],
    E: ['3_4_E', '6_4_E', '9_4_E', '12_4_E'],
    F: ['3_4_F', '6_4_F', '9_4_F', '12_4_F'],
  }

  const totalTournamentCup = filterTournament.reduce(
    (acc, { tournament_id, is_tournament_won }) => {
      if (!acc[tournament_id]) acc[tournament_id] = []
      acc[tournament_id].push(is_tournament_won)
      return acc
    },
    {},
  )

  const calculateTotalLength = (tournamentIds) =>
    tournamentIds
      ?.map((id) => totalTournamentCup[id]?.length || 0)
      ?.reduce((sum, length) => sum + length, 0)

  const totalLengths = Object.keys(tournamentGroups).reduce((totals, key) => {
    totals[key] = calculateTotalLength(tournamentGroups[key])
    return totals
  }, {})

  const [totalTournament, setTotalTournament] = useState(0)
  const tournament = [
    {
      value: 'tournament',
      key: 'tournament',
      label: 'Tournament',
      children: [
        {
          value: 'count',
          key: 'count',
          label: 'Count',
          total: totalTournament,
        },
        {
          value: 's',
          key: 's',
          label: 'S',
          children: [
            {
              value: 'masters_cup',
              key: '1_4_S',
              label: 'Re.Masters Cup',
              total: totalTournamentCup['1_4_S']?.length || 0,
            },
            {
              value: 'monsters_grand_prix',
              key: '2_3_S',
              label: 'Monsters Grand Prix',
              total: totalTournamentCup['2_3_S']?.length || 0,
            },
            {
              value: 'official_competition',
              key: 'total_tournament_cup_S',
              label: 'Official Competition',
              total: totalLengths['S'] || 0,
            },
            {
              value: 'crown_cup',
              key: '5_3_S',
              label: 'Crown Cup',
              total: totalTournamentCup['5_3_S']?.length || 0,
            },
            {
              value: 'sanctuary_cup',
              key: '8_3_S',
              label: 'Sanctuary Cup',
              total: totalTournamentCup['8_3_S']?.length || 0,
            },
            {
              value: 'winners_cup',
              key: '11_3_S',
              label: 'Winners Cup',
              total: totalTournamentCup['11_3_S']?.length || 0,
            },
          ],
        },
        {
          value: 'a',
          key: 'a',
          label: 'A',
          children: [
            {
              value: 'fusion_cup',
              key: '2_2_A',
              label: 'Fusion Cup',
              total: totalTournamentCup['2_2_A']?.length || 0,
            },
            {
              value: 'official_competition',
              key: 'total_tournament_cup_A',
              label: 'Official Competition',
              total: totalLengths['A'] || 0,
            },
            {
              value: 'leaders_cup',
              key: '4_2_A',
              label: 'Leaders Cup',
              total: totalTournamentCup['4_2_A']?.length || 0,
            },
            {
              value: 'gold_cup',
              key: '5_2_A',
              label: 'Gold Cup',
              total: totalTournamentCup['5_2_A']?.length || 0,
            },
            {
              value: 'forcus_cup',
              key: '8_4_A',
              label: 'Forcus Cup',
              total: totalTournamentCup['8_4_A']?.length || 0,
            },
            {
              value: 'stamina_cup',
              key: '10_2_A',
              label: 'Stamina Cup',
              total: totalTournamentCup['10_2_A']?.length || 0,
            },
            {
              value: 'secret_cup',
              key: '11_3_A',
              label: 'Secret Cup',
              total: totalTournamentCup['11_3_A']?.length || 0,
            },
          ],
        },
        {
          value: 'b',
          key: 'b',
          label: 'B',
          children: [
            {
              value: 'strong_cup',
              key: '1_3_B',
              label: 'Strong Cup',
              total: totalTournamentCup['1_3_B']?.length || 0,
            },
            {
              value: 'moon_night_award',
              key: '2_3_B',
              label: 'Moon Night Award',
              total: totalTournamentCup['2_3_B']?.length || 0,
            },
            {
              value: 'official_competition',
              key: 'total_tournament_cup_B',
              label: 'Official Competition',
              total: totalLengths['B'] || 0,
            },
            {
              value: 'sun_rise_cup',
              key: '4_4_B',
              label: 'Sun Rise Cup',
              total: totalTournamentCup['4_4_B']?.length || 0,
            },
            {
              value: 'quick_cup',
              key: '7_3_B',
              label: 'Quick Cup',
              total: totalTournamentCup['7_3_B']?.length || 0,
            },
            {
              value: 'genius_cup',
              key: '9_1_B',
              label: 'Genius Cup',
              total: totalTournamentCup['9_1_B']?.length || 0,
            },
            {
              value: 'silver_cup',
              key: '10_4_B',
              label: 'Silver Cup',
              total: totalTournamentCup['10_4_B']?.length || 0,
            },
            {
              value: 'strudy_cup',
              key: '11_3_B',
              label: 'Strudy Cup',
              total: totalTournamentCup['11_3_B']?.length || 0,
            },
          ],
        },
        {
          value: 'c',
          key: 'c',
          label: 'C',
          children: [
            {
              value: 'energy_cup',
              key: '1_2_C',
              label: 'Energy Cup',
              total: totalTournamentCup['1_2_C']?.length || 0,
            },
            {
              value: 'fresh_cup',
              key: '3_1_C',
              label: 'Fresh Cup',
              total: totalTournamentCup['3_1_C']?.length || 0,
            },
            {
              value: 'official_competition',
              key: 'total_tournament_cup_C',
              label: 'Official Competition',
              total: totalLengths['C'] || 0,
            },
            {
              value: 'fairy_cup',
              key: '4_1_C',
              label: 'Fairy Cup',
              total: totalTournamentCup['4_1_C']?.length || 0,
            },
            {
              value: 'refresh_cup',
              key: '5_2_C',
              label: 'Refresh Cup',
              total: totalTournamentCup['5_2_C']?.length || 0,
            },
            {
              value: 'cheer_cup',
              key: '7_3_C',
              label: 'Cheer Cup',
              total: totalTournamentCup['7_3_C']?.length || 0,
            },
            {
              value: 'glory_cup',
              key: '10_3_C',
              label: 'Glory Cup',
              total: totalTournamentCup['10_3_C']?.length || 0,
            },
            {
              value: 'caramel_cup',
              key: '11_2_C',
              label: 'Caramel Cup',
              total: totalTournamentCup['11_2_C']?.length || 0,
            },
            {
              value: 'fullness_cup',
              key: '12_1_C',
              label: 'Fullness Cup',
              total: totalTournamentCup['12_1_C']?.length || 0,
            },
          ],
        },
        {
          value: 'd',
          key: 'd',
          label: 'D',
          children: [
            {
              value: 'snowdrop_cup',
              key: '2_4_D',
              label: 'Snowdrop Cup',
              total: totalTournamentCup['2_4_D']?.length || 0,
            },
            {
              value: 'sunny_cup',
              key: '3_2_D',
              label: 'Sunny Cup',
              total: totalTournamentCup['3_2_D']?.length || 0,
            },
            {
              value: 'official_competition',
              key: 'total_tournament_cup_D',
              label: 'Official Competition',
              total: totalLengths['D'] || 0,
            },
            {
              value: 'silent_cup',
              key: '4_3_D',
              label: 'Silent Cup',
              total: totalTournamentCup['4_3_D']?.length || 0,
            },
            {
              value: 'iris_cup',
              key: '5_4_D',
              label: 'Iris Cup',
              total: totalTournamentCup['5_4_D']?.length || 0,
            },
            {
              value: 'salvia_cup',
              key: '7_1_D',
              label: 'Salvia Cup',
              total: totalTournamentCup['7_1_D']?.length || 0,
            },
            {
              value: 'passion_cup',
              key: '8_3_D',
              label: 'Passion Cup',
              total: totalTournamentCup['8_3_D']?.length || 0,
            },
            {
              value: 'marigold_cup',
              key: '9_2_D',
              label: 'Marigold Cup',
              total: totalTournamentCup['9_2_D']?.length || 0,
            },
            {
              value: 'rose_cup',
              key: '10_2_D',
              label: 'Rose Cup',
              total: totalTournamentCup['10_2_D']?.length || 0,
            },
            {
              value: 'maple_cup',
              key: '11_1_D',
              label: 'Maple Cup',
              total: totalTournamentCup['11_1_D']?.length || 0,
            },
          ],
        },
        {
          value: 'e',
          key: 'e',
          label: 'E',
          children: [
            {
              value: 'narcissus_cup',
              key: '2_2_E',
              label: 'Narcissus Cup',
              total: totalTournamentCup['2_2_E']?.length || 0,
            },
            {
              value: 'spring_cup',
              key: '3_2_E',
              label: 'Spring Cup',
              total: totalTournamentCup['3_2_E']?.length || 0,
            },
            {
              value: 'official_competition',
              key: 'total_tournament_cup_E',
              label: 'Official Competition',
              total: totalLengths['E'] || 0,
            },
            {
              value: 'azalea_cup',
              key: '5_3_E',
              label: 'Azalea Cup',
              total: totalTournamentCup['5_3_E']?.length || 0,
            },
            {
              value: 'morning_cup',
              key: '6_2_E',
              label: 'Morning Cup',
              total: totalTournamentCup['6_2_E']?.length || 0,
            },
            {
              value: 'sunflower_cup',
              key: '7_2_E',
              label: 'Sunflower Cup',
              total: totalTournamentCup['7_2_E']?.length || 0,
            },
            {
              value: 'summer_cup',
              key: '8_1_E',
              label: 'Summer Cup',
              total: totalTournamentCup['8_1_E']?.length || 0,
            },
            {
              value: 'dahlia_cup',
              key: '9_2_E',
              label: 'Dahlia Cup',
              total: totalTournamentCup['9_2_E']?.length || 0,
            },
            {
              value: 'autumn_cup',
              key: '10_3_E',
              label: 'Autumn Cup',
              total: totalTournamentCup['10_3_E']?.length || 0,
            },
            {
              value: 'winter_cup',
              key: '12_2_E',
              label: 'Winter Cup',
              total: totalTournamentCup['12_2_E']?.length || 0,
            },
          ],
        },
        {
          value: 'f',
          key: 'f',
          label: 'F',
          children: [
            {
              value: 'new_year_cup',
              key: '1_4_F',
              label: 'New Year Cup',
              total: totalTournamentCup['1_4_F']?.length || 0,
            },
            {
              value: 'official_competition',
              key: 'total_tournament_cup_F',
              label: 'Official Competition',
              total: totalLengths['F'] || 0,
            },
            {
              value: 'beginners_cup',
              key: '4_2_F',
              label: 'Beginners Cup',
              total: totalTournamentCup['4_2_F']?.length || 0,
            },
            {
              value: 'newcomer_cup',
              key: '5_1_F',
              label: 'Newcomer Cup',
              total: totalTournamentCup['5_1_F']?.length || 0,
            },
            {
              value: 'rookie_cup',
              key: '6_1_F',
              label: 'Rookie Cup',
              total: totalTournamentCup['6_1_F']?.length || 0,
            },
            {
              value: 'novice_cup',
              key: '8_2_F',
              label: 'Novice Cup',
              total: totalTournamentCup['8_2_F']?.length || 0,
            },
            {
              value: 'trainee_cup',
              key: '9_1_F',
              label: 'Trainee Cup',
              total: totalTournamentCup['9_1_F']?.length || 0,
            },
            {
              value: 'apprentice_cup',
              key: '10_1_F',
              label: 'Apprentice Cup',
              total: totalTournamentCup['10_1_F']?.length || 0,
            },
            {
              value: 'junior_cup',
              key: '11_2_F',
              label: 'Junior Cup',
              total: totalTournamentCup['11_2_F']?.length || 0,
            },
          ],
        },
      ],
    },
  ]
  const totalByCategory = tournament[0]?.children?.reduce((acc, category) => {
    if (category.children) {
      const categoryTotal = category.children.reduce(
        (sum, cup) => sum + (cup.total || 0),
        0,
      )
      acc[category.label] = categoryTotal
    }
    return acc
  }, {})
  useEffect(() => {
    const grandTotal = Object.values(totalByCategory).reduce(
      (sum, total) => sum + total,
      0,
    )
    setTotalTournament(grandTotal)
  }, [totalByCategory])

  function calculateTotalsAchievement(data) {
    data.forEach((item) => {
      if (item.children && item.children?.length > 0) {
        item.total = item.children.reduce(
          (sum, child) => sum + (child.total || 0),
          0,
        )
        calculateTotalsAchievement(item.children)
      }
    })
  }
  calculateTotalsAchievement(tournament)

  const getTotalRank = (key) => {
    return tournament[0]?.children?.find((child) => child.key === key)?.total
  }
  const getColorRank = (value) => {
    switch (value) {
      case 's':
        return { color: '#F4AF3E' }
      case 'a':
        return { color: '#D064A5' }
      case 'b':
        return { color: '#722ED1' }
      case 'c':
        return { color: '#2F54EB' }
      case 'd':
        return { color: '#4AACD1' }
      case 'e':
        return { color: '#52C41A' }
      case 'f':
        return { color: '#8C8C8C' }
      default:
        return {}
    }
  }

  const renderMenus = (children) => {
    return children?.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu
            key={item.key}
            title={
              <Row>
                <Col span={12} style={getColorRank(item.key)}>
                  {item.label}
                </Col>
                <Col span={11} className="flex justify-end  ml-[2%]">
                  <div>{getTotalRank(item.key)}</div>

                  <img
                    className="ml-[5%]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/${
                      keyOpenTournament.includes(item.key)
                        ? 'up.svg'
                        : 'down.svg'
                    }`}
                    alt=""
                  />
                </Col>
              </Row>
            }
          >
            {renderMenus(item.children)}
          </Menu.SubMenu>
        )
      } else {
        return (
          <Menu.Item key={item.key} style={{ marginBottom: 10 }}>
            <Row>
              <Col span={20}>
                <span>{item.label}</span>
              </Col>
              <Col span={4}>
                <span className="flex justify-end">{item.total}</span>
              </Col>
            </Row>
          </Menu.Item>
        )
      }
    })
  }
  const formatPlayerTime = (minutes) => {
    // Kiểm tra nếu minutes không hợp lệ (undefined, null, NaN)
    if (!minutes || isNaN(minutes)) return '00h:00m'

    const days = Math.floor(minutes / (24 * 60))
    const remainingMinutes = minutes % (24 * 60)
    const hours = Math.floor(remainingMinutes / 60)
    const mins = remainingMinutes % 60

    return days > 0
      ? `${String(days).padStart(2, '0')}d:${String(hours).padStart(2, '0')}h`
      : `${String(hours).padStart(2, '0')}h:${String(mins).padStart(2, '0')}m`
  }

  const Obtained = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
    { id: 13 },
    { id: 14 },
    { id: 15 },
    { id: 16 },
    { id: 17 },
    { id: 18 },
    { id: 19 },
    { id: 20 },
    { id: 21 },
  ]
  const CountObtaining = Obtained?.map((item) => {
    return {
      id: item.id,
      total:
        userDetail?.numberOfSeedObtain?.find((child) => child._id === item.id)
          ?.total || 0,
    }
  })
  const handleExportToExcel = () => {
    const formattedData = [
      [
        'Player rank',
        converUserRankUser(userDetail?.playerGameProgress?.player_rank),
      ],
      [
        'Player Time',
        formatPlayerTime(userDetail?.user?.player_time) ?? '00h:00m',
      ],
      [
        'Total BIT',
        formatNumber(
          userDetail?.playerGameProgress?.total_currency_ingame_earned,
        ),
      ],
      ['Total MA Point', userDetail?.playerGameProgress?.total_mapoint_earned],
      ['Complete Daily Quest', balancePoc?.DAILY],
      ['Complete Weekly Quest', balancePoc?.WEEKLY],
      ['Monster Boost', userDetail?.playerGameProgress?.total_monster_boosted],
      ['Farm Boost', userDetail?.playerGameProgress?.total_farm_boosted],
      [
        'Training',
        userDetail?.playerGameProgress?.total_training_session_conducted,
      ],
      [
        'Intensive Training',
        userDetail?.playerGameProgress
          ?.total_intensive_traning_sessions_conducted,
      ],
      [
        'Exploration',
        userDetail?.playerGameProgress?.total_exploration_conducted,
      ],
      ['Items found', userDetail?.playerGameProgress?.total_item_founded],
      [
        'Max Monster Rank',
        userDetail?.playerGameProgress?.highest_monster_rank,
      ],
      [
        'Regenerations',
        formatNumber(
          userDetail?.playerGameProgress?.total_mint_monster_performed,
        ),
      ],
      ['Fusions', userDetail?.playerGameProgress?.total_fushion_completed],
      [
        'Crystallization',
        userDetail?.playerGameProgress?.total_monster_crystalized,
      ],
      ['Coach', userDetail?.playerGameProgress?.total_monster_coached],
      ['Monster Seed', `${userDetail?.monsterSeed}/142`],
      [
        'Total of times each seed is obtained',
        CountObtaining?.map(
          (item) => `ID: ${converSeed(item.id)}, Total: ${item.total}`,
        ).join('\n'),
      ],
    ]
    formattedData.push(['', ''])
    ranking?.forEach((rankItem) => {
      formattedData.push([rankItem.label, ''])

      rankItem.children?.forEach((child) => {
        formattedData.push([`  - ${child.label}`, child.total])
      })
    })

    formattedData.push(['', ''])
    tournament?.forEach((rankItem) => {
      formattedData.push([rankItem.label, ''])

      rankItem.children?.forEach((child) => {
        formattedData.push([`  - ${child.label}`, child.total])

        child.children?.forEach((subChild) => {
          formattedData.push([`  + ${subChild.label}`, subChild.total])
        })
      })
    })

    // Tạo sheet Excel
    const ws = XLSX.utils.aoa_to_sheet(formattedData)

    ws['!cols'] = [{ wch: 35 }, { wch: 25 }]

    // Tạo workbook và xuất file
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Achievement user')
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(data, 'Achievement_user.xlsx')
  }

  return (
    <Modal
      title={<>{t('managementUsers.form.achievement')}</>}
      onCancel={handleClose}
      open={isShowModal}
      width={800}
      centered
      destroyOnClose
      footer={
        <div className="flex justify-end ">
          <Button
            style={{ backgroundColor: '#1890FF' }}
            onClick={handleExportToExcel}
          >
            Export Achievement
          </Button>
        </div>
      }
    >
      <div
        className="font-normal achievement"
        style={{
          maxHeight: '600px',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Row gutter={24} style={{ fontFamily: 'Rexliafree' }}>
          <Col span={12}>
            {/* Player */}
            <div
              style={{
                background: '#0D2758',
                padding: '12px',
                borderRadius: '5px',
                lineHeight: '24px',
                marginBottom: '20px',
              }}
            >
              <Row className="mb-3">
                <Col
                  span={12}
                  className="flex"
                  style={{ alignItems: 'center' }}
                >
                  <img
                    className="w-[32px] h-[32px]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/mdi_user.svg`}
                    alt=""
                  />
                  <div className="text-[20px] ml-2">Player</div>
                </Col>
              </Row>
              <Row>
                <Col span={16}>Player Rank</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {converUserRankUser(
                    userDetail?.playerGameProgress?.player_rank,
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={16}>Player Time</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {formatPlayerTime(userDetail?.user?.player_time) ?? '00h:00m'}
                </Col>
              </Row>
              <Row>
                <Col span={16}>Total BIT</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {formatNumber(
                    userDetail?.playerGameProgress
                      ?.total_currency_ingame_earned,
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={16}>Total MA Point</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {userDetail?.playerGameProgress?.total_mapoint_earned}
                </Col>
              </Row>
              <Row>
                <Col span={16}>Complete Daily Quest</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {balancePoc?.DAILY}
                </Col>
              </Row>
              <Row>
                <Col span={16}>Complete Weekly Quest</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {balancePoc?.WEEKLY}
                </Col>
              </Row>
            </div>
            {/* Raise */}
            <div
              style={{
                background: '#0D2758',
                padding: '12px',
                borderRadius: '5px',
                lineHeight: '24px',
                marginBottom: '20px',
              }}
            >
              <Row className="mb-3">
                <Col
                  span={12}
                  className="flex"
                  style={{ alignItems: 'center' }}
                >
                  <img
                    className="w-[32px] h-[32px]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/raise.svg`}
                    alt=""
                  />
                  <div className="text-[20px] ml-2">Raise</div>
                </Col>
              </Row>
              <Row>
                <Col span={16}>Monster Boost</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {userDetail?.playerGameProgress?.total_monster_boosted}
                </Col>
              </Row>
              <Row>
                <Col span={16}>Farm Boost</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {userDetail?.playerGameProgress?.total_farm_boosted}
                </Col>
              </Row>
              <Row>
                <Col span={16}>Training</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {
                    userDetail?.playerGameProgress
                      ?.total_training_session_conducted
                  }
                </Col>
              </Row>
              <Row>
                <Col span={16}>Intensive Training</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {
                    userDetail?.playerGameProgress
                      ?.total_intensive_traning_sessions_conducted
                  }
                </Col>
              </Row>
              <Row>
                <Col span={16}>Exploration </Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {userDetail?.playerGameProgress?.total_exploration_conducted}
                </Col>
              </Row>
              <Row>
                <Col span={16}>Items found</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {userDetail?.playerGameProgress?.total_item_founded}
                </Col>
              </Row>
            </div>
          </Col>
          {/* Monster */}
          <Col span={12}>
            <div
              style={{
                background: '#0D2758',
                padding: '12px',
                borderRadius: '5px',
                lineHeight: '24px',
                marginBottom: '20px',
                height: '96%',
              }}
            >
              <Row className="mb-3">
                <Col
                  span={12}
                  className="flex"
                  style={{ alignItems: 'center' }}
                >
                  <img
                    className="w-[32px] h-[32px]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/monster.svg`}
                    alt=""
                  />
                  <div className="text-[20px] ml-2">Monster</div>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Button
                    style={{
                      fontSize: '12px',
                      background: '#1890FF',
                      borderRadius: '10px',
                    }}
                    disabled={userDetail?.monsterSeed === 0 ? true : false}
                    onClick={() => onShowModalForm()}
                  >
                    Database
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={16}>Max Monster Rank</Col>
                <Col
                  span={8}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <img
                    className="w-[25px] h-[25px]"
                    src={`${process.env.PUBLIC_URL}/svgs/formProfile/${userDetail?.playerGameProgress?.highest_monster_rank}.svg`}
                    alt=""
                  />
                </Col>
              </Row>
              <Row>
                <Col span={16}>Regenerations</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {formatNumber(
                    userDetail?.playerGameProgress
                      ?.total_mint_monster_performed,
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={16}>Fusions</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {userDetail?.playerGameProgress?.total_fushion_completed}
                </Col>
              </Row>
              <Row>
                <Col span={16}>Crystallization</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {userDetail?.playerGameProgress?.total_monster_crystalized}
                </Col>
              </Row>
              <Row>
                <Col span={16}>Coach</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {userDetail?.playerGameProgress?.total_monster_coached}
                </Col>
              </Row>
              <Row>
                <Col span={16}>Monster Seed</Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  {userDetail?.monsterSeed}/142
                </Col>
              </Row>
              <div className="text-[12px] mt-[16px]">
                total of times each seed is obtained
              </div>

              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(7,40px)',
                  gridAutoRows: '40px',
                  gap: '10px',
                  marginTop: '20px',
                }}
              >
                {CountObtaining?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      width: '40px',
                      height: '40px',
                      position: 'relative',
                      backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/seed/${item.id}.png)`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      }}
                    />
                    <span
                      style={{
                        position: 'relative',
                        color: 'white',
                        zIndex: 1,
                      }}
                    >
                      {item.total}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <div
          className="rankingAchievement"
          style={{
            fontFamily: 'Rexliafree',
            background: '#0D2758',
            lineHeight: '24px',
            borderRadius: '5px',
            marginBottom: '20px',
          }}
        >
          <Menu mode="inline" onOpenChange={handleOpenChangeRanking}>
            {ranking?.map((item) => (
              <Menu.SubMenu
                key={item.key}
                title={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      className="w-[24px] h-[24px]"
                      src={`${process.env.PUBLIC_URL}/svgs/formProfile/ranking.svg`}
                      alt=""
                      style={{ marginRight: '8px' }}
                    />
                    <div className="text-[20px]">{item.label}</div>
                    <img
                      className="w-[32px] h-[32px] ml-[77%]"
                      src={`${process.env.PUBLIC_URL}/svgs/formProfile/${
                        menuOpen ? 'up.svg' : 'down.svg'
                      }`}
                      alt=""
                    />
                  </span>
                }
              >
                {renderMenus(item.children)}
              </Menu.SubMenu>
            ))}
          </Menu>
        </div>
        <div
          className="rankingAchievement"
          style={{
            fontFamily: 'Rexliafree',
            background: '#0D2758',
            lineHeight: '24px',
            borderRadius: '5px',
          }}
        >
          <Menu mode="inline" onOpenChange={handleOpenChangeTournament}>
            {tournament?.map((item) => (
              <Menu.SubMenu
                key={item.key}
                title={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      className="w-[24px] h-[24px]"
                      src={`${process.env.PUBLIC_URL}/svgs/formProfile/tournament.svg`}
                      alt=""
                      style={{ marginRight: '8px' }}
                    />
                    <div className="text-[20px]">{item.label}</div>
                    <img
                      className="w-[32px] h-[32px] ml-[71%]"
                      src={`${process.env.PUBLIC_URL}/svgs/formProfile/${
                        keyOpenTournament.includes(item.key)
                          ? 'up.svg'
                          : 'down.svg'
                      }`}
                      alt=""
                    />
                  </span>
                }
              >
                {renderMenus(item.children)}
              </Menu.SubMenu>
            ))}
          </Menu>
        </div>
      </div>
      {modalForm.isOpenModal && (
        <ModalDatabase
          totalSeed={userDetail?.monsterSeed}
          address={address}
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
        />
      )}
    </Modal>
  )
}

Achievement.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default Achievement
