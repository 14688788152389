import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import {
  Form,
  Modal,
  Button,
  notification,
  DatePicker,
  Row,
  Col,
  Select,
} from 'antd'
import React, { useEffect } from 'react'
import 'react-quill/dist/quill.snow.css'
import {
  NOTIFICATION_DURATION,
  cancelButtonStyle,
  okButtonStyle,
} from '~/common/constants'
import TextArea from 'antd/es/input/TextArea'
import moment from 'moment'
import dayjs from 'dayjs'
import axios from 'axios'
import { useMaintenances } from '~/views/app/redux/hooks/useMaintenance'

function MainteanceForm(props) {
  const { t } = useTranslation()
  const { id, isShowModal, onClose, refreshData, maintenance, checkPause } =
    props

  const [form] = Form.useForm()
  const {
    actions,
    data: { isLoading },
  } = useMaintenances()

  const handleSubmit = (values) => {
    const dataToSubmit = {
      ...values,
      time_start: checkPause
        ? maintenance?.time_start
        : values.time_start
        ? values.time_start.utc().format('YYYY-MM-DD HH:mm:ss')
        : null,
      time_end: checkPause
        ? moment().utc().add(60, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        : values.time_end
        ? values.time_end.utc().format('YYYY-MM-DD HH:mm:ss')
        : null,
      type: values.type,
      note: checkPause ? maintenance?.note : values.note,
    }

    const authToken = localStorage.getItem('auth_token')
    if (id) {
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/maintenances/${id}`,
          dataToSubmit,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
          },
        )
        .then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            onSuccess()
            refreshData()
          }
        })
        .catch((error) => {
          onClose()
          refreshData()
          notification.error({
            message: error?.response?.data?.message,
            duration: NOTIFICATION_DURATION,
          })
        })
    } else {
      actions?.addMaintenance(dataToSubmit, (res) => {
        if (res?.statusCode !== 400) {
          onSuccess()
          refreshData()
        }
      })
    }
  }
  const onSuccess = () => {
    notification.success({
      message: id
        ? checkPause
          ? t('common.message.pauseSuccessfully')
          : t('common.message.updateSuccessfully')
        : t('common.message.createSuccessfully'),
      duration: NOTIFICATION_DURATION,
    })
    onClose()
    refreshData()
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    if (id) {
      const formDetailData = {
        note: maintenance?.note,
        time_start: maintenance?.time_start
          ? dayjs(maintenance.time_start).local()
          : null,
        type: maintenance?.type,
        time_end: maintenance?.time_end
          ? dayjs(maintenance.time_end).local()
          : null,
      }
      form.setFieldsValue(formDetailData)
    }
  }, [id])

  const rules = {
    note: [
      {
        required: true,
        message: t('validate.required', {
          0: t('ManagementMainteance.form.note'),
        }),
      },
    ],
    type: [
      {
        required: true,
        message: t('validate.required', {
          0: t('ManagementMainteance.form.type'),
        }),
      },
    ],
    timeStart: [
      {
        required: true,
        message: t('validate.required', {
          0: t('ManagementMainteance.form.timeStart'),
        }),
      },
    ],
    timeEnd: [
      {
        required: true,
        message: t('validate.required', {
          0: t('ManagementMainteance.form.timeEnd'),
        }),
      },
    ],
  }

  useEffect(() => {
    if (isShowModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isShowModal])
  const timeStartValue = Form.useWatch('time_start', form)
  useEffect(() => {
    if (timeStartValue && !id) {
      form.setFieldsValue({ time_end: null })
    }
    if (timeStartValue && id) {
      const timeEndValue = maintenance?.time_end
        ? dayjs(maintenance?.time_end)
        : null
      if (timeEndValue && dayjs(timeStartValue).isAfter(timeEndValue)) {
        form.setFieldsValue({ time_end: null })
      } else {
        form.setFieldsValue({ time_end: timeEndValue })
      }
    }
  }, [timeStartValue])

  return (
    <Modal
      title={
        id
          ? checkPause
            ? t('ManagementMainteance.modal.pause')
            : t('ManagementMainteance.modal.editTitle')
          : t('ManagementMainteance.modal.addTitle')
      }
      onCancel={handleClose}
      open={isShowModal}
      width={checkPause ? 500 : 1000}
      centered
      destroyOnClose
      footer={null}
      // getContainer={false}
      maskClosable={false}
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        translate="yes"
        autoComplete="off"
        size="middle"
        validateTrigger={['onBlur', 'onChange']}
      >
        {checkPause ? (
          <div className="text-center text-[16px] mb-3">
            {t('ManagementMainteance.modal.pauseMessage', {
              time: moment().add(60, 'seconds').format('YYYY-MM-DD HH:mm:ss'),
            })}
          </div>
        ) : (
          <>
            <Form.Item
              label={t('ManagementMainteance.form.note')}
              name="note"
              rules={rules.note}
              labelAlign="left"
            >
              <TextArea placeholder={t('ManagementMainteance.form.note')} />
            </Form.Item>
            <Form.Item
              label={t('ManagementMainteance.form.type')}
              name="type"
              rules={rules.type}
              labelAlign="left"
            >
              <Select
                options={[
                  { value: 'GAME ', label: 'Game' },
                  { value: 'MARKETPLACE ', label: 'Marketplace' },
                  { value: 'ALL', label: 'All' },
                ]}
                placeholder={t('ManagementMainteance.form.type')}
              />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={t('ManagementMainteance.form.timeStart')}
                  name="time_start"
                  rules={rules.timeStart}
                  labelAlign="left"
                >
                  <DatePicker
                    showTime={{ format: 'HH:mm:ss' }}
                    format="YYYY-MM-DD HH:mm:ss"
                    className="dateRanking"
                    style={{
                      background: '#fff',
                      width: '100%',
                      height: '43px',
                    }}
                    placeholder={t('ManagementMainteance.form.timeStart')}
                    disabledDate={(current) =>
                      current && current < moment().startOf('day')
                    }
                    disabledTime={(selectedDate) => {
                      if (
                        selectedDate &&
                        selectedDate.isSame(moment(), 'day')
                      ) {
                        return {
                          disabledHours: () => [
                            ...Array(moment().hour()).keys(),
                          ],
                          disabledMinutes: (hour) =>
                            hour === moment().hour()
                              ? [...Array(moment().minute()).keys()]
                              : [],
                          disabledSeconds: (hour, minute) =>
                            hour === moment().hour() &&
                            minute === moment().minute()
                              ? [...Array(moment().second()).keys()]
                              : [],
                        }
                      }
                      return {}
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t('ManagementMainteance.form.timeEnd')}
                  name="time_end"
                  rules={rules.timeEnd}
                  labelAlign="left"
                >
                  <DatePicker
                    showTime={{ format: 'HH:mm:ss' }}
                    format="YYYY-MM-DD HH:mm:ss"
                    className="dateRanking"
                    style={{
                      background: '#fff',
                      width: '100%',
                      height: '43px',
                    }}
                    placeholder={t('ManagementMainteance.form.timeEnd')}
                    disabledDate={(current) => {
                      const timeStartValue = form.getFieldValue('time_start')
                      return timeStartValue
                        ? current.isBefore(timeStartValue, 'day')
                        : current.isBefore(moment().startOf('day'))
                    }}
                    disabledTime={(selectedDate) => {
                      const timeStartValue = form.getFieldValue('time_start')

                      if (timeStartValue && selectedDate) {
                        const isSameDay = selectedDate.isSame(
                          timeStartValue,
                          'day',
                        )

                        if (isSameDay) {
                          return {
                            disabledHours: () =>
                              Array(timeStartValue.hour())
                                .fill(0)
                                .map((_, i) => i),
                            disabledMinutes: (hour) =>
                              hour === timeStartValue.hour()
                                ? Array(timeStartValue.minute())
                                    .fill(0)
                                    .map((_, i) => i)
                                : [],
                            disabledSeconds: (hour, minute) =>
                              hour === timeStartValue.hour() &&
                              minute === timeStartValue.minute()
                                ? Array(timeStartValue.second() + 1)
                                    .fill(0)
                                    .map((_, i) => i)
                                : [],
                          }
                        }
                      }
                      return {}
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        <div key="buttons" className="flex justify-center">
          <Button key="cancel" onClick={handleClose} style={cancelButtonStyle}>
            {t('common.btn.cancel')}
          </Button>
          <Button
            className="border-hidden"
            key="ok"
            htmlType="submit"
            type="primary"
            style={okButtonStyle}
            disabled={isLoading}
            loading={isLoading}
          >
            {id
              ? checkPause
                ? t('common.btn.pause')
                : t('common.btn.update')
              : t('common.btn.add')}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

MainteanceForm.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default MainteanceForm
