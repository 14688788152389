import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Dropdown, Form, Input, Row, Space, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import TimeHelper from '~/helpers/time-helper'
import UserForm from './form'
import { useAdmin } from '../../../redux/hooks/useAdmin'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { formatAddress } from '~/helpers/common'
import configs from '~/configs'
import DeleteCommon from '~/common/hooks/useDelete'
import { handleExportCSVScreen } from '~/common/constants'

const ManagementAdmins = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [idSelected, setIdSelected] = useState([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [isModalOpenDelete, setIsModalOpenDelete] = useState({
    isOpenModal: false,
    user: {},
  })

  const {
    data: { user },
  } = useTheme()

  const ADMIN_ROLE = [
    // {
    //   key: '0',
    //   value: 'ranking',
    //   label: t(`managementUsers.roleUser.ranking`),
    // },
    // {
    //   key: '1',
    //   value: 'lastActive',
    //   label: t(`managementUsers.roleUser.lastActive`),
    // },
    {
      key: '2',
      value: 'crt_dt',
      label: t(`managementUsers.roleUser.createdAt`),
    },
  ]
  const [role, setRole] = useState(ADMIN_ROLE[0])
  const {
    actions,
    data: { isLoading, listAdmin },
  } = useAdmin()
  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  //Control all modal
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    user: {},
  })

  const showModalDelete = (content, idArray) => {
    setIsModalOpenDelete({
      content: content,
      idArray: idArray,
      isOpenModal: true,
    })
  }
  const handleCancelDelete = () => {
    setIsModalOpenDelete({
      content: null,
      idArray: [],
      isOpenModal: false,
    })
  }

  const columns = [
    {
      title: t('common.no'),
      dataIndex: 'key',
      key: 'key',
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementUsers.form.name'),
      dataIndex: 'user_name',
      key: 'user_name',
      render: (user_name) => {
        return <div className="">{user_name}</div>
      },
    },
    {
      title: t('managementUsers.form.walletAddress'),
      dataIndex: 'address_wallet',
      key: 'address_wallet',
      render: (address_wallet) => {
        let link = `${configs.NETWORK.scan}address/${address_wallet}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={address_wallet}
            style={{ color: '#40A9FF' }}
          >
            {formatAddress(address_wallet)}
          </a>
        )
      },
    },
    {
      title: t('managementUsers.form.createdAt'),
      dataIndex: 'crt_dt',
      key: 'crt_dt',
      render: (crt_dt) => {
        return TimeHelper(crt_dt)
      },
    },
    {
      title: t('managementUsers.form.updatedAt'),
      dataIndex: 'mod_dt',
      key: 'mod_dt',
      render: (mod_dt) => {
        return TimeHelper(mod_dt)
      },
    },
    {
      title: t('managementUsers.form.role'),
      dataIndex: 'role',
      key: 'role',
      render: (role) => {
        if (role === 'admin')
          return <Tag color="#1890FF">{t(`managementUsers.role.${role}`)}</Tag>
        else
          return (
            <Tag color="#00000073">{t(`managementUsers.role.${role}`)}</Tag>
          )
      },
    },
    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={<EditFilled style={{ color: '#36CFC9' }} />}
              onClick={() => onShowModalForm(data?._id, data)}
            />

            <Button
              type="ghost"
              loading={false}
              disabled={data?.address_wallet === user?.address_wallet}
              onClick={() => {
                // if (!openDelete) {
                //   onRemove([
                //     {
                //       user_name: data?.user_name,
                //       id: data?._id,
                //     },
                //   ])
                // }
                onRemove([
                  {
                    user_name: data?.user_name,
                    id: data?._id,
                  },
                ])
              }}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
            />
          </Space>
        )
      },
    },
  ]

  const rowClassName = (record) => {
    return record?.address_wallet === user?.address_wallet
      ? 'highlight-row'
      : ''
  }

  // On select User
  const onSelectChange = (itemsSelected, record) => {
    setSelectedRowKeys(itemsSelected)
    const idsPicked = record
    setIdSelected(idsPicked)
  }

  //Refresh data
  const params = {
    search: keyword,
    pageSize: pageSize,
    role: 'admin',
    page,
    sort: 'asc',
  }

  const refreshData = () => {
    actions.getListAdmin(params)
  }

  // Handle delete
  const onRemove = (data) => {
    let idArray = []
    let contentArray = ''

    if (data) {
      idArray = [data[0]?.id]
      contentArray = data[0]?.user_name
    } else {
      idArray = idSelected.map((item) => item._id)
      contentArray = idSelected.map((item) => item.user_name)
    }
    if (idArray.length > 0) {
      showModalDelete(
        t('cancel_modal.question', { question: contentArray.toString() }),
        idArray,
      )
    }
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword, role])

  // Handle open modal (create-edit-delete)
  const onShowModalForm = (id, user) => {
    setModalForm({
      id,
      isOpenModal: true,
      user: user,
    })
  }

  // Handle close modal (create-edit-delete)
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      user: {},
    })
  }

  useEffect(() => {
    // Reset row selected when keyword change
    setSelectedRowKeys([])
    setIdSelected([])
  }, [keyword])

  const handleChangeRole = (e) => {
    setRole(ADMIN_ROLE[e.key])
  }
  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }
  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Space className="d-flex justify-content-between justify-content-sm-start">
            <Button
              type="primary"
              onClick={() => {
                onRemove()
              }}
              disabled={!selectedRowKeys.length > 0 || isDeleting}
              loading={isDeleting}
              danger
            >
              {`${t('common.btn.delete')} ${
                selectedRowKeys.length ? `(${selectedRowKeys.length})` : ''
              }`}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                onShowModalForm(null, {})
              }}
            >
              {t('common.btn.addNew')}
            </Button>
            <Button
              style={{ backgroundColor: '#1890FF' }}
              onClick={() =>
                handleExportCSVScreen('ADMINS', params, 'Admins_Management')
              }
            >
              Export to CSV
            </Button>
          </Space>
        </Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <Form
                onFinish={handleSearch}
                className="w-full sm:w-auto"
                form={formSearch}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {t('common.search')}
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Input className="w-full" placeholder={t('common.search')} />
                </Form.Item>
              </Form>

              <div className="flex items-baseline">
                <div className="mr-2 font__Rexliafree">
                  {t('managementUsers.sortBy')}
                </div>
                <Dropdown
                  className="flex items-center cursor-pointer ml-3 sort__value"
                  menu={{
                    items: ADMIN_ROLE,
                    selectable: true,
                    onClick: handleChangeRole,
                  }}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <Button className="flex !bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[160px]">
                    <div className="title">{role?.label}</div>
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listAdmin}
        rowClassName={rowClassName}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
          preserveSelectedRowKeys: true,
          getCheckboxProps: (record) => ({
            disabled: record?.address_wallet === user?.address_wallet,
            address_wallet: record?.address_wallet,
          }),
        }}
        loading={isLoading}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: listAdmin?.length,
          keyword,
          setKeyword,
        }}
      />

      {modalForm.isOpenModal && (
        <UserForm
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          refreshData={refreshData}
          id={modalForm.id}
          user={modalForm?.user}
        />
      )}

      <DeleteCommon
        action={{
          isModalOpen: isModalOpenDelete.isOpenModal,
          handleCancel: handleCancelDelete,
        }}
        user={user}
        content={isModalOpenDelete.content}
        idArray={isModalOpenDelete.idArray}
        setIdSelected={setIdSelected}
        setIsDeleting={setIsDeleting}
        setSelectedRowKeys={setSelectedRowKeys}
        refreshData={refreshData}
        setPage={setPage}
        setPageSize={setPageSize}
        t={t}
      />
    </div>
  )
}

export default ManagementAdmins
