import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import {
  Form,
  Modal,
  Input,
  Button,
  notification,
  Select,
  DatePicker,
  Upload,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import {
  NOTIFICATION_DURATION,
  cancelButtonStyle,
  okButtonStyle,
} from '~/common/constants'
import TextArea from 'antd/es/input/TextArea'
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { formatAddress } from '~/helpers/common'
import { isAddress } from 'ethers/lib/utils'
import moment from 'moment'
import dayjs from 'dayjs'
import { useNotifications } from '~/views/app/redux/hooks/useNotification'
import MDEditor from '@uiw/react-md-editor'
import axios from 'axios'

function NotificationForm(props) {
  const { t } = useTranslation()
  const [listAddress, setListAddress] = useState([])
  const [selectedAddress, setSelecteAddress] = useState(null)
  const [content, setContent] = useState(null)
  const [title, setTitle] = useState(null)
  const [title_jp, setTitle_jp] = useState(null)
  const [content_jp, setContent_jp] = useState(null)
  const [checkContentJP, setCheckContentJP] = useState(null)

  const { id, isShowModal, onClose, refreshData, noti, address_wallet_key } =
    props
  const [form] = Form.useForm()
  const [btnLang, setBtnLang] = useState('ENG')
  const [formAddress] = Form.useForm()
  const {
    actions,
    data: { isLoading },
  } = useNotifications()

  const handleSubmit = (values) => {
    if (btnLang === 'ENG' && !title_jp && !content_jp) {
      setCheckContentJP('JP')
      return
    }
    if (btnLang === 'JP' && !title && !content) {
      setCheckContentJP('ENG')
      return
    }
    const addressesValue = listAddress.map((item) => item.to)
    const parseMarkdownContent = (content) => {
      const lines = content?.split('\n')
      const formattedContent = []
      let isBlockCode = false
      let blockCodeLines = []

      lines?.forEach((line) => {
        if (line.startsWith('![Image](')) {
          const imageUrl = line.match(/!\[Image\]\((.*?)\)/)?.[1]
          if (imageUrl) {
            formattedContent?.push({ image: imageUrl })
          }
        } else if (line.startsWith('|')) {
          formattedContent?.push({ table: line.trim() })
        } else if (line.startsWith('```')) {
          if (isBlockCode) {
            blockCodeLines?.push(line.trim())
            formattedContent?.push({ block_code: blockCodeLines?.join('\n') })
            blockCodeLines = []
            isBlockCode = false
          } else {
            isBlockCode = true
            blockCodeLines?.push(line.trim())
          }
        } else if (isBlockCode) {
          blockCodeLines?.push(line.trim())
        } else if (/`([^`]+)`/.test(line)) {
          formattedContent?.push({ inline_code: line.trim() })
        } else if (line.startsWith('<!--')) {
          formattedContent?.push({ comment: line.trim() })
        } else if (line.startsWith('>')) {
          formattedContent?.push({ quote: line.trim() })
        } else if (line.trim()) {
          formattedContent?.push({ md: line.trim() })
        }
      })

      return formattedContent
    }

    const dataToSubmit = {
      ...values,
      is_all: selectedAddress === 'Specified Addresses' ? false : true,
      notification_date: values.notification_date
        ? values.notification_date.utc().format('YYYY-MM-DD HH:mm:ss')
        : null,
      address_wallets: addressesValue,
      title: title,
      title_jp: title_jp,
      content_in_game: parseMarkdownContent(content),
      content_in_game_jp: parseMarkdownContent(content_jp),
      content: content,
      content_jp: content_jp,
    }

    if (
      selectedAddress === 'Specified Addresses' &&
      addressesValue.length === 0
    ) {
      notification.error({
        message: 'Specified address is empty!',
        duration: NOTIFICATION_DURATION,
      })
      return
    }
    const authToken = localStorage.getItem('auth_token')
    if (id) {
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/notifications/${id}`,
          dataToSubmit,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
          },
        )
        .then((res) => {
          if (res?.status === 200 || res?.status === 201) {
            onSuccess()
            refreshData()
          }
        })
        .catch((error) => {
          onClose()
          refreshData()
          notification.error({
            message: error?.response?.data?.message,
            duration: NOTIFICATION_DURATION,
          })
        })
    } else {
      actions?.addNotification(dataToSubmit, (res) => {
        if (res?.statusCode !== 400) {
          onSuccess()
          refreshData()
        }
      })
    }
  }
  const onSuccess = () => {
    notification.success({
      message: id
        ? t('common.message.updateSuccessfully')
        : t('common.message.createSuccessfully'),
      duration: NOTIFICATION_DURATION,
    })
    onClose()
    refreshData()
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    if (address_wallet_key) {
      const formDetailData = {
        address_wallet: address_wallet_key,
      }
      form.setFieldsValue(formDetailData)
    }
  }, [address_wallet_key])

  useEffect(() => {
    if (id) {
      const formDetailData = {
        title: noti?.title,
        title_jp: noti?.title_jp,
        content: noti?.content,
        content_jp: noti?.content_jp,
        notification_date: noti?.notification_date
          ? dayjs(noti.notification_date).local()
          : null,
      }
      setTitle(noti?.title)
      setTitle_jp(noti?.title_jp)
      setContent(noti?.content)
      setContent_jp(noti?.content_jp)

      const addressType = noti?.is_all
        ? 'All User Addresses'
        : 'Specified Addresses'
      formAddress.setFieldsValue({ address: addressType })
      setSelecteAddress(addressType)
      const formattedAddresses = (noti?.address_wallets || []).map(
        (wallet) => ({
          address: addressType,
          to: wallet,
        }),
      )
      setListAddress(formattedAddresses)
      form.setFieldsValue(formDetailData)
    }
  }, [id])

  const handleAddAddress = (values) => {
    const addresses = values?.to?.split(/,|\n/).map((address) => address.trim())
    const uniqueAddresses = []

    addresses.forEach((toAddress) => {
      if (
        toAddress &&
        !uniqueAddresses.includes(toAddress) &&
        !listAddress?.some((item) => item.to === toAddress)
      ) {
        const formData = {
          address: values?.address,
          to: toAddress,
        }
        uniqueAddresses.push(toAddress)
        setListAddress((old) => [...old, formData])
      }
    })

    formAddress.resetFields(['to'])
  }
  const handleAddressChange = (value) => {
    setSelecteAddress(value)
    formAddress.resetFields(['to'])
    setListAddress([])
  }
  const optionToAddress = [
    {
      value: 'All User Addresses',
      label: 'All User Addresses',
    },
    {
      value: 'Specified Addresses',
      label: 'Specified Addresses',
    },
  ]
  const rules = {
    to: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementGift.toAddress'),
        }),
      },
      {
        validator(rule, value) {
          return new Promise((resolve, reject) => {
            if (!value) {
              resolve()
            } else {
              const addresses = value
                .split(/,|\n/)
                .map((address) => address.trim())
              for (let address of addresses) {
                if (!isAddress(address)) {
                  reject(t('validate.walletAddress'))
                  return
                }
              }
              resolve()
            }
          })
        },
      },
    ],
    address: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementNotification.toAddress'),
        }),
      },
    ],
    title: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementNotification.form.title'),
        }),
      },
      {
        validator: (_, value) => {
          if (!value) return Promise.resolve()
          const emojiRegex = /[\p{Extended_Pictographic}]/u

          if (value.length > 19) {
            return Promise.reject(t('managementNotification.form.maxTitle'))
          }
          if (emojiRegex.test(value)) {
            return Promise.reject(t('managementNotification.form.noEmoji'))
          }
          return Promise.resolve()
        },
      },
    ],

    content: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementNotification.form.content'),
        }),
      },
    ],
    notification_date: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementNotification.form.notification_date'),
        }),
      },
    ],
  }

  const handleUploadImage = async (file) => {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']
    const maxSize = 10 * 1024 * 1024

    if (!allowedTypes.includes(file.type)) {
      message.error(t('managementNotification.form.validateImage'))
      return
    }

    if (file.size > maxSize) {
      message.error(t('managementNotification.form.validateUpload'))
      return
    }
    const formData = new FormData()
    formData.append('file', file)
    const token = localStorage.getItem('auth_token_game')

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_GAME}/api/user/upload-event-image`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      const imageUrl = response.data.data
      if (imageUrl) {
        const markdownImage = `![Image](${imageUrl})`
        if (btnLang === 'ENG') {
          setContent((prevContent) => `${prevContent}\n${markdownImage}`)
          form.setFieldsValue({
            content: `${form.getFieldValue('content')}\n${markdownImage}`,
          })
        } else {
          setContent_jp((prevContent) => `${prevContent}\n${markdownImage}`)
          form.setFieldsValue({
            content_jp: `${form.getFieldValue('content_jp')}\n${markdownImage}`,
          })
        }
      } else {
        message.error('Upload photo failed!')
      }
    } catch (error) {
      message.error('Error uploading image!')
    }
  }
  useEffect(() => {
    if (isShowModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isShowModal])

  return (
    <Modal
      title={
        id
          ? t('managementNotification.modal.editTitle')
          : t('managementNotification.modal.addTitle')
      }
      onCancel={handleClose}
      open={isShowModal}
      width={1000}
      centered
      destroyOnClose
      footer={null}
      // getContainer={false}
      maskClosable={false}
    >
      <div className="flex justify-start  h-[70px] ">
        <div>
          <Button
            onClick={() => {
              setBtnLang('ENG')
              setCheckContentJP(null)
            }}
            style={{
              background:
                btnLang === 'ENG'
                  ? '#1890FF'
                  : checkContentJP === 'ENG'
                  ? '#ff4d4f'
                  : 'rgba(0, 0, 0, 0.45)',
              marginRight: '25px',
            }}
          >
            ENG
          </Button>
          {checkContentJP === 'ENG' && (
            <span className="text-[#ff4d4f]">
              {t('managementNotification.modal.required_input')}
            </span>
          )}
        </div>
        <div>
          <Button
            onClick={() => {
              setBtnLang('JP')
              setCheckContentJP(null)
            }}
            style={{
              background:
                btnLang === 'JP'
                  ? '#1890FF'
                  : checkContentJP === 'JP'
                  ? '#ff4d4f'
                  : 'rgba(0, 0, 0, 0.45)',
            }}
          >
            JP
          </Button>
          {checkContentJP === 'JP' && (
            <span className="text-[#ff4d4f]">
              {t('managementNotification.modal.required_input')}
            </span>
          )}
        </div>
      </div>
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        translate="yes"
        autoComplete="off"
        size="middle"
        validateTrigger={['onBlur', 'onChange']}
      >
        {btnLang === 'ENG' ? (
          <>
            <Form.Item
              label={t('managementNotification.form.title')}
              name="title"
              rules={rules.title}
              labelAlign="left"
            >
              <Input
                placeholder={t('managementNotification.form.title')}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={t('managementNotification.form.content')}
              name="content"
              rules={rules.content}
              labelAlign="left"
              className="custom-list"
            >
              <MDEditor
                className="Markdown_height"
                value={content}
                onChange={(value) => {
                  setContent(value)
                  form.setFieldsValue({ content: value })
                }}
                // preview="edit"
                preview="live"
                data-color-mode="light"
                style={{
                  background: '#ffffff',
                  color: '#000000',
                  borderRadius: '5px',
                  border: '1px solid #ddd',
                }}
              />
              <Upload
                showUploadList={false}
                beforeUpload={(file) => {
                  handleUploadImage(file)
                  return false
                }}
              >
                <Button className="mt-3" icon={<UploadOutlined />}>
                  Upload Image
                </Button>
              </Upload>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              label={t('managementNotification.form.title')}
              name="title_jp"
              rules={rules.title}
              labelAlign="left"
            >
              <Input
                placeholder={t('managementNotification.form.title')}
                onChange={(e) => setTitle_jp(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={t('managementNotification.form.content')}
              name="content_jp"
              rules={rules.content}
              labelAlign="left"
              className="custom-list"
            >
              <MDEditor
                className="Markdown_height"
                value={content_jp}
                onChange={(value) => {
                  setContent_jp(value)
                  form.setFieldsValue({ content_jp: value })
                }}
                // preview="edit"
                preview="live"
                data-color-mode="light"
                style={{
                  background: '#ffffff',
                  color: '#000000',
                  borderRadius: '5px',
                  border: '1px solid #ddd',
                }}
              />
              <Upload
                showUploadList={false}
                beforeUpload={(file) => {
                  handleUploadImage(file)
                  return false
                }}
              >
                <Button className="mt-3" icon={<UploadOutlined />}>
                  Upload Image
                </Button>
              </Upload>
            </Form.Item>
          </>
        )}

        <Form.Item
          label={t('managementNotification.form.notification_date')}
          name="notification_date"
          rules={rules.notification_date}
          labelAlign="left"
        >
          <DatePicker
            showTime={{ format: 'HH:mm:ss' }}
            format="YYYY-MM-DD HH:mm:ss"
            className="dateRanking"
            style={{ background: '#fff', width: '100%', height: '43px' }}
            placeholder={t('managementNotification.form.notification_date')}
            disabledDate={(current) =>
              current && current < moment().startOf('day')
            }
            disabledTime={(selectedDate) => {
              if (selectedDate && selectedDate.isSame(moment(), 'day')) {
                return {
                  disabledHours: () => [...Array(moment().hour()).keys()],
                  disabledMinutes: (hour) =>
                    hour === moment().hour()
                      ? [...Array(moment().minute()).keys()]
                      : [],
                  disabledSeconds: (hour, minute) =>
                    hour === moment().hour() && minute === moment().minute()
                      ? [...Array(moment().second()).keys()]
                      : [],
                }
              }
              return {}
            }}
          />
        </Form.Item>

        <Form
          name="addGift"
          layout="vertical"
          onFinish={handleAddAddress}
          form={formAddress}
          translate="yes"
          autoComplete="off"
          size="middle"
          validateTrigger={['onBlur', 'onChange']}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              formAddress.submit()
            }
          }}
          initialValues={{ address: 'All User Addresses' }}
        >
          <Form.Item
            label={t('managementNotification.toAddress')}
            name="address"
            rules={rules.address}
            labelAlign="left"
          >
            <Select
              options={optionToAddress}
              // placeholder={t('managementGift.collection')}
              onChange={handleAddressChange}
            ></Select>
          </Form.Item>
          {selectedAddress === 'Specified Addresses' ? (
            <div>
              <Form.Item
                // label={t('managementGift.type')}
                name="to"
                rules={rules.to}
                labelAlign="left"
              >
                <TextArea
                  placeholder={t('managementNotification.toAddress')}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.shiftKey) {
                      e.preventDefault()
                      const value = formAddress.getFieldValue('to')
                      formAddress.setFieldsValue({ to: `${value}\n` })
                    } else if (e.key === 'Enter' && !e.shiftKey) {
                      formAddress.submit()
                    }
                  }}
                />
              </Form.Item>
              <Button
                onClick={formAddress.submit}
                type="primary"
                className="mb-3"
              >
                {t('common.btn.add')}
              </Button>
              {listAddress?.map((data, i) => (
                <React.Fragment key={i?.toString()}>
                  <div
                    className={`rounded-[6px] p-[10px] w-full h-[44px] flex items-center justify-between mb-2 bg-[#314385] ${data?.collection} ${data?.type}`}
                  >
                    <div className={`flex`}>
                      <div>{formatAddress(data?.to)}</div>
                    </div>
                    <DeleteOutlined
                      onClick={() => {
                        const newList = listAddress.filter(function (item) {
                          return String(item?.to) !== String(data?.to)
                        })

                        setListAddress(newList)
                      }}
                      twoToneColor="#FF4D4F"
                      style={{ color: '#FF4D4F' }}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
          ) : (
            ''
          )}
        </Form>
        <div key="buttons" className="flex justify-center">
          <Button key="cancel" onClick={handleClose} style={cancelButtonStyle}>
            {t('common.btn.cancel')}
          </Button>
          <Button
            className="border-hidden"
            key="ok"
            htmlType="submit"
            type="primary"
            style={okButtonStyle}
            disabled={isLoading}
            loading={isLoading}
          >
            {id ? t('common.btn.update') : t('common.btn.add')}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

NotificationForm.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default NotificationForm
