import { Button, Card, DatePicker, Popover, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from '~/common/hooks/useTranslation'
import {
  ACTIVE_ALL_ITEM,
  DEFAULT_ACTIVE_ITEM,
  handleExportCSVScreen,
} from '~/common/constants'
import { getColorTypeItem } from '~/helpers/item-chart'
import { useDashboard } from '~/views/app/redux/hooks/useDashboard'
import moment from 'moment'
const CheckboxGroup = Checkbox.Group
const { RangePicker } = DatePicker

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const ItemChart = () => {
  // eslint-disable-next-line
  const { t } = useTranslation()
  const { actions } = useDashboard()

  const [checkedList, setCheckedList] = useState(DEFAULT_ACTIVE_ITEM)
  const checkAll = ACTIVE_ALL_ITEM.length === checkedList.length
  const indeterminate =
    checkedList.length > 0 && checkedList.length < ACTIVE_ALL_ITEM.length
  // const [date, setDate] = useState(null)
  const [date, setDate] = useState(['', ''])
  const [dataChart, setDataChart] = useState([])

  const LIST_ITEM = [
    {
      label: t('item.ENERGY_BANANA_SHOP'),
      value: 'ENERGY_BANANA_SHOP',
    },
    {
      label: t('item.REFRESH_HERB_SHOP'),
      value: 'REFRESH_HERB_SHOP',
    },
    {
      label: t('item.GENERAL_BOX'),
      value: 'GENERAL_BOX',
    },
    {
      label: t('item.FAIRY_BERRY_SHOP'),
      value: 'FAIRY_BERRY_SHOP',
    },
    {
      label: t('item.CARAMEL_CAKE_SHOP'),
      value: 'CARAMEL_CAKE_SHOP',
    },
    {
      label: t('item.CHIA_YOGURT_SHOP'),
      value: 'CHIA_YOGURT_SHOP',
    },
    {
      label: t('item.SATIETY_KONJACT_SHOP'),
      value: 'SATIETY_KONJACT_SHOP',
    },
    {
      label: t('item.GLORIOUS_MEAT_SHOP'),
      value: 'GLORIOUS_MEAT_SHOP',
    },
    {
      label: t('item.SUNNY_BLOSSOM_SHOP'),
      value: 'SUNNY_BLOSSOM_SHOP',
    },
    {
      label: t('item.LUNAR_GRASS_SHOP'),
      value: 'LUNAR_GRASS_SHOP',
    },
  ]

  const labels = dataChart?.map((item) => {
    const date = new Date((item?.timestamp * 1000) / 1000)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    const formattedDate = `${year}/${month}/${day}`
    return formattedDate
  })

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: true,
        labels: {
          color: '#fff',
          usePointStyle: true,
        },
      },
      borderDash: {
        xAxis: [0, 888],
      },
    },
    scales: {
      min: 0,
      y: {
        border: {
          color: '#fff',
        }, // for the grid lines
        grid: {
          display: true,
          color: 'rgba(255, 255, 255, 0.1)',
          tickBorderDash: [1, 10],
          tickColor: 'transparent',
          tickWidth: 2,
          offset: true,
          drawTicks: true,
          drawOnChartArea: true,
        },
        ticks: {
          color: '#fff',
          font: {
            size: 10,
          },
          stepSize: 200,
          beginAtZero: true,
        },
      },
      x: {
        border: {
          color: '#fff',
        },
        grid: {
          display: true,
          borderDash: [5, 5],
          color: 'rgba(255, 255, 255, 0)',
        },
        ticks: {
          color: '#fff',
          font: {
            size: 10,
          },
          stepSize: 1,
          beginAtZero: true,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  }

  const data = {
    labels,
    datasets: checkedList?.map((data) => {
      const obj = {
        label: t(`item.${data}`),
        data: dataChart?.map((item) => item?.params?.inventoryItem[data]),
        borderColor: getColorTypeItem(data),
        backgroundColor: getColorTypeItem(data),
      }

      return obj
    }),
  }

  const onChange = (list) => {
    setCheckedList(list)
  }
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? ACTIVE_ALL_ITEM : [])
  }
  const [paramsExport, setparamsExport] = useState({ type: 'TrainingItem' })
  useEffect(() => {
    const params = {
      type: 'TrainingItem',
    }
    if (date[0] !== '') {
      let temp = date[0].split('-')
      let temp1 = date[1].split('-')
      params.fromTime = Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0)
      params.toTime = Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59)
      setparamsExport((prev) => ({
        ...prev,
        fromTime: Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0),
        toTime: Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59),
      }))
    }
    actions.getDashBoardItem(params, (res) => {
      setDataChart(res.histories)
    })
  }, [date])

  const disabledDate = (current) => {
    return current && current > moment().endOf('day')
  }
  const onChangeDate = (values) => {
    setDate(values)
  }

  return (
    <Card
      className=""
      // title="Inventory of Training Item"
      title={
        <div className="mt-[5px] mb-[5px]">
          Inventory of Training Item
          <Button
            style={{ backgroundColor: '#1890FF', marginLeft: 8 }}
            onClick={() =>
              handleExportCSVScreen(
                'DASHBOARD_INVENTORY_TRAINING_ITEM',
                paramsExport,
                'DASHBOARD_INVENTORY_TRAINING_ITEM',
              )
            }
          >
            Export to CSV
          </Button>
        </div>
      }
      extra={
        <div className="flex gap-4 items-center">
          <Popover
            content={
              <div className="w-[180px]">
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  All
                </Checkbox>
                <CheckboxGroup
                  options={LIST_ITEM}
                  value={checkedList}
                  onChange={onChange}
                />
              </div>
            }
            className="w-full"
            trigger="click"
            placement="bottom"
            arrow={false}
          >
            <Button className="!bg-[#00113D] border__grey hover:!border-[#40A9FF] h-[32.19px] !w-[210px]">
              <div className="w-full flex text-start justify-between items-center">
                <div className="max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis">
                  {checkAll
                    ? 'All'
                    : checkedList?.map((data) => t(`item.${data}`))?.join()}
                </div>
                <DownOutlined
                  className="absolute right-[15px]"
                  style={{ fontSize: 10 }}
                />
              </div>
            </Button>
          </Popover>
          <RangePicker
            className="h-full max-w-[256px]"
            // onChange={(e) => setDate(e)}
            onChange={(values, dateString) => onChangeDate(dateString)}
            disabledDate={disabledDate}
          />
        </div>
      }
    >
      <div className="h-[48px]"></div>
      <Line options={options} data={data} />
    </Card>
  )
}

export default ItemChart
