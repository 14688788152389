import PropTypes from 'prop-types'
// import { useTranslation } from '~/common/hooks/useTranslation'
import { Form, Modal, Row, Col, Progress } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import { dataGuildLevel } from '~/common/constants'
import RankingGuild from './guildRank'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'

function GuildDetail(props) {
  const { t } = useTranslation()
  const {
    isShowModal,
    onClose,
    data,
    listUserByWallet,
    listPool,
    totalGuild,
    dataGetGuildScore,
  } = props
  const [userWallet, setUserWallet] = useState([])
  const [sortListWallet, setSortListWallet] = useState([])
  const validWallets = data?.guild_member?.map((item) => item.user_wallet)

  const filteredData = listUserByWallet?.filter((user) =>
    validWallets.includes(user.address_wallet),
  )

  const [form] = Form.useForm()

  useEffect(() => {
    const userResult = data?.guild_member
      ? data.guild_member?.map((item) => item.user_wallet)
      : []
    setUserWallet(userResult)
  }, [data])

  useEffect(() => {
    // Sort listUserByWallet based on userWallet order
    const sortedList = [...filteredData].sort((a, b) => {
      const indexA = userWallet.indexOf(a.address_wallet)
      const indexB = userWallet.indexOf(b.address_wallet)
      return indexA - indexB
    })

    const mergedList = sortedList?.map((user) => {
      // Find the corresponding final standing by matching user_name or any other field
      const standing = data?.guild_member?.find(
        (item) => item.user_wallet === user.address_wallet,
      )
      return standing
        ? {
            ...user,
            member_role: standing.member_role || 0,
          }
        : user // If no matching standing is found, return the user as is
    })
    if (userWallet?.length > 0) {
      setSortListWallet(
        mergedList.sort((a, b) => a.member_role - b.member_role),
      )
    } else {
      setSortListWallet([])
    }
  }, [listUserByWallet, userWallet, data])

  const handleClose = () => {
    form.resetFields()
    onClose()
  }
  const timestamp = data?.guild_create_date
  const date = new Date(timestamp * 1000)
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  const formattedDate = date.toLocaleDateString('en-US', options)
  const uniqueGuildMembers = data?.guild_member?.filter(
    (member, index, self) =>
      index === self.findIndex((m) => m.user_wallet === member.user_wallet),
  )

  const sortedDataListPool = [...listPool].sort(
    (a, b) => a.timeStart - b.timeStart || a.timeEnd - b.timeEnd,
  )
  const currentTimestamp = Date.now() / 1000 // Current time in seconds
  const initialCycleIndex = sortedDataListPool.findIndex(
    (pool) =>
      pool.timeStart <= currentTimestamp && pool.timeEnd >= currentTimestamp,
  )

  const [currentCycleIndex, setCurrentCycleIndex] = useState(
    initialCycleIndex >= 0 ? initialCycleIndex : 0,
  )
  const [currentGuildIndexDetail, setCurrentGuildIndexDetail] = useState(1)
  const goToPrevious = () => {
    setCurrentCycleIndex((prev) => Math.max(prev - 1, 0))
  }

  const goToNext = () => {
    setCurrentCycleIndex((prev) =>
      Math.min(prev + 1, sortedDataListPool?.length - 1),
    )
  }
  const cyclePool = sortedDataListPool[currentCycleIndex]

  const dataCycleGuildRank = Object.values(
    dataGetGuildScore?.guild_score?.reduce((acc, item) => {
      const { guild_id, cycle } = item

      if (!acc[guild_id]) {
        acc[guild_id] = {
          guild_id: item.guild_id,
          guild_name: item.guild_name,
          from_date_cycle: cyclePool.timeStart,
          to_date_cycle: cyclePool.timeEnd,
          cycles: [],
        }
      }

      let cycleData = acc[guild_id].cycles.find((c) => c.cycle_id === cycle)

      if (!cycleData) {
        cycleData = {
          cycle_id: cycle,
          cycle_name: item.cycle_name,
          from_date_cycle: item.from_date,
          to_date_cycle: item.to_date,
          dataMonthCycle: [],
        }
        acc[guild_id].cycles.push(cycleData)
      }

      cycleData.dataMonthCycle.push({
        from_date: item.from_date,
        to_date: item.to_date,
        oas_earned: item.oas_earned,
        ranked_score: item.ranked_score,
        exploration_score: item.explore_score,
        guild_placement: item.guild_placement,
        total_placement: item.total_placement,
        is_finalize: item.is_finalize,
      })

      // Cập nhật to_date_cycle nếu cần
      if (item.to_date > cycleData.to_date_cycle) {
        cycleData.to_date_cycle = item.to_date
      }

      return acc
    }, {}),
  )
  const dataScore = dataCycleGuildRank?.filter(
    (item) => item.guild_id === data?._id,
  )
  const ConverDataGuildScore = dataScore[0]?.cycles?.filter(
    (item) => item.cycle_id === currentCycleIndex,
    currentCycleIndex,
  )

  return (
    <Modal
      title={<>{t('managementGuilds.form.guildDetail')}</>}
      onCancel={handleClose}
      open={isShowModal}
      width={1000}
      centered
      destroyOnClose
      footer={null}
    >
      <div>
        {/* GuildName */}
        <Row gutter={24} align="middle" style={{ fontFamily: 'Rexliafree' }}>
          <Col span={8} className="flex items-center">
            <img
              className={`mr-2
                ${
                  data?.guild_image?.startsWith(
                    process.env.REACT_APP_BASE_URL_IMAGE,
                  )
                    ? 'h-[35px] mb-2'
                    : 'w-[60px] h-[60px] '
                }
              `}
              src={
                data?.guild_image?.startsWith(
                  process.env.REACT_APP_BASE_URL_IMAGE,
                )
                  ? data.guild_image
                  : `${process.env.PUBLIC_URL}/svgs/guild/ImageGuild/${data?.guild_image}.svg`
              }
              alt=""
            />
            <div className="text-[24px]"> {data?.guild_name}</div>
          </Col>
          <Col span={16} className="flex items-center">
            <Row style={{ width: '100%' }} align="middle">
              <Col span={3} style={{ textAlign: 'center', fontSize: '20px' }}>
                Lv.{data?.guild_level}
              </Col>
              <Col span={16} style={{ paddingRight: '30px' }}>
                <Progress
                  percent={
                    (data?.guild_exp /
                      dataGuildLevel(data?.guild_level + 1)?.requiredEXP) *
                    100
                  }
                  format={() =>
                    `${data?.guild_exp}/${
                      dataGuildLevel(data?.guild_level + 1)?.requiredEXP
                    }`
                  }
                />
              </Col>

              <Col
                span={5}
                style={{
                  display: 'flex',
                  border: '1px solid #66BB6A',
                  justifyContent: 'center',
                  background: '#66BB6A',
                  borderRadius: '10px',
                }}
              >
                <img
                  className="w-[30px] h-[30px] mr-2"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/groupMember.svg`}
                  alt=""
                />
                <div className="text-[20px]">
                  {uniqueGuildMembers?.length}/
                  {dataGuildLevel(data?.guild_level)?.maxMembers}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Guild Rank */}
        <Row
          gutter={24}
          align="middle"
          className="mt-[15px]"
          style={{ fontFamily: 'Rexliafree' }}
        >
          <Col span={24} className="flex items-center justify-center">
            <img
              className="w-[30px] h-[30px] mr-2"
              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
              alt=""
            />
            <div className="text-[20px] ">Guild Rank</div>
          </Col>
        </Row>

        <RankingGuild
          dataCycleGuildRank={ConverDataGuildScore}
          cyclePool={cyclePool}
          totalGuild={totalGuild}
          goToPrevious={goToPrevious}
          goToNext={goToNext}
          currentCycleIndex={currentCycleIndex}
          sortedDataListPool={sortedDataListPool?.length}
        />
        <Row gutter={24} className="mt-[12px] p-[12px]">
          <Col span={12} style={{ fontFamily: 'Rexliafree' }}>
            <Row className="mb-[12px]">
              <Col span={12} className="flex items-center">
                <img
                  className="w-[20px] h-[20px] mr-1"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/guildMaster.svg`}
                  alt=""
                />
                <div className="text-[16px]">Guild Master</div>
              </Col>
              <Col span={12} className="text-[16px] flex justify-center">
                {sortListWallet[0]?.member_role === 0
                  ? sortListWallet[0]?.user_name
                  : '-'}
              </Col>
            </Row>
            <Row className="mb-[12px]">
              <Col span={12} className="flex items-center">
                <img
                  className="w-[20px] h-[20px] mr-1"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/subGuild.svg`}
                  alt=""
                />
                <div className="text-[16px]">Sub Master1</div>
              </Col>
              <Col span={12} className="text-[16px] flex justify-center">
                {sortListWallet[1]?.member_role === 1
                  ? sortListWallet[1]?.user_name
                  : '-'}
              </Col>
            </Row>
            <Row className="mb-[12px]">
              <Col span={12} className="flex items-center ">
                <img
                  className="w-[20px] h-[20px] mr-1"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/subGuild.svg`}
                  alt=""
                />
                <div className="text-[16px]">Sub Master2</div>
              </Col>
              <Col span={12} className="text-[12px] flex justify-center">
                {data.guild_level < 6 ? (
                  <div
                    className="flex items-center justify-center"
                    style={{
                      border: '1px solid #fff',
                      borderRadius: '4px',
                      padding: '4px',
                      width: '52%',
                    }}
                  >
                    <img
                      className="w-[15px] h-[15px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/keyGuild.svg`}
                      alt=""
                    />
                    <div>Guild level 6</div>
                  </div>
                ) : (
                  <>
                    {sortListWallet[2]?.member_role === 2
                      ? sortListWallet[2]?.user_name
                      : '-'}
                  </>
                )}
              </Col>
            </Row>
            <Row className="mb-[12px]">
              <Col span={12} className="flex items-center">
                <img
                  className="w-[20px] h-[20px] mr-1"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/subGuild.svg`}
                  alt=""
                />
                <div className="text-[16px]">Sub Master3</div>
              </Col>
              <Col span={12} className="text-[12px] flex justify-center">
                {data.guild_level < 11 ? (
                  <div
                    className="flex items-center justify-center"
                    style={{
                      border: '1px solid #fff',
                      borderRadius: '4px',
                      padding: '4px',
                      width: '52%',
                    }}
                  >
                    <img
                      className="w-[15px] h-[15px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/keyGuild.svg`}
                      alt=""
                    />
                    <div>Guild level 11</div>
                  </div>
                ) : (
                  <>
                    {sortListWallet[3]?.member_role === 3
                      ? sortListWallet[3]?.user_name
                      : '-'}
                  </>
                )}
              </Col>
            </Row>
            <Row className="mb-[12px]">
              <Col span={12} className="flex items-center">
                <div className="text-[16px] pl-[10%]"> Earned OAS</div>
              </Col>
              <Col span={12} className=" flex text-[16px]  justify-center">
                <img
                  className="w-[25px] h-[25px] mr-1"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                  alt=""
                />
                <div>{data?.guild_total_oas_earned}</div>
              </Col>
            </Row>
            <Row className="mb-[12px]">
              <Col span={12} className="flex items-center ">
                <div className="text-[16px] pl-[10%]">Establishment</div>
              </Col>
              <Col span={12} className="text-[16px] flex justify-center">
                {formattedDate}
              </Col>
            </Row>
          </Col>
          <Col
            span={12}
            className="guildInfo"
            style={{
              border: '1px solid #fff',
              borderRadius: '8px',
              padding: '12px',
              maxHeight: '230px',
              overflowY: 'auto',
            }}
          >
            <div
              className="text-[20px] flex justify-center items-center mb-3"
              style={{ fontFamily: 'Rexliafree' }}
            >
              <CaretLeftOutlined
                onClick={() => {
                  if (currentGuildIndexDetail > 0) {
                    setCurrentGuildIndexDetail(currentGuildIndexDetail - 1)
                  }
                }}
                style={{
                  color: currentGuildIndexDetail === 0 ? '#004a5c' : '#00c8fa',
                  fontSize: '30px',
                  cursor:
                    currentGuildIndexDetail === 0 ? 'not-allowed' : 'pointer',
                }}
              />
              {currentGuildIndexDetail === 0 ? 'Noticeboard' : 'Introduction'}
              <CaretRightOutlined
                onClick={() => {
                  if (currentGuildIndexDetail < 1) {
                    setCurrentGuildIndexDetail(currentGuildIndexDetail + 1)
                  }
                }}
                style={{
                  color: currentGuildIndexDetail === 1 ? '#004a5c' : '#00c8fa',
                  fontSize: '30px',
                  cursor:
                    currentGuildIndexDetail === 1 ? 'not-allowed' : 'pointer',
                }}
              />
              {/* {data?.guild_notice} */}
            </div>
            <div
              className="flex justify-center items-center mb-3"
              style={{ fontWeight: '500' }}
            >
              {currentGuildIndexDetail === 0
                ? data?.guild_notice
                : data?.guild_introduction}
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

GuildDetail.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default GuildDetail
