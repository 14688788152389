import { Button, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import RevenueChart from '../components/RevenueChart'
import BuySellChart from '../components/BuySellChart'
import ItemChart from '../components/ItemChart'
import { useDashboard } from '~/views/app/redux/hooks/useDashboard'
import { handleExportCSVScreen } from '~/common/constants'

const ShopDashboard = () => {
  const { actions } = useDashboard()
  //Date
  // const [dateOasRevenue, setDateOasRevenue] = useState(null)
  const [dateOasRevenue, setDateOasRevenue] = useState(['', ''])
  // const [dateBitRevenue, setDateBitRevenue] = useState(null)
  const [dateBitRevenue, setDateBitRevenue] = useState(['', ''])

  //Data
  const [dataOasRevenue, setDataOasRevenue] = useState([])
  const [dataBitRevenue, setDataBitRevenue] = useState([])

  const [paramsOASExport, setParamsOASExport] = useState({ type: 'OasRevenue' })
  const [paramBITExport, setparamBITExport] = useState({ type: 'BitRevenue' })

  useEffect(() => {
    const paramsOAS = {
      type: 'OasRevenue',
    }

    if (dateOasRevenue[0] !== '') {
      let temp = dateOasRevenue[0].split('-')
      let temp1 = dateOasRevenue[1].split('-')
      // paramsOAS.fromTime =
      //   Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0) / 1000
      paramsOAS.fromTime = Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0)
      // paramsOAS.toTime =
      //   Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59) / 1000
      paramsOAS.toTime = Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59)

      setParamsOASExport((prev) => ({
        ...prev,
        fromTime: Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0),
        toTime: Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59),
      }))
    }
    actions.getDashBoardShop(paramsOAS, (res) => {
      setDataOasRevenue(res.histories)
    })
  }, [dateOasRevenue])

  useEffect(() => {
    const paramsBIT = {
      type: 'BitRevenue',
    }
    if (dateBitRevenue[0] !== '') {
      let temp = dateBitRevenue[0].split('-')
      let temp1 = dateBitRevenue[1].split('-')
      paramsBIT.fromTime = Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0)
      paramsBIT.toTime = Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59)
      setparamBITExport((prev) => ({
        ...prev,
        fromTime: Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0),
        toTime: Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59),
      }))
    }
    actions.getDashBoardBit(paramsBIT, (res) => {
      setDataBitRevenue(res.histories)
    })
  }, [dateBitRevenue])
  return (
    <React.Fragment>
      <Row gutter={10}>
        <Col xl={12} md={12} sm={24} xs={24}>
          <RevenueChart
            type="OasRevenue"
            title={
              <div className="flex items-center">
                OAS Revenue
                <Button
                  style={{ backgroundColor: '#1890FF', marginLeft: 8 }}
                  onClick={() =>
                    handleExportCSVScreen(
                      'DASHBOARD_OAS_REVENUE',
                      paramsOASExport,
                      'DASHBOARD_OAS_REVENUE',
                    )
                  }
                >
                  Export to CSV
                </Button>
              </div>
            }
            color={{
              borderColor: 'rgba(19, 178, 228, 1)',
              backgroundColor: 'rgba(19, 178, 228, 1)',
            }}
            setDate={setDateOasRevenue}
            dataChart={dataOasRevenue}
          />
        </Col>
        <Col xl={12} md={12} sm={24} xs={24}>
          <RevenueChart
            type={'BitRevenue'}
            // title={'BIT Revenue'}
            title={
              <div className="flex items-center">
                BIT Revenue
                <Button
                  style={{ backgroundColor: '#1890FF', marginLeft: 8 }}
                  onClick={() =>
                    handleExportCSVScreen(
                      'DASHBOARD_BIT_REVENUE',
                      paramBITExport,
                      'DASHBOARD_BIT_REVENUE',
                    )
                  }
                >
                  Export to CSV
                </Button>
              </div>
            }
            color={{
              borderColor: 'rgba(255, 169, 64, 1)',
              backgroundColor: 'rgba(255, 169, 64, 1)',
            }}
            setDate={setDateBitRevenue}
            dataChart={dataBitRevenue}
          />
        </Col>
        <Col xl={12} md={12} sm={24} xs={24}>
          <BuySellChart />
        </Col>
        <Col xl={12} md={12} sm={24} xs={24}>
          <ItemChart />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ShopDashboard
